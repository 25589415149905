import { parseJSON } from 'date-fns';
import { match } from 'ts-pattern';

import { SaleSchedule } from 'components/SaleSchedule';
import Divider from 'components/base/Divider';
import { Checkout } from 'components/checkout-widget/Checkout';

import { TimedEditionCollectionSale } from 'types/EditionSale';
import { CollectorsPreview } from 'types/collectors';

import { CollectorsSummary } from '../CollectorsSummary';

import { MintWidget } from './MintWidget';
import { useMintWidget } from './MintWidgetContext';
import { MintWidgetMintCta } from './MintWidgetMintCta';
import { MintWidgetQuantityStepper } from './MintWidgetQuantityStepper';
import { MintWidgetSaleStats } from './MintWidgetSaleStats';
import { MintWidgetUnlimitedSupplyStatus } from './MintWidgetUnlimitedSupplyStatus';

export function TimedEditionMintWidget(props: {
  collectors: CollectorsPreview;
  nftCount: number;
  sale: TimedEditionCollectionSale;
  totalSales: number;
}) {
  const { collectors, nftCount, sale, totalSales } = props;

  const { onAddToCal, onCollectorsClick, onShopTheSecondary } = useMintWidget();

  return match({ sale })
    .with({ sale: { status: 'SCHEDULED' } }, ({ sale }) => {
      const { mintPrice, startTime, endTime } = sale;

      const startDate = parseJSON(startTime);
      const endDate = parseJSON(endTime);

      return (
        <Checkout.Root>
          <MintWidgetSaleStats.Scheduled
            mintPrice={mintPrice}
            supply={Infinity}
          />
          <Divider />
          <Checkout.Stack css={{ gap: '$4' }}>
            <SaleSchedule.Public startDate={startDate} prefix="mint-opens" />
            <MintWidget.AddToCal
              onClick={() =>
                onAddToCal({
                  startDate,
                  endDate,
                })
              }
            />
          </Checkout.Stack>
        </Checkout.Root>
      );
    })
    .with({ sale: { status: 'OPEN' } }, ({ sale }) => {
      const { endTime } = sale;
      return (
        <Checkout.Root>
          <Checkout.Stack>
            <MintWidgetQuantityStepper />
            <MintWidgetMintCta size={1} />
          </Checkout.Stack>
          <MintWidgetUnlimitedSupplyStatus
            minted={nftCount}
            endDate={endTime ? parseJSON(endTime) : null}
          />
          <CollectorsSummary
            type="collector"
            collectors={collectors}
            onClick={onCollectorsClick}
            maxPreviews={2}
          />
        </Checkout.Root>
      );
    })
    .with({ sale: { status: 'ENDED' } }, ({ sale }) => {
      return (
        <Checkout.Root>
          <Checkout.Stack>
            <MintWidgetSaleStats.Ended
              nftCount={nftCount}
              sale={sale}
              totalSales={totalSales}
            />
            {onShopTheSecondary && (
              <MintWidget.BrowseSecondaryCta onClick={onShopTheSecondary} />
            )}
          </Checkout.Stack>
          <CollectorsSummary
            type="collector"
            collectors={collectors}
            onClick={onCollectorsClick}
            maxPreviews={2}
          />
        </Checkout.Root>
      );
    })
    .exhaustive();
}
