import {
  GIZMO_CENTER,
  GIZMO_RADIUS,
  GIZMO_VIEWBOX,
  GizmoCircle,
  GizmoSvg,
} from './GizmoCircle';
import { GizmoProgressCircle } from './GizmoProgressCircle';

const MINTED_OUT_COLOR = '#B2B2B2';
const CIRCUMFRENCE = GIZMO_RADIUS * 2 * Math.PI;

export function GizmoLinearCircle(props: {
  isMintedOut: boolean;
  /** number between 0...100 */
  percentCompleted: number;
}) {
  const { isMintedOut, percentCompleted } = props;

  /** Converts the percent completed (e.g 80%) to a percent remaining (e.g 20%) */
  const percentRemaining = 100 - percentCompleted;
  /** Converts the percent remaining (.e.g 20%) to a ratio (0.2) */
  const ratioRemaining = percentRemaining / 100;

  const color = isMintedOut ? MINTED_OUT_COLOR : 'currentColor';

  return (
    <GizmoSvg
      viewBox={GIZMO_VIEWBOX}
      css={{
        // Rotate the circle then flip it so that the stroke starts at the top, and goes clockwise
        transform: 'rotate(90deg) scaleX(-1)',
      }}
    >
      {/* the hatched (background) circle */}
      <GizmoCircle
        cx={GIZMO_CENTER}
        cy={GIZMO_CENTER}
        r={GIZMO_RADIUS}
        fill="none"
        stroke="currentColor"
        strokeWidth={6}
        strokeDasharray={`2 ${CIRCUMFRENCE / 80 - 2}`}
        style={{ color }}
      />
      <GizmoProgressCircle color={color} ratioRemaining={ratioRemaining} />
    </GizmoSvg>
  );
}
