/**
 * Note that Highlight supports multiple testnets via one Frontend —
 *
 * "deploying a collection on Goerli on Highlight will give you a mint page and collection dashboard that talks to Goerli"
 * @see https://onfoundation.slack.com/archives/C05RCMBPDNG/p1697399810043189?thread_ts=1697203670.271809&cid=C05RCMBPDNG
 */
const HIGHLIGHT_URL = 'https://highlight.xyz';

const createHighlightUrl = <Path extends `/${string}`>(path: Path) => {
  return `${HIGHLIGHT_URL}${path}` as const;
};

export const getHighlightUrl = {
  create: {
    dashboard: createHighlightUrl('/tools'),
    generativeSeries: createHighlightUrl(
      '/tools/collections/create/type/GenerativeSeries'
    ),
  },
  home: HIGHLIGHT_URL,
} as const;

export const calculateCreatorWithdrawAmount = (options: {
  totalAmountInWei: number;
  takeRateInBasisPoints: number;
}) => {
  const { totalAmountInWei, takeRateInBasisPoints } = options;
  return totalAmountInWei - (totalAmountInWei * takeRateInBasisPoints) / 10000;
};
