import { parseJSON } from 'date-fns';
import type { Address } from 'viem';

import { hasPublicKey, isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { ApiDropFragment } from 'gql/api/api-fragments.generated';
import useModal from 'hooks/use-modal';
import useHighlightDutchRebateState from 'hooks/web3/use-highlight-dutch-auction-rebate-state';
import useHighlightDutchAuctionState from 'hooks/web3/use-highlight-dutch-auction-state';
import { useRefetchOnBlock } from 'hooks/web3/use-watch-block';
import { calculateCreatorWithdrawAmount } from 'utils/highlight';
import { isZero } from 'utils/numbers';

import { HighlightStaggeredDutchAuctionSale } from 'types/DropSale';

import DutchAuctionClaimAction from './DutchAuctionClaimAction';
import {
  ClaimRebateAction,
  WithdrawEarningsAction,
} from './collections/DutchAuctionClaimActions';

interface HighlightDutchAuctionClaimActionsProps {
  sale: HighlightStaggeredDutchAuctionSale;
  collection: Pick<
    ApiDropFragment,
    'chainId' | 'creator' | 'contractAddress' | 'worldId'
  >;
  totalMinted: number;
  takeRateInBasisPoints: number;
}
export default function HighlightDutchAuctionClaimActions(
  props: HighlightDutchAuctionClaimActionsProps
) {
  const { sale, collection, totalMinted, takeRateInBasisPoints } = props;
  const { chainId } = collection;

  const auth = useAuth();
  const modal = useModal();

  const isCreator = isMyAddress(auth, collection.creator.publicKey);
  const userPublicKey = hasPublicKey(auth) ? auth.publicKey : ('' as Address);

  const rebateState = useHighlightDutchRebateState(
    { chainId, userPublicKey, vectorId: sale.mintVectorId },
    { enabled: Boolean(sale.highlightId) && hasPublicKey(auth) }
  );

  useRefetchOnBlock(rebateState, {
    enabled: Boolean(sale.highlightId) && hasPublicKey(auth),
  });

  const rebate = rebateState.data?.rebate ?? BigInt(0);

  const highlightDutchAuctionStateQuery = useHighlightDutchAuctionState(
    { chainId, sale },
    { enabled: Boolean(sale.highlightId) && isCreator }
  );

  useRefetchOnBlock(highlightDutchAuctionStateQuery, {
    enabled: Boolean(sale.highlightId) && isCreator,
  });

  const isRevenueReadyForWithdrawal = Boolean(
    highlightDutchAuctionStateQuery.data?.isRevenueReadyForWithdrawal
  );

  const creatorRevenueHasBeenWithdrawn =
    highlightDutchAuctionStateQuery.isLoading
      ? true
      : highlightDutchAuctionStateQuery.data?.creatorRevenueHasBeenWithdrawn;

  const totalPayoutFunds =
    highlightDutchAuctionStateQuery.data?.totalPayoutFunds ?? BigInt(0);

  if (!hasPublicKey(auth) || totalMinted === 0) {
    return null;
  }

  const totalAmountInWei = Number(totalPayoutFunds);

  const creatorWithdrawAmount = calculateCreatorWithdrawAmount({
    totalAmountInWei,
    takeRateInBasisPoints,
  });
  return (
    <DutchAuctionClaimAction.PinBottom>
      {isCreator && !creatorRevenueHasBeenWithdrawn && (
        <WithdrawEarningsAction
          onClick={() => {
            modal.setModal({
              type: 'CLAIM_DUTCH_AUCTION_REVENUE',
              chainId,
              market: 'HIGHLIGHT',
              sale,
              withdrawAmount: BigInt(creatorWithdrawAmount.toString()),
              /* exhibitionId is nullable on collection object
               * but Highlight drops will ALWAYS be associated with a world */
              worldId: collection.worldId ?? 0,
              takeRateInBasisPoints,
            });
          }}
          isEnabled={isRevenueReadyForWithdrawal}
        />
      )}
      {isZero(rebate) ? null : (
        <ClaimRebateAction
          rebateBalance={rebate}
          onClick={() => {
            modal.setModal({
              type: 'CLAIM_REBATE',
              chainId,
              contractAddress: collection.contractAddress,
              maxTokenId: sale.maxTokenId,
              endDate: parseJSON(sale.endTime),
              market: 'HIGHLIGHT',
              sale,
            });
          }}
        />
      )}
    </DutchAuctionClaimAction.PinBottom>
  );
}
