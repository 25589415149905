import { lerp } from 'utils/helpers';
import { clampRatio } from 'utils/numbers';

import { Ratio } from 'types/number';

import {
  GIZMO_CENTER,
  GIZMO_RADIUS,
  GIZMO_STROKE_WIDTH,
  GizmoCircle,
} from './GizmoCircle';

const MAX_DASH_LENGTH = 2 * Math.PI * GIZMO_RADIUS;

export function GizmoProgressCircle(props: {
  color: string;
  ratioRemaining: Ratio;
}) {
  const dashReduction = lerp(
    0,
    MAX_DASH_LENGTH,
    clampRatio(props.ratioRemaining)
  );

  return (
    <GizmoCircle
      cx={GIZMO_CENTER}
      cy={GIZMO_CENTER}
      r={GIZMO_RADIUS}
      css={{
        strokeLinecap: 'round',
        strokeWidth: GIZMO_STROKE_WIDTH,
        transition: 'stroke-dashoffset 1s cubic-bezier(0.85, 0, 0.15, 1)',
      }}
      style={{
        stroke: props.color,
        strokeDasharray: MAX_DASH_LENGTH,
        strokeDashoffset: MAX_DASH_LENGTH - dashReduction,
      }}
    />
  );
}
