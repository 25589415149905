import { addMinutes, isFuture, parseJSON } from 'date-fns';
import { useWriteContract, useSimulateContract } from 'wagmi';

import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useTransactionStore from 'state/stores/transactions';
import { satisfiesExperimentalWeb3ActionConfig } from 'web3/config';

import { useQueryEffects } from 'hooks/react-query';
import useHighlightDutchAuctionState from 'hooks/web3/use-highlight-dutch-auction-state';
import { useRefetchOnBlock } from 'hooks/web3/use-watch-block';
import { HighlightMintManager } from 'lib/abis/HighlightMintManager';
import { getHighlightAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';
import { ZERO_ADDRESS } from 'lib/constants';
import { HIGHLIGHT_MINT_FEE } from 'lib/highlight';
import report from 'lib/report';
import { isHex } from 'utils/address';
import { deriveFallbackDutchAuctionPrice } from 'utils/drops';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';
import {
  getMintPriceBreakdown,
  getMintPriceStepperBreakdown,
} from 'utils/transactions';
import { createWeb3StepperControls } from 'utils/web3-cta';

import { HighlightStaggeredDutchAuctionSale } from 'types/DropSale';

import { MintFromDutchAuctionFlowContext } from './MintFromDutchAuctionFlowContext';
import {
  MINT_FROM_DUTCH_AUCTION_ACTION_NAME,
  MINT_FROM_DUTCH_AUCTION_TRACKING_COPY,
} from './constants';
import { MintFromDutchAuctionProviderProps } from './types';

type PrepareMintFromDutchAuctionOptions = {
  chainId: ChainId;
  count: number;
  currentTotalPrice: bigint;
  highlightMintVectorId: HighlightStaggeredDutchAuctionSale['mintVectorId'];
};

function usePrepareMintFromHighlightDutchAuction(
  options: PrepareMintFromDutchAuctionOptions
) {
  const { chainId, count, currentTotalPrice, highlightMintVectorId } = options;

  const auth = useAuth();

  const simulateMechanicMintNum = useSimulateContract({
    abi: HighlightMintManager,
    functionName: 'mechanicMintNum',
    address: getHighlightAddress({ chainId, contractName: 'mintManager' }),
    chainId,
    args:
      hasPublicKey(auth) && isHex(highlightMintVectorId)
        ? [highlightMintVectorId, auth.publicKey, count, ZERO_ADDRESS]
        : undefined,

    value: currentTotalPrice,
    query: {
      enabled: hasPublicKey(auth) && isHex(highlightMintVectorId) && count > 0,
      retry: false,
    },
  });

  useQueryEffects(simulateMechanicMintNum, {
    onError: (error) => {
      report(extractPrepareContractWriteRevertReason({ error }));
    },
  });

  return simulateMechanicMintNum;
}

export function MintFromHighlightStaggeredDutchAuctionProvider(
  props: MintFromDutchAuctionProviderProps & {
    chainId: ChainId;
    sale: HighlightStaggeredDutchAuctionSale;
  }
) {
  const {
    chainId,
    children,
    indexedNftCount,
    mintQuantity,
    setMintQuantity,
    sale,
    worldId,
  } = props;

  const txStore = useTransactionStore();

  const liveDutchAuctionQuery = useHighlightDutchAuctionState(
    { chainId, sale },
    { enabled: Boolean(sale.highlightId) }
  );

  useRefetchOnBlock(liveDutchAuctionQuery, {
    enabled: sale.endTime
      ? isFuture(addMinutes(parseJSON(sale.endTime), 5))
      : false,
  });

  const isMintedOut = liveDutchAuctionQuery.data
    ? liveDutchAuctionQuery.data.isMintedOut
    : sale.status === 'MINTED_OUT';

  const totalMintedCount = liveDutchAuctionQuery.data
    ? liveDutchAuctionQuery.data.totalMinted
    : indexedNftCount;

  const currentPrice = liveDutchAuctionQuery.data
    ? liveDutchAuctionQuery.data.currentPrice
    : deriveFallbackDutchAuctionPrice(sale);

  const itemPriceBreakdown = getMintPriceBreakdown({
    count: BigInt(1),
    fee: HIGHLIGHT_MINT_FEE,
    price: currentPrice,
  });

  const stepper = getMintPriceStepperBreakdown({
    count: mintQuantity,
    fee: HIGHLIGHT_MINT_FEE,
    price: currentPrice,
  });

  const nextDecrement = stepper.nextDecrement.count;
  const nextIncrement = stepper.nextIncrement.count;

  const highlightMintVectorId = sale.mintVectorId;

  const simulation = usePrepareMintFromHighlightDutchAuction({
    chainId,
    count: mintQuantity,
    currentTotalPrice: stepper.current.pricing.totalPrice,
    highlightMintVectorId,
  });

  const nextDecrementSimulation = usePrepareMintFromHighlightDutchAuction({
    chainId,
    count: nextDecrement,
    currentTotalPrice: stepper.nextDecrement.pricing.totalPrice,
    highlightMintVectorId,
  });

  const nextIncrementSimulation = usePrepareMintFromHighlightDutchAuction({
    chainId,
    count: nextIncrement,
    currentTotalPrice: stepper.nextIncrement.pricing.totalPrice,
    highlightMintVectorId,
  });

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess(txHash) {
        txStore.startTracking({
          chainId,
          ui: 'toast',
          action: {
            name: MINT_FROM_DUTCH_AUCTION_ACTION_NAME,
            worldId,
            market: 'HIGHLIGHT',
          },
          txHash,
          title: MINT_FROM_DUTCH_AUCTION_TRACKING_COPY.title,
        });
      },
    },
  });

  const disabledReason = extractPrepareContractWriteRevertReason(simulation);

  return (
    <MintFromDutchAuctionFlowContext.Provider
      value={{
        action: MINT_FROM_DUTCH_AUCTION_ACTION_NAME,
        auction: {
          isMintedOut,
          totalMintedCount,
        },
        chainId,
        config: satisfiesExperimentalWeb3ActionConfig({
          disabledReason,
          hasTxPrompt: contractWrite.isPending,
          reset: contractWrite.reset,
          simulation,
          txHash: contractWrite.data || null,

          prompt: () => {
            if (simulation.isSuccess) {
              contractWrite.writeContract(simulation.data.request);
            }
          },
        }),
        itemPricing: itemPriceBreakdown,
        cartPricing: stepper.current.pricing,
        cartQuantity: {
          count: mintQuantity,
          onCountChange: setMintQuantity,
          decrement: createWeb3StepperControls({
            count: nextDecrement,
            simulation: nextDecrementSimulation,
            onClick: () => setMintQuantity(nextDecrement),
          }),
          increment: createWeb3StepperControls({
            count: nextIncrement,
            simulation: nextIncrementSimulation,
            onClick: () => setMintQuantity(nextIncrement),
          }),
        },
        onChainQuery: liveDutchAuctionQuery,
      }}
    >
      {children}
    </MintFromDutchAuctionFlowContext.Provider>
  );
}
