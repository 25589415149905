import { styled } from '@f8n-frontend/stitches';
import Head from 'next/head';
import Script from 'next/script';

import Skeleton from 'components/base/Skeleton';

import { useIsHydrated } from 'hooks/use-is-hydrated';

const WIDGET_TYPE = 'highlight-mint-card';

export default function HighlightEmbed(props: { highlightId: string }) {
  const isHydrated = useIsHydrated();

  if (!isHydrated) {
    return (
      <HighlightEmbedCard>
        <HighlightEmbedSkeleton />
      </HighlightEmbedCard>
    );
  }

  return (
    <>
      <Head>
        <link
          rel="stylesheet"
          href="https://mint.highlight.xyz/assets/embed.css"
        />
      </Head>
      <Script
        type="module"
        src="https://mint.highlight.xyz/assets/embed.js?v=1"
        async
      />
      <HighlightEmbedCard>
        <div
          key={props.highlightId}
          data-widget={WIDGET_TYPE}
          data-mint-collection-id={props.highlightId}
          data-use-content-as-loading="true"
          data-template="art-only"
          data-styles={createHighlightCustomStyles({
            root: {
              maxWidth: '100%',
            },
          })}
        >
          {/**
           * Skeleton renders until Highlight UI loads, via data-use-content-as-loading
           **/}
          <HighlightEmbedSkeleton />
        </div>
      </HighlightEmbedCard>
    </>
  );
}

const createHighlightCustomStyles = (options: {
  // At this time, `root` is the only custom style Highlight supports
  root: React.CSSProperties;
}): string => {
  const styles = {
    root: options.root,
  };

  // Need to convert to a string because Highlight expects this configuration via a DOM attribute
  return JSON.stringify(styles);
};

function HighlightEmbedSkeleton() {
  return <Skeleton.Block css={{ height: 672 }} />;
}

const HighlightEmbedCard = styled('div', {
  overflow: 'hidden',
  width: '100%',
});
