import Box from 'components/base/Box';
import { Checkout } from 'components/checkout-widget/Checkout';

import { FixedPriceDropSale } from 'types/DropSale';
import { LimitedEditionCollectionSale } from 'types/EditionSale';
import { CollectorsPreview } from 'types/collectors';

import { CollectorsSummary } from '../CollectorsSummary';

import { useMintWidget } from './MintWidgetContext';
import { MintWidgetSaleStats } from './MintWidgetSaleStats';

export function PostSaleMintWidget(props: {
  actions: React.ReactNode;
  collectors: CollectorsPreview;
  nftCount: number;
  sale: FixedPriceDropSale | LimitedEditionCollectionSale;
  totalSales: number;
}) {
  const { onCollectorsClick } = useMintWidget();

  return (
    <Checkout.Root>
      <Box css={{ display: 'flex', flexDirection: 'column', gap: '$4' }}>
        <MintWidgetSaleStats.Ended
          nftCount={props.nftCount}
          sale={props.sale}
          totalSales={props.totalSales}
        />
        {props.actions}
      </Box>
      <CollectorsSummary
        type="collector"
        collectors={props.collectors}
        onClick={onCollectorsClick}
        maxPreviews={2}
      />
    </Checkout.Root>
  );
}
