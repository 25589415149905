import { TrackingCopyMap } from 'state/stores/transactions';

export const CLAIM_REBATE_DUTCH_AUCTION_ACTION_NAME =
  'claim-buyer-rebate-from-dutch-auction';

export const CLAIM_REBATE_DUTCH_AUCTION_TRACKING_COPY: TrackingCopyMap = {
  title: {
    PENDING: 'Claiming rebate…',
    SUCCESS: 'Rebate claimed',
  },
  description: {
    PENDING: 'Your rebate is being claimed. You can close this if you like.',
    SUCCESS:
      'Your rebate has been successfully claimed and is now available in your wallet.',
  },
};
