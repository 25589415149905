import { Fragment } from 'react';
import { match } from 'ts-pattern';

import Button from 'components/base/Button';
import Text from 'components/base/Text';
import UserStack from 'components/follows/UserStack';
import UserTag from 'components/users/UserTag';

import { abbreviateValue } from 'utils/formatters';
import { pluralizeWord } from 'utils/strings';
import { createCssTarget } from 'utils/styles';

import { UserLight } from 'types/Account';
import { CollectorsPreview } from 'types/collectors';

const MINT_WIDGET_COLLECTORS_TARGET = createCssTarget('MINT');

type UserType = 'collector' | 'minter';

const actionMap: Record<UserType, string> = {
  collector: 'Collected',
  minter: 'Minted',
} as const;

export type CollectorsSummaryProps = {
  type: UserType;
  collectors: CollectorsPreview;
  onClick: () => void;
  maxPreviews: 2 | 3;
};

export function CollectorsSummary(props: CollectorsSummaryProps) {
  const { type, collectors, onClick, maxPreviews } = props;

  /** Filters out users with no names set, and enforces we display */
  const collectorsPreviewWithProfiles = collectors.preview
    .filter((user) => {
      if (!user.profileImageUrl) return false;

      return Boolean(user.name) || Boolean(user.username);
    })
    // reversed so that the names match the order of the avatars
    .reverse();

  const collectorsPreviewNames = collectorsPreviewWithProfiles.slice(
    0,
    maxPreviews
  );

  const renderedNameCount = collectorsPreviewNames.length;
  const othersCount = collectors.totalCount - renderedNameCount;

  if (renderedNameCount === 0) {
    return null;
  }

  const action = actionMap[type];

  return (
    <Button
      variant="base"
      onClick={onClick}
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '$1',

        '&:hover': {
          [MINT_WIDGET_COLLECTORS_TARGET.selector]: {
            color: '$black100',
          },
        },

        [MINT_WIDGET_COLLECTORS_TARGET.selector]: {
          textDecoration: 'underline',
          textUnderlineOffset: '4px',
          textDecorationColor: 'transparent',
          textDecorationThickness: '1px',
          transition: '$2 $ease text-decoration',
        },

        [MINT_WIDGET_COLLECTORS_TARGET.onHover]: {
          textDecorationColor: '$black100',
        },
      }}
    >
      <UserStack
        size="1"
        variant="white"
        users={collectorsPreviewWithProfiles.slice(0, 3)}
        disableLinks
      />
      <Text
        className={MINT_WIDGET_COLLECTORS_TARGET.className}
        weight="regular"
        size={0}
        color="dim"
        css={{
          whiteSpace: 'noWrap',
          div: {
            display: 'inline',
          },
        }}
      >
        {match({
          renderedNameCount,
          totalCollectorsCount: collectors.totalCount,
        })
          .with({ renderedNameCount: 1 }, () => {
            return (
              <>
                {action} by{' '}
                {collectorsPreviewNames.map((collector) => (
                  <CollectorUserTag
                    collector={collector}
                    key={collector.publicKey}
                  />
                ))}
              </>
            );
          })
          .with({ renderedNameCount: 2, totalCollectorsCount: 2 }, () => {
            return (
              <>
                {action} by{' '}
                {collectorsPreviewNames.map((collector, index) => (
                  <Fragment key={collector.publicKey}>
                    <CollectorUserTag collector={collector} />
                    {index === 0 && <> and </>}
                  </Fragment>
                ))}{' '}
              </>
            );
          })
          .with({ renderedNameCount: 3, totalCollectorsCount: 3 }, () => {
            return (
              <>
                {action} by{' '}
                {collectorsPreviewNames.map((collector, index) => (
                  <Fragment key={collector.publicKey}>
                    <CollectorUserTag collector={collector} />
                    {index === 0 && <>, </>}
                    {index === 1 && <> and </>}
                  </Fragment>
                ))}{' '}
              </>
            );
          })
          .otherwise(() => {
            const othersWord = pluralizeWord('other', othersCount);

            return (
              <>
                {action} by{' '}
                {collectorsPreviewNames.map((collector, index, arr) => (
                  <Fragment key={collector.publicKey}>
                    <CollectorUserTag collector={collector} />
                    {index !== arr.length - 1 && <>, </>}
                  </Fragment>
                ))}{' '}
                and {abbreviateValue(othersCount, { roundThousands: true })}{' '}
                {othersWord}
              </>
            );
          })}
      </Text>
    </Button>
  );
}

function CollectorUserTag(props: { collector: UserLight }) {
  return (
    <UserTag
      nameVariant="prefer-display-name"
      type="text"
      weight="medium"
      color="strong"
      user={props.collector}
      size={0}
      disableHoverCard
      disableLinks
    />
  );
}
