import { getDutchAuctionV3 } from '@f8n/f8n-contracts/dist/abis/FNDMiddlewareHelpers.generated';
import { useReadContract, useWriteContract, useSimulateContract } from 'wagmi';

import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useTransactionStore from 'state/stores/transactions';

import { useQueryEffects } from 'hooks/react-query';
import { useDutchAuctionSellerInfo } from 'hooks/web3/use-dutch-auction-seller-info';
import { useExhibitionIdForCollection } from 'hooks/web3/use-exhibition-id-for-collection';
import { FNDMiddleware } from 'lib/abis/FNDMiddleware';
import { NFTDropMarket } from 'lib/abis/NFTDropMarket';
import { getContractAddress } from 'lib/addresses';
import { ZERO_ADDRESS } from 'lib/constants';
import report from 'lib/report';
import { getAddress } from 'utils/address';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';

import { ExtractModalOptions } from 'types/modal';

import { ClaimRebateDutchAuctionFlowContext } from './ClaimRebateDutchAuctionFlowContext';
import {
  CLAIM_REBATE_DUTCH_AUCTION_ACTION_NAME,
  CLAIM_REBATE_DUTCH_AUCTION_TRACKING_COPY,
} from './constants';
import { ClaimRebateDutchAuctionProviderProps } from './types';

type ClaimRebateFoundationDutchAuctionProviderProps =
  ClaimRebateDutchAuctionProviderProps &
    ExtractModalOptions<'CLAIM_REBATE', { market: 'FND' }>;

export function ClaimRebateFoundationDutchAuctionProvider(
  props: ClaimRebateFoundationDutchAuctionProviderProps
) {
  const { children, contractAddress, chainId } = props;

  const auth = useAuth();
  const txStore = useTransactionStore();

  const simulateClaimFndDutchAuctionRebate = useSimulateContract({
    abi: NFTDropMarket,
    functionName: 'rebateBuyerFromDutchAuction',
    address: getContractAddress({
      chainId,
      contractName: 'nftDropMarket',
    }),
    chainId,
    query: {
      enabled: hasPublicKey(auth),
      retry: false,
    },
    args: hasPublicKey(auth)
      ? [getAddress(contractAddress), auth.publicKey]
      : undefined,
  });

  useQueryEffects(simulateClaimFndDutchAuctionRebate, {
    onError(error) {
      report(extractPrepareContractWriteRevertReason({ error }));
    },
  });

  const sellerInfoQuery = useDutchAuctionSellerInfo({
    contractAddress,
    chainId,
  });

  const exhibitionIdForCollection = useExhibitionIdForCollection(
    {
      chainId,
      contractAddress,
      seller: sellerInfoQuery.data ? sellerInfoQuery.data.seller : ZERO_ADDRESS,
    },
    { enabled: Boolean(sellerInfoQuery.data) }
  );

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess(txHash) {
        txStore.startTracking({
          ui: 'toast',
          chainId,
          action: {
            name: CLAIM_REBATE_DUTCH_AUCTION_ACTION_NAME,
            worldId: exhibitionIdForCollection.data
              ? Number(exhibitionIdForCollection.data.worldId)
              : null,
            market: 'FND',
          },
          txHash,
          title: CLAIM_REBATE_DUTCH_AUCTION_TRACKING_COPY.title,
          description: CLAIM_REBATE_DUTCH_AUCTION_TRACKING_COPY.description,
        });
      },
    },
  });

  const dutchAuctionQuery = useReadContract({
    abi: FNDMiddleware,
    address: getContractAddress({
      chainId,
      contractName: 'middleware',
    }),
    chainId,
    functionName: 'getDutchAuctionV3',
    args: [
      getAddress(contractAddress),
      hasPublicKey(auth) ? auth.publicKey : ZERO_ADDRESS,
    ],
    query: {
      select: getDutchAuctionV3.parseOutput,
    },
  });

  const isMintedOut = dutchAuctionQuery.data
    ? Boolean(
        props.maxTokenId === Number(dutchAuctionQuery.data.mintedNftCount)
      )
    : false;

  const parsedError = extractPrepareContractWriteRevertReason(
    simulateClaimFndDutchAuctionRebate
  );

  const rebateBalance = dutchAuctionQuery.data
    ? dutchAuctionQuery.data.outstandingRebateBalance
    : BigInt(0);
  const txHash = contractWrite.data || null;

  return (
    <ClaimRebateDutchAuctionFlowContext.Provider
      value={{
        action: CLAIM_REBATE_DUTCH_AUCTION_ACTION_NAME,
        chainId,
        write: () => {
          if (simulateClaimFndDutchAuctionRebate.isSuccess) {
            contractWrite.writeContract(
              simulateClaimFndDutchAuctionRebate.data.request
            );
          }
        },
        isDisabled: !simulateClaimFndDutchAuctionRebate.isSuccess,
        isLoading: contractWrite.isPending || Boolean(contractWrite.data),
        parsedError,
        isMintedOut,
        rebateBalance,
        txHash,
      }}
    >
      {children}
    </ClaimRebateDutchAuctionFlowContext.Provider>
  );
}
