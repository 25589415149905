import { useContext } from 'react';

import { MintFromDutchAuctionFlowContext } from './MintFromDutchAuctionFlowContext';

export const useMintFromDutchAuctionFlow = () => {
  const context = useContext(MintFromDutchAuctionFlowContext);

  if (!context) {
    throw new Error(
      'useMintFromDutchAuctionFlow must be used within a MintFromDutchAuctionFlowContext provider'
    );
  }

  return context;
};
