import { FNDMiddleware } from '@f8n/f8n-contracts/dist/abis/FNDMiddleware.generated';
import { getDutchAuctionV3 } from '@f8n/f8n-contracts/dist/abis/FNDMiddlewareHelpers.generated';
import { addMinutes, isFuture, isPast, parseJSON } from 'date-fns';
import { useReadContract } from 'wagmi';

import { hasPublicKey, isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { ApiDropFragment } from 'gql/api/api-fragments.generated';
import useModal from 'hooks/use-modal';
import { useRefetchOnBlock } from 'hooks/web3/use-watch-block';
import { getContractAddress } from 'lib/addresses';
import { ZERO_ADDRESS } from 'lib/constants';
import { getAddress } from 'utils/address';
import { isBigInt } from 'utils/bigint';
import { isZero } from 'utils/numbers';

import { FndLinearDutchAuctionSale } from 'types/DropSale';

import DutchAuctionClaimAction from './DutchAuctionClaimAction';
import {
  ClaimRebateAction,
  WithdrawEarningsAction,
} from './collections/DutchAuctionClaimActions';

interface LinearDutchAuctionClaimActionsProps {
  sale: FndLinearDutchAuctionSale;
  collection: Pick<
    ApiDropFragment,
    'chainId' | 'creator' | 'contractAddress' | 'nftCount' | 'worldId'
  >;
}

export default function LinearDutchAuctionClaimActions(
  props: LinearDutchAuctionClaimActionsProps
) {
  const { sale, collection } = props;
  const { chainId } = collection;

  const auth = useAuth();
  const modal = useModal();

  const endDate = parseJSON(sale.endTime);

  const dutchAuctionQuery = useReadContract({
    abi: FNDMiddleware,
    address: getContractAddress({
      chainId,
      contractName: 'middleware',
    }),
    chainId,
    functionName: 'getDutchAuctionV3',
    args: [
      getAddress(collection.contractAddress),
      hasPublicKey(auth) ? auth.publicKey : ZERO_ADDRESS,
    ],
    query: {
      select: getDutchAuctionV3.parseOutput,
    },
  });

  useRefetchOnBlock(dutchAuctionQuery, {
    /**
     * add 5 minutes of buffer so that enough time passes for blocks
     * after the sale has ended (to ensure the correct clearing price)
     */
    enabled: endDate ? isFuture(addMinutes(endDate, 5)) : false,
  });

  const isMintedOut =
    dutchAuctionQuery.data && isBigInt(dutchAuctionQuery.data.mintedNftCount)
      ? sale.maxTokenId === Number(dutchAuctionQuery.data.mintedNftCount)
      : false;

  const hasEarningsToWithdraw =
    dutchAuctionQuery.data &&
    isBigInt(dutchAuctionQuery.data.totalFundsPendingDistribution)
      ? dutchAuctionQuery.data.totalFundsPendingDistribution > BigInt(0)
      : false;

  const isWithdrawEnabled = endDate ? isMintedOut || isPast(endDate) : false;

  const rebateBalance = dutchAuctionQuery.data
    ? dutchAuctionQuery.data.outstandingRebateBalance
    : BigInt(0);

  return (
    <DutchAuctionClaimAction.PinBottom>
      {isMyAddress(auth, collection.creator.publicKey) &&
        hasEarningsToWithdraw && (
          <WithdrawEarningsAction
            onClick={() => {
              modal.setModal({
                type: 'CLAIM_DUTCH_AUCTION_REVENUE',
                market: 'FND',
                contractAddress: collection.contractAddress,
                chainId: collection.chainId,
              });
            }}
            isEnabled={isWithdrawEnabled}
          />
        )}
      {!dutchAuctionQuery.data || isZero(rebateBalance) ? null : (
        <ClaimRebateAction
          rebateBalance={rebateBalance}
          onClick={() => {
            modal.setModal({
              type: 'CLAIM_REBATE',
              chainId: collection.chainId,
              market: 'FND',
              contractAddress: collection.contractAddress,
              maxTokenId: sale.maxTokenId,
              endDate,
            });
          }}
        />
      )}
    </DutchAuctionClaimAction.PinBottom>
  );
}
