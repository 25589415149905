import { styled } from '@f8n-frontend/stitches';
import { getUnixTime, isFuture, isPast } from 'date-fns';
import React from 'react';
import { useMeasure } from 'react-use';

import { Dot } from 'components/Dot';
import Pulse from 'components/Pulse';
import RenderCountdown from 'components/RenderCountdown';
import { SaleStat } from 'components/SaleStat';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Text from 'components/base/Text';
import Tooltip from 'components/base/Tooltip';

import { formatDuration, formatRelativeDateTime } from 'utils/dates/dates';
import { formatMintPrice } from 'utils/formatters';

type GizmoStatProps = {
  isMintedOut: boolean;
  lastMintedAtDate: Date | null;
  minPrice: number;
  /** start date for Dutch auction */
  startDate: Date;
  /** end date for Dutch auction */
  endDate: Date;
  /** end date for mint period (assumed to end after the auction) */
  mintEndDate: Date | null;
};

export function GizmoStat(props: GizmoStatProps) {
  const {
    endDate,
    isMintedOut,
    lastMintedAtDate,
    minPrice,
    mintEndDate,
    startDate,
  } = props;

  if (isMintedOut) {
    return (
      <RootContainer>
        <MessageText color="unavailable">
          <SaleStat.MintOverBadge variant="minted-out" />
        </MessageText>
        {lastMintedAtDate && (
          <>
            <Dot />
            <MessageText color="dim">
              {formatRelativeDateTime(lastMintedAtDate)}
            </MessageText>
          </>
        )}
      </RootContainer>
    );
  }

  if (isFuture(startDate)) {
    return (
      <RootContainer variant="stats">
        <MinPrice minPrice={minPrice} />
        <Separator />
        <StatContainer>
          <StatLabel>Duration</StatLabel>
          <StatValue>
            {formatDuration({
              endDate,
              startDate,
            })}{' '}
          </StatValue>
        </StatContainer>
      </RootContainer>
    );
  }

  if (isPast(startDate) && isFuture(endDate)) {
    return (
      <RootContainer variant="stats">
        <MinPrice minPrice={minPrice} />
        <Separator />
        <GizmoCountdown endDate={endDate} label="Price rests in" />
      </RootContainer>
    );
  }

  if (mintEndDate && isPast(mintEndDate)) {
    return (
      <RootContainer>
        <MessageText color="unavailable">
          <SaleStat.MintOverBadge variant="ended" />
        </MessageText>
      </RootContainer>
    );
  }

  if (mintEndDate && isFuture(mintEndDate)) {
    return (
      <RootContainer variant="stats">
        <StatContainer css={{ color: 'currentColor' }}>
          <StatLabel>Status</StatLabel>
          <Flex css={{ alignItems: 'center', gap: '$2' }}>
            <Pulse size={16} color="currentColor" />
            <StatValue>Minting</StatValue>
          </Flex>
        </StatContainer>
        <GizmoCountdown endDate={mintEndDate} label="Mint closes" />
      </RootContainer>
    );
  }

  if (isPast(endDate)) {
    return (
      <RootContainer>
        <Pulse size={16} color="currentColor" />
        <MessageText color="strong">Minting open</MessageText>
      </RootContainer>
    );
  }

  return null;
}

type MinPriceProps = {
  minPrice: number;
};

function MinPrice(props: MinPriceProps) {
  return (
    <Tooltip
      content="The final mint price (pending availability) once the timer ends"
      placement="bottom"
      size={1}
    >
      <StatContainer>
        <StatLabel>Resting price</StatLabel>
        <StatValue>
          {formatMintPrice(props.minPrice, { hasEthSuffix: false })}
          <Box as="span" css={{ fontSize: '$1', marginLeft: '2px' }}>
            ETH
          </Box>
        </StatValue>
      </StatContainer>
    </Tooltip>
  );
}

function GizmoCountdown(props: { endDate: Date; label: string }) {
  const { endDate, label } = props;

  const [measureRef, dimensions] = useMeasure<HTMLDivElement>();

  return (
    <>
      <HiddenOffscreenText ref={measureRef}>00s</HiddenOffscreenText>
      <RenderCountdown
        render={(countdown) => {
          if (!countdown.message) return null;

          return (
            <StatContainer>
              <StatLabel>{label}</StatLabel>
              <StatValue>
                <GizmoCountdownContainer>
                  {countdown.message.split(' ').map((unit, index, array) => {
                    const hasMultipleUnits = array.length > 1;
                    const isLastUnit = index === array.length - 1;

                    return (
                      <span
                        key={index}
                        style={
                          hasMultipleUnits && isLastUnit
                            ? {
                                /**
                                 * Apply a fixed width to prevent the countdown from shifting around every second
                                 */
                                width: dimensions.width,
                              }
                            : undefined
                        }
                      >
                        {unit}
                      </span>
                    );
                  })}
                </GizmoCountdownContainer>
              </StatValue>
            </StatContainer>
          );
        }}
        endsAtTimestamp={getUnixTime(endDate)}
        maxUnits={3}
      />
    </>
  );
}

const HiddenOffscreenText = styled('span', {
  display: 'inline-block',
  fontSize: 'inherit',
  position: 'absolute',
  visibility: 'hidden',
  pointerEvents: 'none',
  opacity: 0,
});

const GizmoCountdownContainer = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$1',
});

const RootContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$2',

  variants: {
    variant: {
      inline: {},
      stats: {
        gap: '$6',
      },
    },
  },
});

const Separator = styled('div', {
  width: '1px',
  height: '48px',
  backgroundColor: '$black10',
});

const MessageText = styled(Text);
MessageText.defaultProps = {
  size: 1,
};

const StatContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  height: '100%',
  justifyContent: 'space-between',
  textAlign: 'center',
  cursor: 'default',
});

const StatLabel = styled(Text);
StatLabel.defaultProps = {
  size: 1,
  color: 'dim',
  lineHeight: 1,
  weight: 'medium',
};

const StatValue = styled(Text);
StatValue.defaultProps = {
  size: { '@initial': 1, '@bp0': 3 },
  lineHeight: 1,
  color: 'strong',
  weight: 'medium',
};
