import { styled } from '@f8n-frontend/stitches';

export const GIZMO_SIZE = 520;
export const GIZMO_CENTER = GIZMO_SIZE / 2;
export const GIZMO_STROKE_WIDTH = 10;
export const GIZMO_RADIUS = GIZMO_CENTER - GIZMO_STROKE_WIDTH;
export const GIZMO_VIEWBOX = `0 0 ${GIZMO_SIZE} ${GIZMO_SIZE}`;

export const GizmoSvg = styled('svg', {
  display: 'block',
  overflow: 'visible',
  position: 'relative',
  width: '100%',
  height: '100%',
});

export const GizmoCircle = styled('circle', { fill: 'transparent' });
