import type { Address } from 'viem';
import { useReadContract } from 'wagmi';

import {
  NFTDropMarket,
  getSellerInfoFromDutchAuction,
} from 'lib/abis/NFTDropMarket';
import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';
import { getAddress } from 'utils/address';

type Options = {
  enabled?: boolean;
};

export function useDutchAuctionSellerInfo(
  variables: {
    contractAddress: Address;
    chainId: ChainId;
  },
  options: Options = {}
) {
  return useReadContract({
    abi: NFTDropMarket,
    functionName: 'getSellerInfoFromDutchAuction',
    address: getContractAddress({
      chainId: variables.chainId,
      contractName: 'nftDropMarket',
    }),
    chainId: variables.chainId,
    args: [getAddress(variables.contractAddress)],
    query: {
      enabled: options.enabled ?? true,
      select: getSellerInfoFromDutchAuction.parseOutput,
    },
  });
}
