import { useContext } from 'react';

import { MintFromFixedPriceContext } from './MintFromFixedPriceContext';

export const useMintFromFixedPriceFlow = () => {
  const context = useContext(MintFromFixedPriceContext);

  if (!context) {
    throw new Error(
      'useMintFromFixedPriceFlow must be used within a MintFromFixedPrice provider'
    );
  }

  return context;
};
