import { P, match } from 'ts-pattern';
import { useWriteContract, useSimulateContract } from 'wagmi';

import { hasPublicKey } from 'contexts/auth/helpers';
import { Auth } from 'contexts/auth/types';
import useAuth from 'contexts/auth/useAuth';
import useTransactionStore from 'state/stores/transactions';
import { satisfiesExperimentalWeb3ActionConfig } from 'web3/config';

import { useDrop } from 'gql/api/queries/drop.generated';
import { useExhibitionIdBySplit } from 'hooks/web3/use-exhibition-id-by-split';
import { HighlightMintManager } from 'lib/abis/HighlightMintManager';
import { getHighlightAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';
import { ZERO_ADDRESS } from 'lib/constants';
import { HIGHLIGHT_MINT_FEE } from 'lib/highlight';
import { formatETHWithSuffix } from 'utils/formatters';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';
import { pluralizeWord } from 'utils/strings';
import { getMintPriceStepperBreakdown } from 'utils/transactions';
import { parseEther } from 'utils/units';
import { createWeb3StepperControls } from 'utils/web3-cta';

import { HighlightFixedPriceSale } from 'types/DropSale';
import { StepperBreakdown } from 'types/MintFee';

import { MintFromFixedPriceContext } from './MintFromFixedPriceContext';
import { MintFromFixedPriceProviderMinimalProps } from './types';

type MintFromHighlightFixedPriceProviderProps =
  MintFromFixedPriceProviderMinimalProps & {
    sale: HighlightFixedPriceSale;
  };

const ACTION = 'mint-from-drop';

export function MintFromHighlightFixedPriceProvider(
  props: MintFromHighlightFixedPriceProviderProps
) {
  const {
    chainId,
    children,
    contractAddress,
    mintQuantity,
    sale,
    setMintQuantity,
  } = props;
  const mintVectorId = sale.mintVectorId;
  const auth = useAuth();
  const { mintPrice } = sale;

  const stepper = getMintPriceStepperBreakdown({
    count: mintQuantity,
    fee: HIGHLIGHT_MINT_FEE,
    price: parseEther(mintPrice.toString()),
  });

  const nextDecrement = stepper.nextDecrement.count;
  const nextIncrement = stepper.nextIncrement.count;

  const simulation = useSimulateMintFromHighlightFixedPrice({
    auth,
    chainId,
    mintVectorId,
    step: stepper.current,
  });

  const nextDecrementSimulation = useSimulateMintFromHighlightFixedPrice({
    auth,
    chainId,
    mintVectorId,
    step: stepper.nextDecrement,
  });

  const nextIncrementSimulation = useSimulateMintFromHighlightFixedPrice({
    auth,
    chainId,
    mintVectorId,
    step: stepper.nextIncrement,
  });

  const dropQuery = useDrop({ chainId, contractAddress });

  const dropSellerPublicKey = match(dropQuery.data)
    .with(
      { drop: { creator: { publicKey: P.string } } },
      (data) => data.drop.creator.publicKey
    )
    .otherwise(() => ZERO_ADDRESS);

  const exhibitionIdForCollection = useExhibitionIdBySplit(
    { chainId, contractAddress, seller: dropSellerPublicKey },
    { enabled: Boolean(dropQuery.isSuccess) }
  );

  const txStore = useTransactionStore();

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess: (txHash) => {
        const mintPriceFormatted = formatETHWithSuffix(Number(mintPrice));
        const pluralizedWord = pluralizeWord('NFT', mintQuantity);

        txStore.startTracking({
          action: {
            name: ACTION,
            quantity: mintQuantity,
            txPrice: stepper.current.pricing.totalPrice,
            worldId: exhibitionIdForCollection.data
              ? Number(exhibitionIdForCollection.data.worldId)
              : null,
          },
          chainId,
          txHash,
          ui: 'toast',
          title: {
            PENDING: `Minting ${mintQuantity} ${pluralizedWord}…`,
            SUCCESS: `Minted ${mintQuantity} ${pluralizedWord}`,
          },
          description: {
            SUCCESS: `Your NFTs were minted for a price of ${mintPriceFormatted}. They are now in your wallet and displayed on your profile.`,
          },
        });
      },
    },
  });

  const disabledReason = extractPrepareContractWriteRevertReason(simulation);

  return (
    <MintFromFixedPriceContext.Provider
      value={{
        action: ACTION,
        chainId,
        config: satisfiesExperimentalWeb3ActionConfig({
          disabledReason,
          hasTxPrompt: contractWrite.isPending,
          reset: contractWrite.reset,
          simulation,
          txHash: contractWrite.data || null,

          prompt: () => {
            if (simulation.isSuccess) {
              contractWrite.writeContract(simulation.data.request);
            }
          },
        }),
        pricing: stepper.current.pricing,
        quantity: {
          count: mintQuantity,
          onCountChange: setMintQuantity,
          decrement: createWeb3StepperControls({
            count: nextDecrement,
            simulation: nextDecrementSimulation,
            onClick: () => setMintQuantity(nextDecrement),
          }),
          increment: createWeb3StepperControls({
            count: nextIncrement,
            simulation: nextIncrementSimulation,
            onClick: () => setMintQuantity(nextIncrement),
          }),
        },
      }}
    >
      {children}
    </MintFromFixedPriceContext.Provider>
  );
}

const useSimulateMintFromHighlightFixedPrice = (options: {
  auth: Auth;
  chainId: ChainId;
  mintVectorId: HighlightFixedPriceSale['mintVectorId'];
  step: StepperBreakdown;
}) => {
  const { auth, chainId, mintVectorId, step } = options;
  const { count, pricing } = step;

  const mintRecipient = hasPublicKey(auth) ? auth.publicKey : '';

  return useSimulateContract({
    abi: HighlightMintManager,
    functionName: 'vectorMint721',
    address: getHighlightAddress({
      chainId,
      contractName: 'mintManager',
    }),
    chainId,
    args: mintRecipient
      ? [BigInt(mintVectorId), count, mintRecipient]
      : undefined,
    value: pricing.totalPrice,
    query: {
      enabled: hasPublicKey(auth),
      retry: false,
    },
  });
};
