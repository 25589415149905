import { NFTDropMarket } from '@f8n/f8n-contracts/dist/abis/NFTDropMarket.generated';
import { useReadContract } from 'wagmi';

import { getFixedPriceSaleV2 } from 'lib/abis/NFTDropMarket';
import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';
import { getAddress } from 'utils/address';

type Options = {
  enabled?: boolean;
};

export function useGetFixedPriceSale(
  variables: { contractAddress: string; chainId: ChainId },
  options: Options = {}
) {
  return useReadContract({
    abi: NFTDropMarket,
    functionName: 'getFixedPriceSaleV2',
    address: getContractAddress({
      chainId: variables.chainId,
      contractName: 'nftDropMarket',
    }),
    chainId: variables.chainId,
    args: [getAddress(variables.contractAddress)],
    query: {
      enabled: options.enabled ?? true,
      select: getFixedPriceSaleV2.parseOutput,
    },
  });
}
