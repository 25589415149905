import { useRef } from 'react';
import { P, match } from 'ts-pattern';
import { Address } from 'viem';

import NftMedia from 'components/NftMedia';
import DropdownMenu from 'components/base/DropdownMenu';
import MarkdownWithReadMoreModal from 'components/base/MarkdownWithReadMoreModal';
import HighlightEmbed from 'components/collections/HighlightEmbed';
import {
  AdminOnlyCollectionMenuItem,
  AdminOnlyDropMenuItem,
  AdminToolsMenuItem,
} from 'components/menu-items/AdminOnlyMenuItem';
import { ReportMenuItem } from 'components/menu-items/ReportDropdownMenuItem';
import { SelfDestructCollectionMenuItem } from 'components/menu-items/SelfDestructCollectionMenuItem';
import { isAdmin } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { ApiSplitFragment } from 'gql/api/api-fragments.generated';
import { ChainConfig } from 'lib/chains';
import { getCollectionFilter } from 'utils/inputs';
import { isNull } from 'utils/predicates';

import { UserLight } from 'types/Account';
import { ContractCategory, ContractType } from 'types/Collection';
import { CuratedStore } from 'types/CuratedStore';
import { LaunchMediaAsset, MediaAsset, PreviewMediaAsset } from 'types/media';

import { MintLayout, useMediaContainerSize } from './MintLayout';
import {
  MintLayoutFullscreenImageMedia,
  useFullscreenMedia,
} from './MintLayoutFullscreenImageMedia';
import { MintLayoutTable } from './MintLayoutTable';

type CollectionMintLayoutProps = {
  blockExplorerHref: string;
  canSelfDestructCollection: boolean;
  chainConfig: ChainConfig;
  collectionName: string;
  contractAddress: Address;
  contractCategory: ContractCategory;
  contractType: ContractType;
  creator: UserLight;
  creatorFollowersCount: number | null;
  description: string | null;
  highlightId: string | null;
  media: MediaAsset | null;
  mediaFullscreen: PreviewMediaAsset | null;
  mediaAssetMedium: string | null;
  mintWidget: React.ReactNode;
  onShare: () => void;
  onSplitsClick: () => void;
  splits: ApiSplitFragment[];
  store: CuratedStore | null;
};

export function CollectionMintLayout(props: CollectionMintLayoutProps) {
  const {
    blockExplorerHref,
    canSelfDestructCollection,
    chainConfig,
    collectionName,
    contractAddress,
    contractCategory,
    contractType,
    creator,
    creatorFollowersCount,
    description,
    highlightId,
    media,
    mediaFullscreen,
    mediaAssetMedium,
    mintWidget,
    onShare,
    onSplitsClick,
    store,
    splits,
  } = props;

  const auth = useAuth();

  const collectionFilter = getCollectionFilter({
    chainId: chainConfig.chainId,
    contractAddress,
  });
  const { isFullscreen, toggleFullscreen } = useFullscreenMedia();

  const isFullscreenSupported = isNull(highlightId);

  return (
    <MintLayout.Root>
      {isFullscreenSupported && mediaFullscreen && (
        <MintLayoutFullscreenImageMedia
          media={mediaFullscreen}
          isFullscreen={isFullscreen}
          toggleFullscreen={toggleFullscreen}
        />
      )}
      <MintLayout.Body>
        <CollectionMedia
          category={contractCategory}
          highlightId={highlightId}
          media={media}
          onMediaClick={toggleFullscreen}
        />
      </MintLayout.Body>
      <MintLayout.Sidebar>
        <MintLayout.SidebarHeader
          name={collectionName}
          category={contractCategory}
          creator={creator}
          type="COLLECTION"
        />
        {mintWidget}
        {description && (
          <MintLayout.SidebarSection heading="Description">
            <MarkdownWithReadMoreModal
              user={creator}
              size="small"
              align="left"
              title={collectionName}
            >
              {description}
            </MarkdownWithReadMoreModal>
          </MintLayout.SidebarSection>
        )}
        <MintLayout.SidebarSection gap="$4" heading="Details">
          <MintLayoutTable.Details
            blockExplorerHref={blockExplorerHref}
            collection={null}
            chainConfig={chainConfig}
            contractAddress={contractAddress}
            contractType={contractType}
            medium={mediaAssetMedium}
            metadataUrl={null}
            owner={null}
            sourceUrl={null}
            splits={splits.map((split) => split.user)}
            onSplitsClick={onSplitsClick}
            tokenId={null}
            tokenCategory="nft"
          />
        </MintLayout.SidebarSection>
        <MintLayout.SidebarStoreSection store={store} />
        <MintLayout.SidebarSection heading="Artist">
          <MintLayout.SidebarArtist
            user={creator}
            userFollowersCount={creatorFollowersCount}
          />
        </MintLayout.SidebarSection>
        <MintLayout.SidebarSection>
          <MintLayout.SidebarActions
            isReferralRewardsSupported={false}
            onShare={onShare}
            menuItems={
              <>
                {isAdmin(auth) && (
                  <>
                    <AdminToolsMenuItem />
                    <AdminOnlyCollectionMenuItem.RefreshOwner
                      collection={collectionFilter}
                    />
                    {contractCategory === 'DROP' && (
                      <AdminOnlyDropMenuItem.ClearStagedAssets
                        collection={collectionFilter}
                      />
                    )}
                    <DropdownMenu.Divider />
                  </>
                )}
                {canSelfDestructCollection && (
                  <SelfDestructCollectionMenuItem
                    collection={collectionFilter}
                  />
                )}
                <ReportMenuItem />
              </>
            }
          />
        </MintLayout.SidebarSection>
      </MintLayout.Sidebar>
    </MintLayout.Root>
  );
}

function CollectionMedia(props: {
  category: ContractCategory;
  highlightId: string | null;
  media: MediaAsset | null;
  onMediaClick: () => void;
}) {
  return match(props)
    .with({ highlightId: P.string }, ({ highlightId }) => {
      return <CollectionMediaHighlightEmbed highlightId={highlightId} />;
    })
    .with(
      { media: { type: P.union('image', 'video') } },
      ({ category, media, onMediaClick }) => {
        return (
          <CollectionMediaAsset
            category={category}
            media={media}
            onMediaClick={onMediaClick}
          />
        );
      }
    )
    .otherwise(() => null);
}

function CollectionMediaHighlightEmbed(props: { highlightId: string }) {
  const { highlightId } = props;

  return (
    <MintLayout.MediaContainer
      css={{
        height: 'auto',

        '@bp2': { width: '100%', maxWidth: 700 },
      }}
    >
      <HighlightEmbed highlightId={highlightId} />
    </MintLayout.MediaContainer>
  );
}

function CollectionMediaAsset(props: {
  category: ContractCategory;
  media: LaunchMediaAsset;
  onMediaClick: () => void;
}) {
  const { category, media, onMediaClick } = props;

  const mediaRef = useRef(null);
  const mediaContainerSize = useMediaContainerSize(mediaRef);

  const mediaSmallprint = match(category)
    .with('DROP', () => {
      return {
        label: 'Pre-reveal image',
        tooltip:
          'This representative image will be replaced by a final output when the artist reveals the collection.',
      };
    })
    .otherwise(() => null);

  return (
    <MintLayout.MediaContainer ref={mediaRef}>
      <NftMedia
        containerAspectRatio={mediaContainerSize.aspectRatio}
        media={media}
        variant="flat"
        smallprint={mediaSmallprint}
        onClick={onMediaClick}
      />
    </MintLayout.MediaContainer>
  );
}
