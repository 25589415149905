import { erc721Abi } from 'viem';
import type { Address } from 'viem';
import { useReadContract } from 'wagmi';

import { ChainId } from 'lib/chains';

type UseMintedTokenCountVariables = {
  chainId: ChainId;
  contractAddress: Address;
};

type UseMintedTokenCountOptions = {
  enabled: boolean;
};

/**
 * totalSupply is the number of tokens that have been minted on an ERC721 contract, but that term is confusing because
 * we often refer to the "supply" of the Drop as the maximum number of tokens that can be minted.
 *
 * For this reason, this hook is called useMintedTokenCount, despite it calling the totalSupply function internally.
 */
export default function useMintedTokenCount(
  variables: UseMintedTokenCountVariables,
  options: UseMintedTokenCountOptions
) {
  return useReadContract({
    abi: erc721Abi,
    address: variables.contractAddress,
    chainId: variables.chainId,
    functionName: 'totalSupply',
    query: {
      select: (data) => {
        return Number(data);
      },
      enabled: options.enabled,
    },
  });
}
