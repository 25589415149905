import { isPast, parseJSON } from 'date-fns';
import { useTimeout } from 'react-use';

import { calculatePercentElapsed } from 'utils/drops';

import Gizmo from './gizmo';

type LinearDutchAuctionGizmoProps = {
  brandColor: string;
  currentPrice: number;
  endsAt: Date;
  isMintedOut: boolean;
  lastMintedAtDate: Date | null;
  minPrice: number;
  startsAt: Date;
};

export function LinearDutchAuctionGizmo(props: LinearDutchAuctionGizmoProps) {
  const {
    brandColor,
    currentPrice,
    endsAt,
    isMintedOut,
    lastMintedAtDate,
    minPrice,
    startsAt,
  } = props;

  const [isReady] = useTimeout(500);

  const percentElapsed = calculatePercentElapsed({
    startsAt,
    endsAt,
    lastMintedAt: lastMintedAtDate,
    isMintedOut,
  });

  const percent = isReady() ? percentElapsed : 0;
  const isCleared = isMintedOut || isPast(endsAt);

  const priceStage = isCleared
    ? 'final'
    : isPast(parseJSON(startsAt))
      ? 'current'
      : 'starting';

  return (
    <Gizmo.Root style={{ color: brandColor }}>
      <Gizmo.LinearCircle
        isMintedOut={isMintedOut}
        percentCompleted={percent}
      />
      <Gizmo.Content>
        <Gizmo.Price price={currentPrice} stage={priceStage} />
        <Gizmo.Stat
          endDate={endsAt}
          isMintedOut={isMintedOut}
          lastMintedAtDate={lastMintedAtDate}
          minPrice={minPrice}
          mintEndDate={null} // Foundation drops don't support mint end dates
          startDate={startsAt}
        />
      </Gizmo.Content>
    </Gizmo.Root>
  );
}
