import { styled } from '@f8n-frontend/stitches';
import { VariantProps } from '@stitches/react';
import { motion } from 'framer-motion';
import { ComponentProps, ReactNode } from 'react';

import { OnlyChildrenProps } from 'types/components';

import Button from './base/Button';
import Text from './base/Text';

type DutchAuctionClaimActionProps = {
  label: ReactNode;
  children: ReactNode;
  color?: VariantProps<typeof ButtonContainer>['color'];
  buttonProps?: ComponentProps<typeof Button>;
};

function DutchAuctionClaimActionBase(props: DutchAuctionClaimActionProps) {
  return (
    <ButtonContainer color={props.color}>
      <DetailsContainer>
        <ClaimActionLabel>{props.label}</ClaimActionLabel>
      </DetailsContainer>
      <ClaimActionButton {...props.buttonProps}>
        {props.children}
      </ClaimActionButton>
    </ButtonContainer>
  );
}

function AnimateIn(props: OnlyChildrenProps) {
  return (
    <motion.div
      initial={{
        y: '200%',
      }}
      animate={{
        y: 0,
      }}
      exit={{
        y: '200%',
      }}
      transition={{
        type: 'spring',
        bounce: 0.25,
        duration: 0.35,
      }}
    >
      {props.children}
    </motion.div>
  );
}

const ClaimActionLabel = styled(Text, {
  color: '$white100',
  display: 'flex',
  gap: '$2',
});

ClaimActionLabel.defaultProps = {
  size: { '@initial': 1, '@bp1': 3 },
  weight: 'semibold',
};

const ClaimActionButton = styled(Button, {});

ClaimActionButton.defaultProps = {
  size: { '@bp1-max': 0, '@bp1': 2 },
  variant: 'raised',
};

const DetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
});

const ButtonContainer = styled('div', {
  whiteSpace: 'nowrap',
  flexShrink: 0,
  display: 'flex',
  padding: '$2',
  boxShadow: '$soft1',

  color: '$white100',
  borderRadius: '$round',
  paddingLeft: '$4',
  gap: '$2',
  alignItems: 'center',

  '@bp1': {
    paddingLeft: '$7',
    gap: '$5',
  },

  variants: {
    color: {
      black: { backgroundColor: '$black100' },
      green: { backgroundColor: '$green4' },
      blue: { backgroundColor: '$blue4' },
    },
  },
});

ButtonContainer.defaultProps = {
  color: 'black',
};

const PinBottom = styled('div', {
  transform: 'translateX(-50%)',
  position: 'fixed',
  left: '50%',
  bottom: '$6',
  zIndex: 9,
  display: 'flex',
  gap: '$2',
});

const DutchAuctionClaimAction = Object.assign(DutchAuctionClaimActionBase, {
  PinBottom,
  AnimateIn,
});

export default DutchAuctionClaimAction;
