import { CheckIcon, ExternalIcon } from '@f8n/icons';
import { fromUnixTime, isPast } from 'date-fns';
import { match } from 'ts-pattern';
import { TransactionReceipt } from 'viem';
import { useBlock, useTransactionReceipt } from 'wagmi';
import { z } from 'zod';

import TransactionModal from 'components/TransactionModal';
import Button from 'components/base/Button';
import Flex from 'components/base/Flex';
import InputEth from 'components/base/InputEth';
import Modal from 'components/base/Modal';
import Text from 'components/base/Text';
import Tooltip from 'components/base/Tooltip';
import ExternalLink from 'components/links/ExternalLink';
import { ClaimRebateFoundationDutchAuctionProvider } from 'flows/claim-rebate-dutch-auction/ClaimRebateFoundationDutchAuction';
import { ClaimRebateHighlightDutchAuctionProvider } from 'flows/claim-rebate-dutch-auction/ClaimRebateHighlightDutchAuction';
import { useClaimRebateDutchAuctionFlow } from 'flows/claim-rebate-dutch-auction/use-claim-rebate-dutch-auction-flow';

import useModalVisibility from 'hooks/use-modal-visibility';
import { formatRelativeDateTime } from 'utils/dates/dates';
import { getEtherscanUrl } from 'utils/etherscan';
import { isZero } from 'utils/numbers';
import { formatEther } from 'utils/units';

import { ModalOptions } from 'types/modal';

const MODAL_KEY = 'CLAIM_REBATE';

type ClaimRebateModalOptions = ModalOptions<typeof MODAL_KEY>;

export default function ClaimRebateModal() {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {match(modal.config)
            .with(null, () => null)
            .with({ market: 'FND' }, (config) => {
              return (
                <ClaimRebateFoundationDutchAuctionProvider {...config}>
                  <ClaimRebateModalWindow {...config} />
                </ClaimRebateFoundationDutchAuctionProvider>
              );
            })
            .with({ market: 'HIGHLIGHT' }, (config) => {
              return (
                <ClaimRebateHighlightDutchAuctionProvider {...config}>
                  <ClaimRebateModalWindow {...config} />
                </ClaimRebateHighlightDutchAuctionProvider>
              );
            })
            .exhaustive()}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

function ClaimRebateModalWindow(
  props: Pick<ClaimRebateModalOptions, 'endDate'>
) {
  const { endDate } = props;
  const context = useClaimRebateDutchAuctionFlow();
  const {
    write,
    isDisabled,
    isLoading,
    parsedError,
    isMintedOut,
    rebateBalance,
    txHash,
  } = context;

  const transactionReceipt = useTransactionReceipt({
    hash: txHash || undefined,
    query: {
      enabled: Boolean(txHash),
    },
  });

  const blockQuery = useBlock({
    blockNumber: transactionReceipt.data
      ? transactionReceipt.data.blockNumber
      : undefined,
    query: {
      enabled: Boolean(transactionReceipt.data),
    },
  });

  const getModalBody = () => {
    if (
      isZero(rebateBalance) &&
      transactionReceipt.isSuccess &&
      blockQuery.isSuccess &&
      txHash
    ) {
      const rebateAmount = getRebateAmountFromReceipt(transactionReceipt.data);

      return (
        <TransactionModal.InputWrapper>
          <InputEth id="price" disabled value={formatEther(rebateAmount)} />
          <Flex css={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Flex css={{ gap: '$1', alignItems: 'center' }}>
              <CheckIcon size={1} />
              <Text size={1}>
                <strong>Claimed</strong>{' '}
                <Text as="span" color="dim" css={{ marginLeft: '$1' }}>
                  {formatRelativeDateTime(
                    fromUnixTime(Number(blockQuery.data.timestamp))
                  )}
                </Text>
              </Text>
            </Flex>
            {/* TODO (base): chainId */}
            <ExternalLink
              css={{ display: 'flex', alignItems: 'center', gap: '$1' }}
              href={getEtherscanUrl.tx(txHash)}
              target="_blank"
              rel="noreferrer"
              variant="primary"
              size={1}
            >
              View on Etherscan <ExternalIcon size={0} />
            </ExternalLink>
          </Flex>
        </TransactionModal.InputWrapper>
      );
    } else {
      return (
        <TransactionModal.InputWrapper>
          <InputEth
            id="price"
            disabled
            value={formatEther(context.rebateBalance)}
          />
          <div style={{ height: 18.5 }} />
        </TransactionModal.InputWrapper>
      );
    }
  };

  const isAlreadyClaimed = isZero(rebateBalance) && txHash;

  const getFooter = () => {
    if (isAlreadyClaimed) {
      return (
        <Button disabled variant="primary">
          Already claimed
        </Button>
      );
    } else if ((endDate && isPast(endDate)) || isMintedOut) {
      return (
        <TransactionModal.TransactionButton
          chainId={context.chainId}
          write={write}
          isDisabled={isDisabled}
          isLoading={isLoading}
          error={parsedError}
          label="Claim"
        />
      );
    } else {
      return (
        <Tooltip content="Can claim rebate when sale is over">
          <div style={{ width: '100%' }}>
            <Button disabled variant="primary">
              Claim
            </Button>
          </div>
        </Tooltip>
      );
    }
  };

  return (
    <TransactionModal
      chainId={context.chainId}
      preSignPrompt={{
        type: 'skip',
      }}
    >
      <TransactionModal.Content
        action={context.action}
        chainId={context.chainId}
        txHash={txHash}
        footer={<Modal.Footer>{getFooter()}</Modal.Footer>}
      >
        <TransactionModal.Body>
          <Modal.BodyTitle
            align="left"
            title="Auction rebate"
            description={
              isAlreadyClaimed
                ? 'The auction has completed and you have been refunded a rebate to claim for the excess you paid.'
                : 'Any amount paid above the final price can be claimed as a rebate.'
            }
          />
          <TransactionModal.Body>{getModalBody()}</TransactionModal.Body>
        </TransactionModal.Body>
      </TransactionModal.Content>
    </TransactionModal>
  );
}
const logSchema = z.object({
  logs: z
    .array(
      z.object({
        data: z.string(),
        topics: z.array(z.string()).length(3),
      })
    )
    .nonempty(),
});

const getRebateAmountFromReceipt = (receipt: TransactionReceipt) => {
  const parsed = logSchema.parse(receipt);
  const data = parsed.logs[0].data;
  // Extract and convert first 32 bytes (64 characters + '0x' prefix)
  const rebate = BigInt('0x' + data.slice(2, 66));

  return rebate;
};
