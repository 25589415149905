import { colors } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import { motion } from 'framer-motion';
import { useMeasure } from 'react-use';

import Box from './base/Box';

type OdometerProps = {
  value: string;
};

export default function Odometer(props: OdometerProps) {
  return (
    <Box css={{ display: 'flex', color: '$black100' }}>
      {[...props.value]
        .filter((char) => PERMITTED_CHARACTERS.includes(char))
        .map((char, index) => (
          <OdometerCharacter key={index.toString()} character={char} />
        ))}
    </Box>
  );
}

type OdometerCharacterProps = {
  character: string;
};

function OdometerCharacter(props: OdometerCharacterProps) {
  const [measureRef, dimensions] = useMeasure<HTMLDivElement>();

  /**
   * return punctuation characters as-is
   */
  if (PUNCTUATION.includes(props.character)) {
    return <>{props.character}</>;
  }

  const currentIndex = PERMITTED_CHARACTERS.findIndex(
    (val) => val === props.character
  );

  if (currentIndex === -1) {
    return null;
  }

  return (
    <div>
      <OffscreenCharacter ref={measureRef}>
        {props.character}
      </OffscreenCharacter>
      <div
        style={{
          height: dimensions.height,
          overflow: 'hidden',
        }}
      >
        <motion.div
          animate={{
            y: -currentIndex * dimensions.height,
          }}
          transition={{
            type: 'spring',
            duration: 0.75,
            bounce: 0.15,
          }}
        >
          {NUMBERS.map((char, index) => {
            return (
              <div
                key={index}
                style={{
                  textAlign: 'center',
                  fontSize: 'inherit',
                }}
              >
                <motion.div
                  animate={{
                    color:
                      props.character === char
                        ? colors.black[100]
                        : colors.black[20],
                  }}
                >
                  {char}
                </motion.div>
              </div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
}

const OffscreenCharacter = styled('div', {
  textIndent: '-9999em',
  fontSize: 'inherit',
  position: 'absolute',
  visibility: 'hidden',
});

const PUNCTUATION = ['.', ','];

const NUMBERS = ['9', '8', '7', '6', '5', '4', '3', '2', '1', '0'];

const PERMITTED_CHARACTERS: string[] = [...NUMBERS, ...PUNCTUATION];
