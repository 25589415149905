import { FIXED_MINT_FEE_PER_NFT_IN_WEI } from '@f8n/f8n-contracts';

import { ethToNumber } from 'utils/bigint';

/**
 * This mint fee must be added to the value sent to mint NFTs from the Drop market
 */
export const FND_DROP_MARKET_MINT_FEE = BigInt(FIXED_MINT_FEE_PER_NFT_IN_WEI);

export const FND_DROP_MARKET_MINT_FEE_IN_ETHER = ethToNumber(
  FND_DROP_MARKET_MINT_FEE
);

/**
 * The mint fee reward is half of the mint fee (only when free)
 */
export const FREE_1155_MINT_FEE_REWARD = FND_DROP_MARKET_MINT_FEE / BigInt(2);

export const FREE_1155_MINT_FEE_REWARD_IN_ETHER = ethToNumber(
  FREE_1155_MINT_FEE_REWARD
);
