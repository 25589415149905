import { createContext, useContext } from 'react';

import { noop } from 'utils/helpers';

import { StepperIncrementControls } from 'types/stepper';

import { MintWidgetContextValue } from './types';

const mockStepperControl: StepperIncrementControls = {
  isDisabled: true,
  tooltipContent: null,
  onClick: noop,
};

export const MintWidgetContext = createContext<MintWidgetContextValue>({
  onAddToCal: noop,
  onCollectorsClick: noop,
  market: 'FND' as const,
  mint: { type: 'CONNECT' as const, prompt: noop },
  pricing: {
    mintPrice: BigInt(0),
    feePrice: BigInt(0),
    totalPrice: BigInt(0),
  },
  quantity: {
    count: 1,
    onCountChange: noop,
    decrement: mockStepperControl,
    increment: mockStepperControl,
  },
  onShopTheSecondary: noop,
});

export const useMintWidget = () => {
  return useContext(MintWidgetContext);
};
