import { match } from 'ts-pattern';

import Button from 'components/base/Button';
import Tooltip from 'components/base/Tooltip';
import { TRANSACTION_CTA } from 'copy/transactions';

import { isWeb3CtaLoading } from 'utils/web3-cta';

import { useMintWidget } from './MintWidgetContext';
import { MINT_WIDGET_CTA, MINT_WIDGET_LOADING_MESSAGE } from './copy';

type MintWidgetMintCtaProps = {
  size: 0 | 1;
};

export function MintWidgetMintCta(props: MintWidgetMintCtaProps) {
  const { mint } = useMintWidget();

  return match(mint)
    .with({ type: 'CONNECT' }, ({ prompt }) => {
      return <MintPrompt size={props.size} onClick={prompt} />;
    })
    .with({ type: 'SWITCH_CHAIN' }, ({ prompt, status }) => {
      return (
        <Button.Loader
          size={props.size}
          isLoading={status === 'pending'}
          disabled={false}
          loadingLabel={TRANSACTION_CTA.CONFIRM}
          onClick={prompt}
          width="full"
        >
          {MINT_WIDGET_CTA.MINT}
        </Button.Loader>
      );
    })
    .with({ type: 'BRIDGE' }, ({ prompt }) => {
      return <MintPrompt size={props.size} onClick={prompt} />;
    })
    .with({ type: 'WEB_3_TX' }, (tx) => {
      const { config } = tx;

      return (
        <Tooltip content={config.disabledReason}>
          <Button.Loader
            size={props.size}
            isLoading={isWeb3CtaLoading(tx)}
            disabled={Boolean(config.disabledReason)}
            loadingLabel={
              config.hasTxPrompt
                ? TRANSACTION_CTA.CONFIRM
                : MINT_WIDGET_LOADING_MESSAGE.MINTING
            }
            onClick={config.prompt}
            width="full"
          >
            {MINT_WIDGET_CTA.MINT}
          </Button.Loader>
        </Tooltip>
      );
    })
    .otherwise(() => null);
}

type MintPromptProps = MintWidgetMintCtaProps & {
  onClick: () => void;
};

function MintPrompt(props: MintPromptProps) {
  return (
    <Button
      variant="primary"
      size={props.size}
      onClick={props.onClick}
      width="full"
    >
      {MINT_WIDGET_CTA.MINT}
    </Button>
  );
}
