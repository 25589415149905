import { add } from 'date-fns';
import { CalendarOptions, GoogleCalendar } from 'datebook';

type ThirdPartyCalendarLinkOptions = {
  description: CalendarOptions['description'];
  title: CalendarOptions['title'];
  startDate: Date;
  endDate: Date;
};

export const getGoogleCalendarLink = (
  options: ThirdPartyCalendarLinkOptions
) => {
  const calendar = new GoogleCalendar({
    title: options.title,
    description: options.description,
    start: options.startDate,
    end: options.endDate,
  });

  return calendar.render();
};

export const getCalendarDescriptionAnchor = (options: {
  href: string;
  label: string;
}) => {
  return `<a href="${options.href}">${options.label}</a>`;
};

/**
 * Used when a sale has no defined end date, but we still want to add it to a users calendar.
 * In this case, we default to a 30 minute calendar event.
 */
export const deriveCalendarSaleEndDate = (options: {
  startDate: Date;
}): Date => {
  return add(options.startDate, { minutes: 30 });
};
