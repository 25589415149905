import { parseJSON } from 'date-fns';
import { P, match } from 'ts-pattern';

import { SaleSchedule } from 'components/SaleSchedule';
import Box from 'components/base/Box';
import Divider from 'components/base/Divider';
import Flex from 'components/base/Flex';
import { Checkout } from 'components/checkout-widget/Checkout';

import { deriveCalendarSaleEndDate } from 'utils/calendar';
import { isNumberType } from 'utils/helpers';

import { CollectionFilter } from 'types/Collection';
import { FixedPriceDropSale, PresaleEligibility } from 'types/DropSale';
import { CollectorsPreview } from 'types/collectors';

import { CollectorsSummary } from '../CollectorsSummary';

import { MintWidget } from './MintWidget';
import { useMintWidget } from './MintWidgetContext';
import { MintWidgetMintCta } from './MintWidgetMintCta';
import { MintWidgetProgress } from './MintWidgetProgress';
import { MintWidgetQuantityStepper } from './MintWidgetQuantityStepper';
import { MintWidgetSaleStats } from './MintWidgetSaleStats';
import { MintWidgetUnlimitedSupplyStatus } from './MintWidgetUnlimitedSupplyStatus';
import { PostSaleMintWidget } from './PostSaleMintWidget';

export function FixedPriceDropMintWidget(props: {
  collectors: CollectorsPreview;
  nftCount: number;
  sale: FixedPriceDropSale;
  totalSales: number;
  eligibilityStatus: PresaleEligibility;
  collection: CollectionFilter;
  isOwner: boolean;
  isRevealed: boolean;
}) {
  const { onAddToCal, onCollectorsClick, onShopTheSecondary } = useMintWidget();
  const {
    collectors,
    nftCount,
    sale,
    totalSales,
    eligibilityStatus,
    isOwner,
    collection,
    isRevealed,
  } = props;

  return match({ eligibilityStatus, sale })
    .with(
      {
        sale: {
          type: 'FND_FIXED_PRICE',
          status: 'SCHEDULED',
          presaleStartTime: P.string,
        },
      },
      ({ sale: scheduledPresale }) => {
        const { maxTokenId, mintPrice, presaleStartTime, startTime } =
          scheduledPresale;

        const startDate = parseJSON(startTime);

        return (
          <Checkout.Root>
            <MintWidgetSaleStats.Scheduled
              mintPrice={mintPrice}
              supply={maxTokenId}
            />
            <Divider />
            <Checkout.Stack css={{ gap: '$4' }}>
              <MintWidget.PresaleRow>
                <SaleSchedule.Presale
                  startDate={parseJSON(presaleStartTime)}
                  eligibilityStatus={eligibilityStatus}
                />
                <MintWidget.PresaleBadge
                  eligibilityStatus={eligibilityStatus}
                />
              </MintWidget.PresaleRow>
              <SaleSchedule.Public startDate={startDate} prefix="public-sale" />
              <Checkout.Stack css={{ gap: '$3' }}>
                {isOwner ? (
                  <FixedPriceDropOwnerActions
                    collection={collection}
                    isRevealed={isRevealed}
                  />
                ) : (
                  <MintWidget.AddToCal
                    onClick={() =>
                      onAddToCal({
                        startDate,
                        endDate: deriveCalendarSaleEndDate({ startDate }),
                      })
                    }
                  />
                )}
              </Checkout.Stack>
            </Checkout.Stack>
          </Checkout.Root>
        );
      }
    )
    .with(
      {
        sale: {
          status: 'SCHEDULED',
        },
      },
      ({ sale: scheduledSale }) => {
        const { maxTokenId, mintPrice, startTime } = scheduledSale;

        const startDate = parseJSON(startTime);

        return (
          <Checkout.Root>
            <MintWidgetSaleStats.Scheduled
              mintPrice={mintPrice}
              supply={isNumberType(maxTokenId) ? maxTokenId : Infinity}
            />
            <Divider />
            <Checkout.Stack css={{ gap: '$4' }}>
              <SaleSchedule.Public startDate={startDate} prefix="mint-opens" />
              <Checkout.Stack css={{ gap: '$3' }}>
                {isOwner ? (
                  <FixedPriceDropOwnerActions
                    collection={collection}
                    isRevealed={isRevealed}
                  />
                ) : (
                  <MintWidget.AddToCal
                    onClick={() =>
                      onAddToCal({
                        startDate,
                        endDate: deriveCalendarSaleEndDate({ startDate }),
                      })
                    }
                  />
                )}
              </Checkout.Stack>
            </Checkout.Stack>
          </Checkout.Root>
        );
      }
    )
    .with(
      {
        eligibilityStatus: P.union('ELIGIBLE', 'UNKNOWN'),
        sale: {
          status: 'OPEN_PRESALE',
          presaleStartTime: P.string,
        },
      },
      ({ sale: scheduledSale }) => {
        const { maxTokenId, presaleStartTime, startTime } = scheduledSale;

        return (
          <Checkout.Root>
            <Checkout.Stack css={{ gap: '$6' }}>
              <Checkout.Stack css={{ gap: '$3' }}>
                <Checkout.Stack>
                  <MintWidgetQuantityStepper />
                  <MintWidgetMintCta size={1} />
                </Checkout.Stack>

                {isOwner && (
                  <FixedPriceDropOwnerActions
                    collection={collection}
                    isRevealed={isRevealed}
                  />
                )}
              </Checkout.Stack>

              <MintWidgetProgress minted={nftCount} supply={maxTokenId} />
              <CollectorsSummary
                type="collector"
                collectors={collectors}
                onClick={onCollectorsClick}
                maxPreviews={2}
              />
              <Divider />
              <Checkout.Stack>
                <MintWidget.PresaleRow>
                  <SaleSchedule.Presale
                    startDate={parseJSON(presaleStartTime)}
                    eligibilityStatus={eligibilityStatus}
                  />
                  <MintWidget.PresaleBadge
                    eligibilityStatus={eligibilityStatus}
                  />
                </MintWidget.PresaleRow>
                <SaleSchedule.Public
                  startDate={parseJSON(startTime)}
                  prefix="public-sale"
                />
              </Checkout.Stack>
            </Checkout.Stack>
          </Checkout.Root>
        );
      }
    )
    .with(
      {
        sale: {
          status: 'OPEN_PRESALE',
          presaleStartTime: P.string,
        },
      },
      ({ sale: scheduledSale }) => {
        const { maxTokenId, mintPrice, presaleStartTime, startTime } =
          scheduledSale;

        const presaleStartDate = parseJSON(presaleStartTime);
        const startDate = parseJSON(startTime);

        const getActionButtons = () => {
          if (isOwner) {
            return (
              <Checkout.Stack css={{ gap: '$3' }}>
                <Checkout.Stack>
                  <MintWidgetQuantityStepper />
                  <MintWidgetMintCta size={1} />
                </Checkout.Stack>
                <FixedPriceDropOwnerActions
                  collection={collection}
                  isRevealed={isRevealed}
                />
              </Checkout.Stack>
            );
          }

          if (eligibilityStatus === 'ELIGIBLE') {
            return (
              <Checkout.Stack css={{ gap: '$3' }}>
                <Checkout.Stack>
                  <MintWidgetQuantityStepper />
                  <MintWidgetMintCta size={1} />
                </Checkout.Stack>
                <FixedPriceDropOwnerActions
                  collection={collection}
                  isRevealed={isRevealed}
                />
              </Checkout.Stack>
            );
          }

          return (
            <MintWidget.AddToCal
              onClick={() => {
                onAddToCal({
                  startDate,
                  endDate: deriveCalendarSaleEndDate({ startDate }),
                });
              }}
            />
          );
        };

        return (
          <Checkout.Root>
            <MintWidgetSaleStats.Scheduled
              mintPrice={mintPrice}
              supply={isNumberType(maxTokenId) ? maxTokenId : Infinity}
            />
            <Divider />
            <Checkout.Stack css={{ gap: '$4' }}>
              <MintWidget.PresaleRow>
                <SaleSchedule.Presale
                  startDate={presaleStartDate}
                  eligibilityStatus={eligibilityStatus}
                />
                <MintWidget.PresaleBadge
                  eligibilityStatus={eligibilityStatus}
                />
              </MintWidget.PresaleRow>
              <SaleSchedule.Public startDate={startDate} prefix="public-sale" />
              {getActionButtons()}
            </Checkout.Stack>
          </Checkout.Root>
        );
      }
    )
    .with(
      {
        sale: {
          status: P.union('OPEN', 'OPEN_PRESALE'),
          type: 'FND_FIXED_PRICE',
        },
      },
      ({ sale: openSale }) => {
        const { maxTokenId } = openSale;

        return (
          <Checkout.Root>
            <Checkout.Stack css={{ gap: '$3' }}>
              <Checkout.Stack>
                <MintWidgetQuantityStepper />
                <MintWidgetMintCta size={1} />
              </Checkout.Stack>
              {isOwner && (
                <FixedPriceDropOwnerActions
                  collection={collection}
                  isRevealed={isRevealed}
                />
              )}
            </Checkout.Stack>

            <MintWidgetProgress minted={nftCount} supply={maxTokenId} />
            <CollectorsSummary
              type="collector"
              collectors={collectors}
              onClick={onCollectorsClick}
              maxPreviews={2}
            />
          </Checkout.Root>
        );
      }
    )
    .with(
      {
        sale: {
          status: 'OPEN',
          type: 'HIGHLIGHT_FIXED_PRICE',
        },
      },
      ({ sale: openSale }) => {
        const { maxTokenId, endTime } = openSale;

        // TODO (l2-collections): progress bar to show number minted? How does it work with unlimited supply?
        return (
          <Checkout.Root>
            <Checkout.Stack css={{ gap: '$3' }}>
              <Checkout.Stack>
                <MintWidgetQuantityStepper />
                <MintWidgetMintCta size={1} />
              </Checkout.Stack>
              {isOwner && (
                <FixedPriceDropOwnerActions
                  collection={collection}
                  isRevealed={isRevealed}
                />
              )}
            </Checkout.Stack>
            {isNumberType(maxTokenId) ? (
              <MintWidgetProgress minted={nftCount} supply={maxTokenId} />
            ) : (
              <MintWidgetUnlimitedSupplyStatus
                minted={nftCount}
                endDate={endTime ? parseJSON(endTime) : null}
              />
            )}
            <CollectorsSummary
              type="collector"
              collectors={collectors}
              onClick={onCollectorsClick}
              maxPreviews={2}
            />
          </Checkout.Root>
        );
      }
    )
    .with(
      {
        sale: {
          status: P.union('ENDED', 'MINTED_OUT'),
        },
      },
      ({ sale }) => {
        return (
          <PostSaleMintWidget
            actions={
              isOwner ? (
                <FixedPriceDropOwnerActions
                  collection={collection}
                  isRevealed={isRevealed}
                />
              ) : (
                onShopTheSecondary && (
                  <MintWidget.BrowseSecondaryCta onClick={onShopTheSecondary} />
                )
              )
            }
            collectors={collectors}
            nftCount={nftCount}
            sale={sale}
            totalSales={totalSales}
          />
        );
      }
    )
    .exhaustive();
}

function FixedPriceDropOwnerActions(props: {
  collection: CollectionFilter;
  isRevealed: boolean;
}) {
  const { collection, isRevealed } = props;

  return (
    <Box
      css={{
        display: 'flex',
        gap: '$2',
      }}
    >
      {!isRevealed && (
        <Flex css={{ flex: 1 }}>
          <MintWidget.RevealCollection collection={collection} />
        </Flex>
      )}

      <MintWidget.EditCollection contractAddress={collection.contractAddress} />
    </Box>
  );
}
