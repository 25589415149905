import Box from 'components/base/Box';
import Progress from 'components/base/Progress';
import Text from 'components/base/Text';

import { formatNumber, getMintedSupplyPercent } from 'utils/formatters';

export function MintWidgetProgress(props: { minted: number; supply: number }) {
  const { minted, supply } = props;

  return (
    <Box css={{ display: 'flex', flexDirection: 'column', gap: '$2' }}>
      <div>
        <Text size={1} as="span" weight="medium">
          {formatNumber(minted)}
        </Text>
        <Text size={1} as="span" color="dim" weight="regular">
          &nbsp;/&nbsp;{formatNumber(supply)} minted
        </Text>
      </div>
      <Progress.Animated
        value={getMintedSupplyPercent({
          minted,
          supply,
        })}
      />
    </Box>
  );
}
