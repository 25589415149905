import {
  CalendarAddIcon,
  EditionsIcon,
  InfoIcon,
  SparklesIcon,
} from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';
import { Address } from 'viem';

import Badge from 'components/base/Badge';
import Flex from 'components/base/Flex';
import Text from 'components/base/Text';
import EditCollectionModal from 'components/modals/EditCollectionModal';

import { getPath } from 'utils/router';

import { CollectionFilter } from 'types/Collection';
import { PresaleEligibility } from 'types/DropSale';

import Button from '../base/Button';

import { MintWidgetContext } from './MintWidgetContext';

function MintWidgetAddToCal(props: { onClick: () => void }) {
  return (
    <Button onClick={props.onClick} variant="outline" icon>
      <CalendarAddIcon />
      <span>Add to cal</span>
    </Button>
  );
}

function MintWidgetBrowseSecondaryCta(props: { onClick: () => void }) {
  return (
    <Button onClick={props.onClick} variant="primary">
      Shop the secondary
    </Button>
  );
}

const MintWidgetPresaleBadge = styled(Badge, {
  borderRadius: '$1',
  paddingY: 2,
  paddingX: 6,
  fontWeight: '$medium',
  fontFamily: '$body',

  '& svg': {
    width: '$icon0',
    height: '$icon0',
  },

  defaultVariants: {
    isEligible: false,
  },
});
MintWidgetPresaleBadge.defaultProps = {
  size: 0,
  radius: 'sharp',
};

function MintWidgetPresaleEligibilityIndicatorBadge(props: {
  eligibilityStatus: PresaleEligibility;
}) {
  const { eligibilityStatus } = props;

  if (eligibilityStatus === 'UNKNOWN') {
    return null;
  }

  if (eligibilityStatus === 'NOT_ELIGIBLE') {
    return (
      <MintWidgetPresaleBadge variant="light">
        <span>Not eligible</span>
        <InfoIcon />
      </MintWidgetPresaleBadge>
    );
  }

  return (
    <MintWidgetPresaleBadge as="button" variant="green">
      <span>You’re eligible</span>
      <InfoIcon />
    </MintWidgetPresaleBadge>
  );
}

function MintWidgetEditCollectionCta(props: { contractAddress: Address }) {
  return (
    <Flex
      css={{
        flex: 1,
        [`& > ${Button}`]: {
          flex: 1,
        },
      }}
    >
      <EditCollectionModal
        contractAddress={props.contractAddress}
        collectionSaleType="FIXED_PRICE_DROP"
      />
    </Flex>
  );
}

function MintWidgetRevealCollectionCta(props: {
  collection: CollectionFilter;
}) {
  return (
    <NextLink href={getPath.drop.reveal(props.collection)} passHref>
      <Button as="a" size={1} icon css={{ flex: 1 }}>
        <SparklesIcon />
        <span>Reveal artwork</span>
      </Button>
    </NextLink>
  );
}

const MintWidgetPresaleRow = styled('div', {
  display: 'flex',
  gap: '$2',
  justifyContent: 'space-between',
});

function MintWidgetTokenBalance(props: { tokenBalance: number }) {
  return (
    <Flex css={{ gap: '$2', alignItems: 'center', color: '$black60' }}>
      <EditionsIcon size={0} />
      <Flex css={{ gap: '$1' }}>
        <Text size={0}>You own</Text>
        <Text weight="medium" size={0} color="strong">
          {props.tokenBalance}
        </Text>
      </Flex>
    </Flex>
  );
}

export const MintWidget = {
  Provider: MintWidgetContext.Provider,

  // Presale
  PresaleRow: MintWidgetPresaleRow,
  PresaleBadge: MintWidgetPresaleEligibilityIndicatorBadge,

  // CTAs
  AddToCal: MintWidgetAddToCal,
  BrowseSecondaryCta: MintWidgetBrowseSecondaryCta,
  EditCollection: MintWidgetEditCollectionCta,
  RevealCollection: MintWidgetRevealCollectionCta,

  // Token balance
  TokenBalance: MintWidgetTokenBalance,
};
