import { CheckIcon } from '@f8n/icons';

import DutchAuctionClaimAction from 'components/DutchAuctionClaimAction';
import Tooltip from 'components/base/Tooltip';

import { isZero } from 'utils/numbers';

type ClaimRebateActionProps = {
  rebateBalance: bigint;
  onClick(): void;
};

export function ClaimRebateAction(props: ClaimRebateActionProps) {
  const { onClick, rebateBalance } = props;

  const hasClaimed = isZero(rebateBalance);

  return (
    <DutchAuctionClaimAction.AnimateIn>
      <DutchAuctionClaimAction
        label="Rebate"
        buttonProps={{
          onClick,
          icon: hasClaimed,
        }}
      >
        {hasClaimed ? (
          <>
            <span>Claimed</span>
            <CheckIcon />
          </>
        ) : (
          <span>Claim</span>
        )}
      </DutchAuctionClaimAction>
    </DutchAuctionClaimAction.AnimateIn>
  );
}

type WithdrawEarningsActionProps = {
  onClick(): void;
  isEnabled: boolean;
};

export function WithdrawEarningsAction(props: WithdrawEarningsActionProps) {
  const { isEnabled, onClick } = props;

  return (
    <DutchAuctionClaimAction.AnimateIn>
      {isEnabled ? (
        <DutchAuctionClaimAction
          label="Earnings"
          buttonProps={{
            onClick,
          }}
        >
          Withdraw
        </DutchAuctionClaimAction>
      ) : (
        <Tooltip content="Funds are claimable once the dynamic period ends">
          <div>
            <DutchAuctionClaimAction
              label="Earnings"
              buttonProps={{
                onClick,
                disabled: true,
              }}
            >
              Withdraw
            </DutchAuctionClaimAction>
          </div>
        </Tooltip>
      )}
    </DutchAuctionClaimAction.AnimateIn>
  );
}
