export const DEGREES_IN_CIRCLE = 360;

/**
 * Angles can be measured in both degrees and radians.
 * @see https://en.wikipedia.org/wiki/Radian
 *
 * A full circle is 360 degrees, or 2*Pi radians.
 * A half circle is 180 degrees, or Pi radians.
 * Thus, "pi radians" equals "180 degrees".
 */
const PI_IN_RADIANS = 180;

/**
 * Converts an angle from the polar coordinate system to a Cartesian coordinate system.
 *
 * In the polar coordinate system, an angle of 0 degrees is typically considered to be directly up (along the positive y-axis), and an angle of 90 degrees is directly to the right (along the positive x-axis).
 *
 * In a Cartesian coordinate system (used my JS Math functions), an angle of 0 degrees is directly to the right (along the positive x-axis), and an angle of 90 degrees is directly down (along the negative y-axis).
 *
 * @see https://www.mathsisfun.com/polar-cartesian-coordinates.html
 *
 * @param angleInDegrees number from 0...360 where 0 is directly up, and 90 is directly to the right
 * @returns angle in degrees from 0...360 where 0 is directly to the right, and 90 is directly down
 */
const angleToCartesianDegrees = (angleInDegrees: number) => {
  return angleInDegrees - 90;
};

/**
 * @param angleInDegrees number from 0...360 where 0 is directly up, and 90 is directly to the right
 * @returns angle in radians from -0.5π...1.5π where -0.5π is directly up, and 0.5π is directly down
 *
 * @example degreesToRadians(90) // 0
 * @example degreesToRadians(270) // π
 */
export const degreesToRadians = (angleInDegrees: number) => {
  const cartesianDegrees = angleToCartesianDegrees(angleInDegrees);
  return (cartesianDegrees * Math.PI) / PI_IN_RADIANS;
};

/**
 * Given a center point, radius, and angle, returns the coordinates of a point on the circle
 *
 * @see https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle for original source
 */
const polarToCartesianCoordinates = (options: {
  /** center coordinate on x axis */
  centerX: number;
  /** center coordinate on y axis */
  centerY: number;
  /** circle radius */
  radius: number;
  /** number from 0...360 */
  angleInDegrees: number;
}): { x: number; y: number } => {
  const { centerX, centerY, radius, angleInDegrees } = options;

  const radians = degreesToRadians(angleInDegrees);

  return {
    x: centerX + radius * Math.cos(radians),
    y: centerY + radius * Math.sin(radians),
  };
};

/**
 * Creates an SVG path, around the bounds of a circle.
 *
 * @see https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle for original source
 */
export function createArcAroundCircle(options: {
  centerX: number;
  centerY: number;
  radius: number;
  startAngleInDegrees: number;
  endAngleInDegrees: number;
}): string {
  const { centerX, centerY, radius, startAngleInDegrees, endAngleInDegrees } =
    options;

  const start = polarToCartesianCoordinates({
    centerX,
    centerY,
    radius,
    angleInDegrees: startAngleInDegrees,
  });

  const end = polarToCartesianCoordinates({
    centerX,
    centerY,
    radius,
    angleInDegrees: endAngleInDegrees,
  });

  /**
   * @see https://www.w3.org/TR/SVG/paths.html#PathDataEllipticalArcCommands for breakdown of each command
   */
  return [
    'M', // Move to the start point
    start.x, // x coordinate of start point
    start.y, // y coordinate of start point
    'A', // Start drawing an elliptical arc curve
    radius, // radius on x axis
    radius, // radius on y axis
    0, // rotation
    endAngleInDegrees - startAngleInDegrees <= 180 ? 0 : 1, // large-arc-flag
    1, // draw arc in counter-clockwise direction (sweep-flag)
    end.x, // x coordinate of end point
    end.y, // y coordinate of end point
  ].join(' ');
}
