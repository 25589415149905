import { addMinutes, isFuture, parseJSON } from 'date-fns';
import type { Address } from 'viem';
import { useWriteContract, useSimulateContract } from 'wagmi';

import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useTransactionStore from 'state/stores/transactions';

import { useQueryEffects } from 'hooks/react-query';
import { useDutchAuctionSellerInfo } from 'hooks/web3/use-dutch-auction-seller-info';
import { useExhibitionIdForCollection } from 'hooks/web3/use-exhibition-id-for-collection';
import useHighlightDutchRebateState from 'hooks/web3/use-highlight-dutch-auction-rebate-state';
import useHighlightDutchAuctionState from 'hooks/web3/use-highlight-dutch-auction-state';
import { useRefetchOnBlock } from 'hooks/web3/use-watch-block';
import { HighlightDutchAuction } from 'lib/abis/HighlightDutchAuction';
import { getHighlightAddress } from 'lib/addresses';
import { ZERO_ADDRESS } from 'lib/constants';
import report from 'lib/report';
import { isHex } from 'utils/address';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';

import { ExtractModalOptions } from 'types/modal';

import { ClaimRebateDutchAuctionFlowContext } from './ClaimRebateDutchAuctionFlowContext';
import {
  CLAIM_REBATE_DUTCH_AUCTION_ACTION_NAME,
  CLAIM_REBATE_DUTCH_AUCTION_TRACKING_COPY,
} from './constants';
import { ClaimRebateDutchAuctionProviderProps } from './types';

type ClaimRebateHighlightDutchAuctionProviderProps =
  ClaimRebateDutchAuctionProviderProps &
    ExtractModalOptions<'CLAIM_REBATE', { market: 'HIGHLIGHT' }>;

export function ClaimRebateHighlightDutchAuctionProvider(
  props: ClaimRebateHighlightDutchAuctionProviderProps
) {
  const { chainId, children, contractAddress, sale } = props;
  const vectorId = sale.mintVectorId;
  const auth = useAuth();
  const txStore = useTransactionStore();
  const publicKey = hasPublicKey(auth) ? auth.publicKey : ('' as Address);

  const simulateClaimHighlightDutchAuctionRebate = useSimulateContract({
    abi: HighlightDutchAuction,
    functionName: 'rebateCollector',
    address: getHighlightAddress({
      chainId,
      contractName: 'dutchAuctionMechanic',
    }),
    chainId,
    query: {
      enabled: hasPublicKey(auth) && isHex(vectorId),
      retry: false,
    },
    args:
      hasPublicKey(auth) && isHex(vectorId) ? [vectorId, publicKey] : undefined,
  });

  useQueryEffects(simulateClaimHighlightDutchAuctionRebate, {
    onError(error) {
      report(extractPrepareContractWriteRevertReason({ error }));
    },
  });

  const sellerInfoQuery = useDutchAuctionSellerInfo({
    contractAddress,
    chainId,
  });

  const exhibitionIdForCollection = useExhibitionIdForCollection(
    {
      chainId,
      contractAddress,
      seller: sellerInfoQuery.data ? sellerInfoQuery.data.seller : ZERO_ADDRESS,
    },
    { enabled: Boolean(sellerInfoQuery.data) }
  );

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess(txHash) {
        txStore.startTracking({
          chainId,
          ui: 'toast',
          needsIndexing: false,
          action: {
            name: CLAIM_REBATE_DUTCH_AUCTION_ACTION_NAME,
            market: 'HIGHLIGHT',
            worldId: exhibitionIdForCollection.data
              ? Number(exhibitionIdForCollection.data.worldId)
              : null,
          },
          txHash,
          title: CLAIM_REBATE_DUTCH_AUCTION_TRACKING_COPY.title,
          description: CLAIM_REBATE_DUTCH_AUCTION_TRACKING_COPY.description,
        });
      },
    },
  });

  const liveDutchAuctionQuery = useHighlightDutchAuctionState(
    { chainId, sale },
    { enabled: Boolean(sale.highlightId) && sale.status == 'LIVE' }
  );

  useRefetchOnBlock(liveDutchAuctionQuery, {
    enabled: sale.endTime
      ? isFuture(addMinutes(parseJSON(sale.endTime), 5))
      : false,
  });

  const rebateState = useHighlightDutchRebateState(
    { chainId, userPublicKey: publicKey, vectorId: sale.mintVectorId },
    { enabled: Boolean(sale.highlightId) && hasPublicKey(auth) }
  );

  useRefetchOnBlock(rebateState, {
    enabled: Boolean(sale.highlightId) && hasPublicKey(auth),
  });

  const rebateBalance = rebateState.data?.rebate ?? BigInt(0);

  const isMintedOut = liveDutchAuctionQuery.data
    ? liveDutchAuctionQuery.data.isMintedOut
    : sale.status === 'MINTED_OUT';

  const parsedError = extractPrepareContractWriteRevertReason(
    simulateClaimHighlightDutchAuctionRebate
  );

  const txHash = contractWrite.data || null;
  return (
    <ClaimRebateDutchAuctionFlowContext.Provider
      value={{
        action: CLAIM_REBATE_DUTCH_AUCTION_ACTION_NAME,
        chainId,
        write: () => {
          if (simulateClaimHighlightDutchAuctionRebate.isSuccess) {
            contractWrite.writeContract(
              simulateClaimHighlightDutchAuctionRebate.data.request
            );
          }
        },
        isDisabled: !simulateClaimHighlightDutchAuctionRebate.isSuccess,
        isLoading: contractWrite.isPending || Boolean(contractWrite.data),
        parsedError,
        isMintedOut,
        rebateBalance,
        txHash,
      }}
    >
      {children}
    </ClaimRebateDutchAuctionFlowContext.Provider>
  );
}
