import { TrackingCopyMap } from 'state/stores/transactions';

export const MINT_FROM_DUTCH_AUCTION_ACTION_NAME = 'mint-from-dutch-auction';

export const MINT_FROM_DUTCH_AUCTION_TRACKING_COPY: TrackingCopyMap = {
  title: {
    PENDING: 'Minting',
    SUCCESS: 'Minted!',
  },
};
