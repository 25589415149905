import { useWriteContract, useSimulateContract } from 'wagmi';

import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useTransactionStore from 'state/stores/transactions';

import { useQueryEffects } from 'hooks/react-query';
import { HighlightDutchAuction } from 'lib/abis/HighlightDutchAuction';
import { getHighlightAddress } from 'lib/addresses';
import report from 'lib/report';
import { isHex } from 'utils/address';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';

import { ExtractModalOptions } from 'types/modal';

import { WithdrawDutchAuctionEarningsFlowContext } from './WithdrawDutchAuctionEarningsFlowContext';
import {
  WITHDRAW_DUTCH_AUCTION_ACTION_NAME,
  WITHDRAW_DUTCH_AUCTION_TRACKING_COPY,
} from './constants';
import { WithdrawDutchAuctionEarningsProviderProps } from './types';

type WithdrawHighlightDutchAuctionEarningsProviderProps =
  WithdrawDutchAuctionEarningsProviderProps &
    ExtractModalOptions<'CLAIM_DUTCH_AUCTION_REVENUE', { market: 'HIGHLIGHT' }>;

export function WithdrawHighlightDutchAuctionEarningsProvider(
  props: WithdrawHighlightDutchAuctionEarningsProviderProps
) {
  const { chainId, children, withdrawAmount, sale, worldId } = props;

  const auth = useAuth();
  const txStore = useTransactionStore();

  const simulateWithdrawHighlightDutchAuctionEarnings = useSimulateContract({
    abi: HighlightDutchAuction,
    functionName: 'withdrawDPPFunds',
    address: getHighlightAddress({
      chainId,
      contractName: 'dutchAuctionMechanic',
    }),
    query: {
      enabled: hasPublicKey(auth) && isHex(sale.mintVectorId),
      retry: false,
    },
    args: isHex(sale.mintVectorId) ? [sale.mintVectorId] : undefined,
  });

  useQueryEffects(simulateWithdrawHighlightDutchAuctionEarnings, {
    onError(error) {
      report(extractPrepareContractWriteRevertReason({ error }));
    },
  });

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess(txHash) {
        txStore.startTracking({
          chainId,
          ui: 'toast',
          needsIndexing: false,
          action: {
            name: WITHDRAW_DUTCH_AUCTION_ACTION_NAME,
            market: 'HIGHLIGHT',
            worldId,
          },
          txHash,
          title: WITHDRAW_DUTCH_AUCTION_TRACKING_COPY.title,
          description: WITHDRAW_DUTCH_AUCTION_TRACKING_COPY.description,
        });
      },
    },
  });

  const disabledReason = extractPrepareContractWriteRevertReason(
    simulateWithdrawHighlightDutchAuctionEarnings
  );

  const txHash = contractWrite.data || null;

  return (
    <WithdrawDutchAuctionEarningsFlowContext.Provider
      value={{
        action: WITHDRAW_DUTCH_AUCTION_ACTION_NAME,
        chainId,
        write: () => {
          if (simulateWithdrawHighlightDutchAuctionEarnings.isSuccess) {
            contractWrite.writeContract(
              simulateWithdrawHighlightDutchAuctionEarnings.data.request
            );
          }
        },
        isDisabled: !simulateWithdrawHighlightDutchAuctionEarnings.isSuccess,
        isLoading: contractWrite.isPending || Boolean(contractWrite.data),
        disabledReason,
        txHash,
        withdrawAmount,
        worldId: props.worldId,
        takeRateInBasisPoints: props.takeRateInBasisPoints,
      }}
    >
      {children}
    </WithdrawDutchAuctionEarningsFlowContext.Provider>
  );
}
