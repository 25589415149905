import { useEffect } from 'react';
import { Address } from 'viem';
import { useWriteContract, useSimulateContract } from 'wagmi';
import { z } from 'zod';

import { hasPublicKey, hasToken } from 'contexts/auth/helpers';
import { Auth } from 'contexts/auth/types';
import useAuth from 'contexts/auth/useAuth';
import useTransactionStore from 'state/stores/transactions';

import { useDropAllowlist } from 'gql/api/queries/drop-allowlist.generated';
import useModal from 'hooks/use-modal';
import { useExhibitionIdForCollection } from 'hooks/web3/use-exhibition-id-for-collection';
import { useGetFixedPriceSale } from 'hooks/web3/use-get-fixed-price-sale';
import useMarketBalances from 'hooks/web3/use-market-balances';
import { NFTDropMarket } from 'lib/abis/NFTDropMarket';
import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';
import { ZERO_ADDRESS } from 'lib/constants';
import { FND_DROP_MARKET_MINT_FEE } from 'lib/drop-market';
import { hexValueSchema } from 'schemas/shared';
import { formatETHWithSuffix } from 'utils/formatters';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';
import { pluralizeWord } from 'utils/strings';
import {
  calculateTransactionValue,
  getMintPriceStepperBreakdown,
} from 'utils/transactions';
import { parseEther } from 'utils/units';
import { createWeb3StepperControls } from 'utils/web3-cta';

import { FixedPriceDropSale } from 'types/DropSale';
import { StepperBreakdown } from 'types/MintFee';

import { MintFromFixedPriceContext } from './MintFromFixedPriceContext';
import { MintFromFixedPriceProviderMinimalProps } from './types';

type MintFromFoundationFixedPriceProviderProps =
  MintFromFixedPriceProviderMinimalProps & {
    price: number;
  } & (
      | {
          contractCategory: 'DROP';
          saleStatus: FixedPriceDropSale['status'];
        }
      | {
          contractCategory: 'EDITION';
        }
    );

export function MintFromFoundationFixedPriceProvider(
  props: MintFromFoundationFixedPriceProviderProps
) {
  const {
    chainId,
    contractAddress,
    contractCategory,
    mintQuantity,
    price,
    setMintQuantity,
  } = props;

  const auth = useAuth();

  const modal = useModal();

  // TODO (L2): make chain-aware
  // TODO (L2): add guard for base
  const dropAllowlistQuery = useDropAllowlist(
    { drop: { contractAddress, chainId } },
    {
      enabled:
        hasPublicKey(auth) &&
        props.contractCategory === 'DROP' &&
        props.saleStatus === 'OPEN_PRESALE',
      select(data) {
        return data.dropAllowlist;
      },
    }
  );

  useEffect(() => {
    if (hasToken(auth)) {
      dropAllowlistQuery.refetch();
    }
  }, [auth.state]);

  const parsedMerkleProofSchema = merkleProofSchema.safeParse(
    dropAllowlistQuery.data
  );
  const presaleMerkleProof = parsedMerkleProofSchema.success
    ? parsedMerkleProofSchema.data.merkleProof
    : [];

  const stepper = getMintPriceStepperBreakdown({
    count: mintQuantity,
    fee: FND_DROP_MARKET_MINT_FEE,
    price: parseEther(price.toString()),
  });

  const nextDecrement = stepper.nextDecrement.count;
  const nextIncrement = stepper.nextIncrement.count;

  const simulation = useSimulateMintFromFoundationFixedPrice({
    auth,
    chainId,
    contractAddress,
    presaleMerkleProof,
    step: stepper.current,
  });

  const nextDecrementSimulation = useSimulateMintFromFoundationFixedPrice({
    auth,
    chainId,
    contractAddress,
    presaleMerkleProof,
    step: stepper.nextDecrement,
  });

  const nextIncrementSimulation = useSimulateMintFromFoundationFixedPrice({
    auth,
    chainId,
    contractAddress,
    presaleMerkleProof,
    step: stepper.nextIncrement,
  });

  const getFixedPriceSaleQuery = useGetFixedPriceSale({
    contractAddress,
    chainId,
  });

  const exhibitionIdForCollection = useExhibitionIdForCollection(
    {
      chainId,
      contractAddress,
      seller: getFixedPriceSaleQuery.data
        ? getFixedPriceSaleQuery.data.seller
        : ZERO_ADDRESS,
    },
    { enabled: Boolean(getFixedPriceSaleQuery.data) }
  );

  const txStore = useTransactionStore();

  const actionName =
    contractCategory === 'DROP' ? 'mint-from-drop' : 'mint-from-edition';

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess: (txHash) => {
        const txPrice = stepper.current.pricing.totalPrice;
        const mintPriceFormatted = formatETHWithSuffix(price);
        const pluralizedWord = pluralizeWord('NFT', mintQuantity);

        txStore.startTracking({
          action: {
            name: actionName,
            quantity: mintQuantity,
            txPrice,
            worldId: exhibitionIdForCollection.data
              ? Number(exhibitionIdForCollection.data.worldId)
              : null,
          },
          chainId,
          txHash,
          ui: 'toast',
          title: {
            PENDING: `Minting ${mintQuantity} ${pluralizedWord}…`,
            SUCCESS: `Minted ${mintQuantity} ${pluralizedWord}`,
          },
          description: {
            SUCCESS: `Your NFTs were minted for a price of ${mintPriceFormatted}. They are now in your wallet and displayed on your profile.`,
          },
        });
      },
    },
  });

  const getDisabledReason = () => {
    /**
     * in the case of a drop with a presale we want to enable the submit
     * button and force the user to sign the message before they can mint
     */
    if (
      props.contractCategory === 'DROP' &&
      props.saleStatus === 'OPEN_PRESALE' &&
      !hasToken(auth)
    ) {
      return null;
    }
    return extractPrepareContractWriteRevertReason(simulation);
  };

  const disabledReason = getDisabledReason();

  return (
    <MintFromFixedPriceContext.Provider
      value={{
        action: actionName,
        chainId,
        config: {
          disabledReason,
          hasTxPrompt: contractWrite.isPending,
          reset: contractWrite.reset,
          simulation,
          txHash: contractWrite.data || null,

          prompt: () => {
            if (
              props.contractCategory === 'DROP' &&
              props.saleStatus === 'OPEN_PRESALE' &&
              !hasToken(auth)
            ) {
              modal.setModal({ type: 'AUTH_PRE_SIGN' });
              return;
            }

            if (simulation.isSuccess) {
              contractWrite.writeContract(simulation.data.request);
            }
          },
        },
        pricing: stepper.current.pricing,
        quantity: {
          count: mintQuantity,
          onCountChange: setMintQuantity,
          decrement: createWeb3StepperControls({
            count: nextDecrement,
            simulation: nextDecrementSimulation,
            onClick: () => setMintQuantity(nextDecrement),
          }),
          increment: createWeb3StepperControls({
            count: nextIncrement,
            simulation: nextIncrementSimulation,
            onClick: () => setMintQuantity(nextIncrement),
          }),
        },
      }}
    >
      {props.children}
    </MintFromFixedPriceContext.Provider>
  );
}

const merkleProofSchema = z.object({
  merkleProof: z.array(hexValueSchema),
});

const useSimulateMintFromFoundationFixedPrice = (options: {
  auth: Auth;
  chainId: ChainId;
  contractAddress: Address;
  presaleMerkleProof: Address[];
  step: StepperBreakdown;
}) => {
  const { auth, chainId, contractAddress, presaleMerkleProof, step } = options;
  const { count, pricing } = step;
  const { totalPrice } = pricing;

  const marketBalancesQuery = useMarketBalances({
    chainId,
  });

  const txValue = marketBalancesQuery.data
    ? calculateTransactionValue({
        transactionValue: totalPrice,
        marketplaceBalance: marketBalancesQuery.data.availableFethBalance,
      })
    : totalPrice;

  return useSimulateContract({
    scopeKey: contractAddress,
    abi: NFTDropMarket,
    functionName: 'mintFromFixedPriceSaleWithEarlyAccessAllowlistV2',
    address: getContractAddress({
      chainId,
      contractName: 'nftDropMarket',
    }),
    chainId,
    args: [
      // contract to mint from
      contractAddress,
      // number of mints
      BigInt(count),
      // transferTo (who receives the minted NFT)
      hasPublicKey(auth) ? auth.publicKey : ZERO_ADDRESS,
      // buyReferrer
      ZERO_ADDRESS,
      // presale proof
      presaleMerkleProof,
    ],
    query: {
      enabled: hasPublicKey(auth),
      retry: false,
    },
    value: txValue,
  });
};
