import { useReadContract } from 'wagmi';

import { HighlightDutchAuction } from 'lib/abis/HighlightDutchAuction';
import { getHighlightAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';
import { isHex } from 'utils/address';

import { HighlightStaggeredDutchAuctionSale } from 'types/DropSale';

type UseHighlightDutchAuctionStateVariables = {
  chainId: ChainId;
  sale: HighlightStaggeredDutchAuctionSale;
};

type UseHighlightDutchAuctionStateOptions = {
  enabled: boolean;
};

// TODO (highlight): rename to useHighlightDutchWithdrawEarningsState
export default function useHighlightDutchAuctionState(
  variables: UseHighlightDutchAuctionStateVariables,
  options: UseHighlightDutchAuctionStateOptions
) {
  return useReadContract({
    abi: HighlightDutchAuction,
    functionName: 'getVectorState',
    address: getHighlightAddress({
      chainId: variables.chainId,
      contractName: 'dutchAuctionMechanic',
    }),
    chainId: variables.chainId,
    args: isHex(variables.sale.mintVectorId)
      ? [variables.sale.mintVectorId]
      : undefined,
    query: {
      enabled: options.enabled,
      select: (data) => {
        const mappedFields = {
          currentPrice: data[2],
          payeePotentialEscrowedFunds: data[3],
          collectionSupply: data[4],
          escrowedFundsAmountFinalized: data[6],
        };

        // TODO (wagmi-v2): use parseOutpt helper
        return {
          currentPrice: mappedFields.currentPrice,
          isRevenueReadyForWithdrawal:
            mappedFields.escrowedFundsAmountFinalized,
          creatorRevenueHasBeenWithdrawn:
            Number(mappedFields.payeePotentialEscrowedFunds) === 0 &&
            mappedFields.escrowedFundsAmountFinalized,
          totalPayoutFunds: mappedFields.payeePotentialEscrowedFunds,
          totalMinted: Number(mappedFields.collectionSupply),
          isMintedOut:
            Number(mappedFields.collectionSupply) >= variables.sale.maxTokenId,
        };
      },
    },
  });
}
