import { MinusIcon, PlusIcon } from '@f8n/icons';
import { darkMode, styled } from '@f8n-frontend/stitches';
import { VariantProps } from '@stitches/react';
import React, { useState } from 'react';

import Box from 'components/base/Box';
import Button from 'components/base/Button';
import InputField from 'components/base/InputField';
import { Input } from 'components/base/InputV3Base';
import Text from 'components/base/Text';
import Tooltip from 'components/base/Tooltip';

import { StepperIncrementControls, StepperValidation } from 'types/stepper';

type StepperBaseProps = {
  size: VariantProps<typeof StepperContainer>['size'];
  count: number;
};

type StepperInputProps =
  | {
      isDisabled: true;
    }
  | {
      isDisabled: false;
      onCountChange: (count: number) => void;
    };

type StepperControlsProps =
  | {
      isDisabled: true;
    }
  | {
      isDisabled: false;
      decrement: StepperIncrementControls;
      increment: StepperIncrementControls;
    };

export type StepperProps = StepperInputProps &
  StepperControlsProps &
  StepperValidation &
  StepperBaseProps;

export default function Stepper(props: StepperProps) {
  if (props.isDisabled) {
    return <DisabledStepper count={props.count} size={props.size} />;
  }

  return (
    <StepperScaffolding
      min={props.min}
      max={props.max}
      count={props.count}
      onCountChange={props.onCountChange}
      size={props.size}
      isDisabled={false}
    >
      <MinusButton {...props.decrement} />
      <PlusButton {...props.increment} />
    </StepperScaffolding>
  );
}

function DisabledStepper(props: StepperBaseProps) {
  return (
    <StepperScaffolding
      isDisabled
      count={props.count}
      min={props.count}
      max={props.count}
      size={props.size}
    >
      <MinusButton isDisabled />
      <PlusButton isDisabled />
    </StepperScaffolding>
  );
}

Stepper.Disabled = DisabledStepper;

function StepperScaffolding(
  props: StepperInputProps &
    StepperValidation &
    StepperBaseProps & {
      /** Allows exactly two children (for the two buttons) */
      children: [React.ReactNode, React.ReactNode];
    }
) {
  const { count, min, max, size } = props;
  const [isFocussed, setIsFocussed] = useState(false);

  return (
    <StepperContainer
      isDisabled={props.isDisabled}
      isFocussed={isFocussed}
      size={size}
    >
      {props.children[0]}
      <Box
        css={{
          flex: 1,
          input: {
            paddingX: 0,
            textAlign: 'center',
            backgroundColor: 'transparent !important',
            transition: '$2 $ease fontSize',
            color: '$black100',

            // Handling very large numbers
            padding: '0px!important',
            fontSize: props.count >= 100_000 ? '$1!important' : undefined,
          },
        }}
      >
        {props.isDisabled ? (
          <InputField
            disabled
            id="stepper-input"
            type="number"
            value={count}
            variant="base"
            size={size}
          />
        ) : (
          <InputField
            disabled={props.isDisabled}
            id="stepper-input"
            onBlur={() => {
              setIsFocussed(false);
            }}
            onChange={(event) => {
              const quantity = event.target.valueAsNumber;

              if (isNaN(quantity)) {
                return props.onCountChange(0);
              }

              if (quantity <= min) {
                return props.onCountChange(min);
              }

              if (quantity >= max) {
                return props.onCountChange(max);
              }

              props.onCountChange(Math.ceil(quantity));
            }}
            onFocus={() => {
              setIsFocussed(true);
            }}
            min={min}
            max={max}
            step={1}
            type="number"
            value={count === 0 ? '' : count}
            variant="base"
            size={size}
          />
        )}
      </Box>
      {props.children[1]}
    </StepperContainer>
  );
}

type ChangeQuantityButtonProps = {
  children: React.ReactNode;
  isDisabled: StepperIncrementControls['isDisabled'];
  onClick?: StepperIncrementControls['onClick'];
  tooltipContent?: StepperIncrementControls['tooltipContent'];
};

function ChangeQuantityButton(props: ChangeQuantityButtonProps) {
  return (
    <Tooltip content={props.tooltipContent}>
      <StepperIconButton
        onClick={props.onClick ? props.onClick : undefined}
        disabled={props.isDisabled}
      >
        {props.children}
      </StepperIconButton>
    </Tooltip>
  );
}

type ChangeQuantityVariantButtonProps = Omit<
  ChangeQuantityButtonProps,
  'children'
>;

function PlusButton(props: ChangeQuantityVariantButtonProps) {
  return (
    <ChangeQuantityButton {...props}>
      <PlusIcon />
    </ChangeQuantityButton>
  );
}

function MinusButton(props: ChangeQuantityVariantButtonProps) {
  return (
    <ChangeQuantityButton {...props}>
      <MinusIcon />
    </ChangeQuantityButton>
  );
}

const StepperIconButton = styled(Button, {
  borderRadius: '$2 !important',
  svg: {
    width: '$icon1 !important',
    height: '$icon1 !important',
  },
});
StepperIconButton.defaultProps = {
  icon: 'standalone',
  type: 'button',
  variant: 'ghost',
};

const StepperHeading = styled(Text, {
  textAlign: 'center',
  cursor: 'default',
});
StepperHeading.defaultProps = {
  size: 2,
  weight: 'medium',
};

const StepperContainer = styled('div', {
  padding: '$1',
  borderRadius: '$3',
  border: '1px solid',
  justifyContent: 'space-between',
  transition:
    'background-color $1 $ease, border $1 $ease, box-shadow $1 $ease, color $1 $ease, outline $1 $ease',

  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',

  variants: {
    isDisabled: {
      true: {
        [`& ${StepperHeading}`]: {
          color: '$black40',
        },
      },
      false: {},
    },
    isFocussed: {
      true: {
        borderColor: '$black100',
        outline: '4px solid $black30',
      },
      false: {
        borderColor: '$black10',
        [darkMode]: {
          borderColor: '$black20',
        },
      },
    },
    size: {
      0: {
        width: 120,
        height: 38,

        [`& ${Input}`]: {
          height: 'auto !important', // Override the size 0 height on `Input`
        },

        [`& ${StepperIconButton}`]: {
          width: 28,
          height: 28,
        },
      },
      1: {
        width: 152,
        height: 50,

        [`& ${Input}`]: {
          height: 'auto !important', // Override the size 1 height on `Input`
        },

        [`& ${StepperIconButton}`]: {
          width: 40,
          height: 40,
        },
      },
    },
  },
  compoundVariants: [
    {
      isDisabled: false,
      isFocussed: true,
      css: {
        '@hover': {
          '&:hover': {
            borderColor: '$black100',
          },
        },
      },
    },
    {
      isDisabled: false,
      isFocussed: false,
      css: {
        '@hover': {
          '&:hover': {
            borderColor: '$black20',
          },
        },
      },
    },
  ],
});
