import { useWriteContract, useSimulateContract } from 'wagmi';

import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useTransactionStore from 'state/stores/transactions';

import { useQueryEffects } from 'hooks/react-query';
import { useDutchAuctionSellerInfo } from 'hooks/web3/use-dutch-auction-seller-info';
import { useExhibitionIdForCollection } from 'hooks/web3/use-exhibition-id-for-collection';
import { useRefetchOnBlock } from 'hooks/web3/use-watch-block';
import { NFTDropMarket } from 'lib/abis/NFTDropMarket';
import { getContractAddress } from 'lib/addresses';
import { ZERO_ADDRESS } from 'lib/constants';
import report from 'lib/report';
import { getAddress } from 'utils/address';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';

import { ExtractModalOptions } from 'types/modal';

import { WithdrawDutchAuctionEarningsFlowContext } from './WithdrawDutchAuctionEarningsFlowContext';
import {
  WITHDRAW_DUTCH_AUCTION_ACTION_NAME,
  WITHDRAW_DUTCH_AUCTION_TRACKING_COPY,
} from './constants';
import { WithdrawDutchAuctionEarningsProviderProps } from './types';

type WithdrawFoundationDutchAuctionEarningsProviderProps =
  WithdrawDutchAuctionEarningsProviderProps &
    ExtractModalOptions<'CLAIM_DUTCH_AUCTION_REVENUE', { market: 'FND' }>;

export function WithdrawFoundationDutchAuctionEarningsProvider(
  props: WithdrawFoundationDutchAuctionEarningsProviderProps
) {
  const { children, contractAddress, chainId } = props;

  const auth = useAuth();
  const txStore = useTransactionStore();

  const sellerInfoQuery = useDutchAuctionSellerInfo(
    { contractAddress: getAddress(contractAddress), chainId },
    { enabled: hasPublicKey(auth) }
  );

  useRefetchOnBlock(sellerInfoQuery, {
    enabled: hasPublicKey(auth),
  });

  const simulateWithdrawFndDutchAuctionEarnings = useSimulateContract({
    abi: NFTDropMarket,
    functionName: 'withdrawCreatorRevenueFromDutchAuction',
    address: getContractAddress({
      chainId,
      contractName: 'nftDropMarket',
    }),
    chainId,
    args: [getAddress(contractAddress)],
    query: {
      enabled: hasPublicKey(auth),
      retry: false,
    },
  });

  useQueryEffects(simulateWithdrawFndDutchAuctionEarnings, {
    onError(error) {
      report(extractPrepareContractWriteRevertReason({ error }));
    },
  });

  const exhibitionIdForCollection = useExhibitionIdForCollection(
    {
      chainId,
      contractAddress,
      seller: sellerInfoQuery.data ? sellerInfoQuery.data.seller : ZERO_ADDRESS,
    },
    { enabled: Boolean(sellerInfoQuery.data) }
  );

  const worldId = exhibitionIdForCollection.data
    ? Number(exhibitionIdForCollection.data.worldId)
    : null;

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess(txHash) {
        txStore.startTracking({
          chainId,
          ui: 'toast',
          needsIndexing: true,
          action: {
            name: WITHDRAW_DUTCH_AUCTION_ACTION_NAME,
            market: 'FND',
            worldId,
          },
          txHash,
          title: WITHDRAW_DUTCH_AUCTION_TRACKING_COPY.title,
          description: WITHDRAW_DUTCH_AUCTION_TRACKING_COPY.description,
        });
      },
    },
  });

  const disabledReason = extractPrepareContractWriteRevertReason(
    simulateWithdrawFndDutchAuctionEarnings
  );

  const withdrawAmount = sellerInfoQuery.data
    ? sellerInfoQuery.data.totalFundsPendingDistribution
    : null;

  const txHash = contractWrite.data || null;

  return (
    <WithdrawDutchAuctionEarningsFlowContext.Provider
      value={{
        action: WITHDRAW_DUTCH_AUCTION_ACTION_NAME,
        chainId,
        write: () => {
          if (simulateWithdrawFndDutchAuctionEarnings.isSuccess) {
            contractWrite.writeContract(
              simulateWithdrawFndDutchAuctionEarnings.data.request
            );
          }
        },
        isDisabled: !simulateWithdrawFndDutchAuctionEarnings.isSuccess,
        isLoading: contractWrite.isPending || Boolean(contractWrite.data),
        disabledReason,
        txHash,
        withdrawAmount,
        worldId,
        takeRateInBasisPoints: exhibitionIdForCollection.data
          ? exhibitionIdForCollection.data.takeRateInBasisPoints
          : null,
      }}
    >
      {children}
    </WithdrawDutchAuctionEarningsFlowContext.Provider>
  );
}
