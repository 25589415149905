import { getAssociationByCollection } from '@f8n/f8n-contracts/dist/abis/WorldsHelpers.generated';
import { useReadContract } from 'wagmi';

import { Worlds } from 'lib/abis/Worlds';
import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';
import { getAddress } from 'utils/address';

type Options = {
  enabled?: boolean;
};

export function useExhibitionIdForCollection(
  variables: {
    chainId: ChainId;
    contractAddress: string;
    seller: string;
  },
  options: Options = {}
) {
  return useReadContract({
    abi: Worlds,
    address: getContractAddress({
      chainId: variables.chainId,
      contractName: 'worlds',
    }),
    chainId: variables.chainId,
    functionName: 'getAssociationByCollection',
    args: [getAddress(variables.contractAddress), getAddress(variables.seller)],
    query: {
      enabled: options.enabled ?? true,
      select: getAssociationByCollection.parseOutput,
    },
  });
}
