import { Address } from 'viem';
import { useReadContract } from 'wagmi';

import { HighlightDutchAuction } from 'lib/abis/HighlightDutchAuction';
import { getHighlightAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';
import { isHex } from 'utils/address';

import { HighlightStaggeredDutchAuctionSale } from 'types/DropSale';

type UseHighlightDutchRebateStateVariables = {
  chainId: ChainId;
  vectorId: HighlightStaggeredDutchAuctionSale['mintVectorId'];
  userPublicKey: Address;
};

type UseHighlightDutchRebateStateOptions = {
  enabled: boolean;
};

export default function useHighlightDutchRebateState(
  variables: UseHighlightDutchRebateStateVariables,
  options: UseHighlightDutchRebateStateOptions
) {
  return useReadContract({
    abi: HighlightDutchAuction,
    functionName: 'getUserInfo',
    address: getHighlightAddress({
      chainId: variables.chainId,
      contractName: 'dutchAuctionMechanic',
    }),
    chainId: variables.chainId,
    args: isHex(variables.vectorId)
      ? [variables.vectorId, variables.userPublicKey]
      : undefined,
    query: {
      enabled: options.enabled,
      select: (data) => {
        // TODO (wagmi-v2) — use parse outputs helper
        const rebate = data[0];

        return {
          rebate,
        };
      },
    },
  });
}
