import { SaleStat } from 'components/SaleStat';
import { Checkout } from 'components/checkout-widget/Checkout';

import useCountdown from 'hooks/use-countdown';
import { isNumberType } from 'utils/helpers';

import { Sale } from 'types/Sale';

import { useMintWidget } from './MintWidgetContext';

function MintWidgetPriceStat(props: { mintPrice: number; feePrice?: number }) {
  const { mintPrice, feePrice } = props;
  const { market } = useMintWidget();

  return (
    <SaleStat.Root>
      <SaleStat.EthPrice amount={mintPrice} />
      {isNumberType(feePrice) && (
        <SaleStat.MintFee amount={feePrice} market={market} />
      )}
    </SaleStat.Root>
  );
}

function MintWidgetMintedStatSmall(props: { nftCount: number; sale: Sale }) {
  return (
    <SaleStat.Root>
      <SaleStat.MintedCount sale={props.sale} nftCount={props.nftCount} />
      <SaleStat.Label css={{ fontSize: '$0' }}>Minted</SaleStat.Label>
    </SaleStat.Root>
  );
}

function MintWidgetScheduledStats(props: {
  mintPrice: number;
  supply: number;
}) {
  return (
    <Checkout.StatRowContainer>
      <SaleStat.Root>
        <SaleStat.Label>Price</SaleStat.Label>
        <SaleStat.EthPrice amount={props.mintPrice} />
      </SaleStat.Root>
      <SaleStat.Root>
        <SaleStat.Label>Supply</SaleStat.Label>
        <SaleStat.Supply supply={props.supply} />
      </SaleStat.Root>
    </Checkout.StatRowContainer>
  );
}

function MintWidgetEndedStats(props: {
  nftCount: number;
  sale: Sale;
  totalSales: number;
}) {
  return (
    <Checkout.StatRowContainer>
      <SaleStat.Root>
        <SaleStat.Label>Total sales</SaleStat.Label>
        <SaleStat.TotalEth amount={props.totalSales} />
      </SaleStat.Root>
      <SaleStat.Root>
        <SaleStat.Label>Minted</SaleStat.Label>
        <SaleStat.MintedStatus sale={props.sale} nftCount={props.nftCount} />
      </SaleStat.Root>
    </Checkout.StatRowContainer>
  );
}

function MintWidgetLiveStats(props: { minted: number; endsAtUnix: number }) {
  const countdown = useCountdown(props.endsAtUnix);
  return (
    <Checkout.StatRowContainer>
      <SaleStat.Root>
        <SaleStat.Label>Minted</SaleStat.Label>
        <SaleStat.Supply supply={props.minted} />
      </SaleStat.Root>
      <SaleStat.Root>
        <SaleStat.Label>Ends in</SaleStat.Label>
        <SaleStat.Countdown countdown={countdown} />
      </SaleStat.Root>
    </Checkout.StatRowContainer>
  );
}

function MintWidgetRevenueStats(props: {
  nftCount: number;
  sale: Sale;
  totalSales: number;
}) {
  return (
    <Checkout.StatRowContainer>
      <SaleStat.Root>
        <SaleStat.Label>Items</SaleStat.Label>
        <SaleStat.MintedCount sale={props.sale} nftCount={props.nftCount} />
      </SaleStat.Root>
      <SaleStat.Root>
        <SaleStat.Label>Creator earnings</SaleStat.Label>
        <SaleStat.TotalEth amount={props.totalSales} />
      </SaleStat.Root>
    </Checkout.StatRowContainer>
  );
}

export const MintWidgetSaleStats = {
  // Singular stats
  Price: MintWidgetPriceStat,
  MintedSmall: MintWidgetMintedStatSmall,

  // Rows with multiple stats
  Ended: MintWidgetEndedStats,
  Scheduled: MintWidgetScheduledStats,
  Live: MintWidgetLiveStats,
  RevenueStats: MintWidgetRevenueStats,
};
