import { TrackingCopyMap } from 'state/stores/transactions';

export const WITHDRAW_DUTCH_AUCTION_ACTION_NAME =
  'withdraw-creator-revenue-from-dutch-auction';

export const WITHDRAW_DUTCH_AUCTION_TRACKING_COPY: TrackingCopyMap = {
  title: {
    PENDING: 'Withdrawing earnings…',
    SUCCESS: 'Earnings withdrawn',
  },
  description: {
    PENDING: 'Your funds are being withdrawn. You can close this if you like.',
    SUCCESS:
      'Your funds are now in your wallet. Any remaining earnings will be automatically sent to your wallet without needing to withdraw.',
  },
};
