import { Dot } from 'components/Dot';
import Pulse from 'components/Pulse';
import Box from 'components/base/Box';
import Text from 'components/base/Text';
import { Checkout } from 'components/checkout-widget/Checkout';

import { formatMintRatio } from 'utils/formatters';

export function MintWidgetUnlimitedSupplyStatus(props: {
  minted: number;
  endDate: Date | null;
}) {
  const { endDate, minted } = props;

  return (
    <Checkout.StatusRowContainer>
      <Box>
        <Pulse color="$live" />
      </Box>
      <Box css={{ display: 'flex', alignItems: 'center', gap: '$2' }}>
        <Text size={1} weight="medium">
          {formatMintRatio({
            minted,
            // Intentionally null, because this component is only used for unlimited supply
            supply: null,
          })}
        </Text>
        {endDate && (
          <>
            <Dot />
            <Checkout.SaleEndCountdown endDate={endDate} />
          </>
        )}
      </Box>
    </Checkout.StatusRowContainer>
  );
}
