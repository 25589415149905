import { ShareModalWindow } from 'components/ShareModalWindow';
import Modal from 'components/base/Modal';
import useAnalytics from 'contexts/analytics/useAnalytics';

import useModalVisibility from 'hooks/use-modal-visibility';
import { getCollectionFilter } from 'utils/inputs';

import { ModalOptions } from 'types/modal';

const MODAL_KEY = 'SHARE_MINT';
type ShareMintModalOptions = ModalOptions<typeof MODAL_KEY>;

export default function ShareMintModal() {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {modal.config && <ShareMintModalWindow {...modal.config} />}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

function ShareMintModalWindow(props: ShareMintModalOptions) {
  const analytics = useAnalytics();
  const collectionFilter = getCollectionFilter(props.collection);

  return (
    <ShareModalWindow
      type="collection"
      creator={props.creator}
      onCopyUrl={() => {
        analytics.track({
          name: 'share_url_clicked',
          type: 'collection',
          category: props.category,
          collection: collectionFilter,
        });
      }}
      onShareTwitterClicked={() => {
        analytics.track({
          name: 'share_twitter_clicked',
          type: 'collection',
          category: props.category,
          collection: collectionFilter,
        });
      }}
    />
  );
}
