import { styled } from '@f8n-frontend/stitches';

import Odometer from 'components/Odometer';
import Flex from 'components/base/Flex';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';

import { GizmoLinearCircle } from './GizmoLinearCircle';
import { GizmoStaggeredCircle } from './GizmoStaggeredCircle';
import { GizmoStat } from './GizmoStat';

function GizmoSkeleton() {
  return (
    <GizmoRoot css={{ color: '$black5-solid' }}>
      <GizmoLinearCircle isMintedOut={false} percentCompleted={0} />
      <GizmoContent
        css={{
          gap: '$7',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Skeleton.Block css={{ height: 26, width: 54 }} />
        <Skeleton.Block css={{ height: 76, width: '70%' }} />
        <Flex
          css={{
            gap: '$6',
            paddingTop: 9,
          }}
        >
          <Skeleton.Block css={{ height: 40, width: 80 }} />
          <Skeleton.Block css={{ height: 40, width: 80 }} />
        </Flex>
      </GizmoContent>
    </GizmoRoot>
  );
}

export const GizmoRoot = styled('div', {
  color: 'currentColor',
  position: 'relative',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$7',
  maxWidth: '415px',
});

export const GizmoContent = styled(Flex, {
  color: 'currentColor',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  gap: '$6',
});

const priceStageCopyMap = {
  final: 'Final price',
  current: 'Current price',
  starting: 'Starting price',
};

export type PriceStage = keyof typeof priceStageCopyMap;

function GizmoPrice(props: { price: number; stage: PriceStage }) {
  const { price, stage } = props;

  return (
    <PriceContainer>
      <Text color="dim" lineHeight={1} size={1} weight="medium">
        {priceStageCopyMap[stage]}
      </Text>
      <Flex css={{ alignItems: 'baseline', gap: '$1' }}>
        <Text
          css={{ letterSpacing: '$-2' }}
          lineHeight={1}
          size={6}
          weight="medium"
        >
          <Odometer value={price.toFixed(4)} />
        </Text>
        <Text
          color="strong"
          css={{ letterSpacing: '$-1' }}
          size={4}
          weight="medium"
        >
          ETH
        </Text>
      </Flex>
    </PriceContainer>
  );
}

const PriceContainer = styled(Flex, {
  gap: '$1',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const Gizmo = {
  Root: GizmoRoot,
  Content: GizmoContent,
  LinearCircle: GizmoLinearCircle,
  StaggeredCircle: GizmoStaggeredCircle,
  Price: GizmoPrice,
  Skeleton: GizmoSkeleton,
  Stat: GizmoStat,
};

export default Gizmo;
