import { parseJSON } from 'date-fns';
import { match } from 'ts-pattern';

import { SaleSchedule } from 'components/SaleSchedule';
import Divider from 'components/base/Divider';
import { Checkout } from 'components/checkout-widget/Checkout';

import { deriveCalendarSaleEndDate } from 'utils/calendar';
import { isNumberType } from 'utils/helpers';

import { LimitedEditionCollectionSale } from 'types/EditionSale';
import { CollectorsPreview } from 'types/collectors';

import { CollectorsSummary } from '../CollectorsSummary';

import { MintWidget } from './MintWidget';
import { useMintWidget } from './MintWidgetContext';
import { MintWidgetMintCta } from './MintWidgetMintCta';
import { MintWidgetProgress } from './MintWidgetProgress';
import { MintWidgetQuantityStepper } from './MintWidgetQuantityStepper';
import { MintWidgetSaleStats } from './MintWidgetSaleStats';
import { PostSaleMintWidget } from './PostSaleMintWidget';

export function LimitedEditionMintWidget(props: {
  collectors: CollectorsPreview;
  nftCount: number;
  sale: LimitedEditionCollectionSale;
  totalSales: number;
}) {
  const context = useMintWidget();
  const { collectors, nftCount, sale, totalSales } = props;

  return match({ sale })
    .with({ sale: { status: 'SCHEDULED' } }, ({ sale: scheduledSale }) => {
      const { maxTokenId, mintPrice, startTime } = scheduledSale;

      const startDate = parseJSON(startTime);

      return (
        <Checkout.Root>
          <MintWidgetSaleStats.Scheduled
            mintPrice={mintPrice}
            supply={isNumberType(maxTokenId) ? maxTokenId : Infinity}
          />
          <Divider />
          <Checkout.Stack css={{ gap: '$4' }}>
            <SaleSchedule.Public startDate={startDate} prefix="mint-opens" />
            <MintWidget.AddToCal
              onClick={() =>
                context.onAddToCal({
                  startDate,
                  endDate: deriveCalendarSaleEndDate({ startDate }),
                })
              }
            />
          </Checkout.Stack>
        </Checkout.Root>
      );
    })
    .with({ sale: { status: 'OPEN' } }, ({ sale: openSale }) => {
      const { maxTokenId } = openSale;
      return (
        <Checkout.Root>
          <Checkout.Stack>
            <MintWidgetQuantityStepper />
            <MintWidgetMintCta size={1} />
          </Checkout.Stack>
          <MintWidgetProgress minted={nftCount} supply={maxTokenId} />
          <CollectorsSummary
            type="collector"
            collectors={collectors}
            onClick={context.onCollectorsClick}
            maxPreviews={2}
          />
        </Checkout.Root>
      );
    })
    .with({ sale: { status: 'MINTED_OUT' } }, ({ sale: mintedOutSale }) => {
      return (
        <PostSaleMintWidget
          actions={
            context.onShopTheSecondary && (
              <MintWidget.BrowseSecondaryCta
                onClick={context.onShopTheSecondary}
              />
            )
          }
          collectors={collectors}
          nftCount={nftCount}
          sale={mintedOutSale}
          totalSales={totalSales}
        />
      );
    })
    .exhaustive();
}
