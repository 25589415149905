import { useContext } from 'react';

import { WithdrawDutchAuctionEarningsFlowContext } from './WithdrawDutchAuctionEarningsFlowContext';

export const useWithdrawDutchAuctionEarningsFlow = () => {
  const context = useContext(WithdrawDutchAuctionEarningsFlowContext);

  if (!context) {
    throw new Error(
      'useWithdrawDutchAuctionEarningsFlow must be used within a WithdrawDutchAuctionEarningsFlowContext provider'
    );
  }

  return context;
};
