import { FNDMiddleware } from '@f8n/f8n-contracts/dist/abis/FNDMiddleware.generated';
import { getDutchAuctionV3 } from '@f8n/f8n-contracts/dist/abis/FNDMiddlewareHelpers.generated';
import { addMinutes, isFuture, parseJSON } from 'date-fns';
import type { Address } from 'viem';
import { useWriteContract, useSimulateContract, useReadContract } from 'wagmi';

import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useTransactionStore from 'state/stores/transactions';
import { satisfiesExperimentalWeb3ActionConfig } from 'web3/config';

import { useQueryEffects } from 'hooks/react-query';
import useMarketBalances from 'hooks/web3/use-market-balances';
import { useRefetchOnBlock } from 'hooks/web3/use-watch-block';
import { NFTDropMarket } from 'lib/abis/NFTDropMarket';
import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';
import { ZERO_ADDRESS } from 'lib/constants';
import { FND_DROP_MARKET_MINT_FEE } from 'lib/drop-market';
import report from 'lib/report';
import { getAddress } from 'utils/address';
import { deriveFallbackDutchAuctionPrice } from 'utils/drops';
import { isNumberType } from 'utils/helpers';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';
import {
  calculateTransactionValue,
  getMintPriceBreakdown,
  getMintPriceStepperBreakdown,
} from 'utils/transactions';
import { createWeb3StepperControls } from 'utils/web3-cta';

import { FndLinearDutchAuctionSale } from 'types/DropSale';

import { MintFromDutchAuctionFlowContext } from './MintFromDutchAuctionFlowContext';
import {
  MINT_FROM_DUTCH_AUCTION_ACTION_NAME,
  MINT_FROM_DUTCH_AUCTION_TRACKING_COPY,
} from './constants';
import { MintFromDutchAuctionProviderProps } from './types';

type SimulateMintFromDutchAuctionOptions = {
  chainId: ChainId;
  contractAddress: Address;
  count: bigint;
  totalPrice: bigint;
};

function useSimulateMintFromLinearDutchAuction(
  options: SimulateMintFromDutchAuctionOptions
) {
  const { chainId, contractAddress, count, totalPrice } = options;

  const auth = useAuth();

  const marketBalancesQuery = useMarketBalances({
    chainId,
  });

  // TODO: explain why +1 is needed
  const transactionValue = totalPrice + BigInt(1);

  const calculatedTransactionValue = marketBalancesQuery.data
    ? calculateTransactionValue({
        transactionValue,
        marketplaceBalance: marketBalancesQuery.data.availableFethBalance,
      })
    : transactionValue;

  const simulateMintFromDutchAuction = useSimulateContract({
    abi: NFTDropMarket,
    functionName: 'mintFromDutchAuctionV2',
    address: getContractAddress({
      chainId,
      contractName: 'nftDropMarket',
    }),
    chainId,
    args: [
      // contract to mint from
      getAddress(contractAddress),
      // number of mints
      BigInt(count),
      // transferTo (who receives the minted NFT)
      hasPublicKey(auth) ? auth.publicKey : ZERO_ADDRESS,
    ],
    query: {
      enabled: hasPublicKey(auth) && count > 0,
      retry: false,
    },
    value: calculatedTransactionValue,
  });

  useQueryEffects(simulateMintFromDutchAuction, {
    onError: (error) => {
      report(extractPrepareContractWriteRevertReason({ error }));
    },
  });

  return simulateMintFromDutchAuction;
}

export function MintFromLinearDutchAuctionProvider(
  props: MintFromDutchAuctionProviderProps & {
    contractAddress: Address;
    sale: FndLinearDutchAuctionSale;
  }
) {
  const {
    chainId,
    children,
    contractAddress,
    indexedNftCount,
    mintQuantity,
    sale,
    setMintQuantity,
    worldId,
  } = props;

  const auth = useAuth();
  const txStore = useTransactionStore();

  const dutchAuctionQuery = useReadContract({
    abi: FNDMiddleware,
    address: getContractAddress({
      chainId,
      contractName: 'middleware',
    }),
    chainId,
    functionName: 'getDutchAuctionV3',
    args: [
      getAddress(contractAddress),
      hasPublicKey(auth) ? auth.publicKey : ZERO_ADDRESS,
    ],
    query: {
      select: getDutchAuctionV3.parseOutput,
    },
  });

  useRefetchOnBlock(dutchAuctionQuery, {
    /**
     * add 5 minutes of buffer so that enough time passes for blocks
     * after the sale has ended (to ensure the correct clearing price)
     */
    enabled: sale.endTime
      ? isFuture(addMinutes(parseJSON(sale.endTime), 5))
      : false,
  });

  const currentMintPrice = dutchAuctionQuery.data
    ? dutchAuctionQuery.data.currentPrice
    : deriveFallbackDutchAuctionPrice(sale);

  const totalMintedCount = dutchAuctionQuery.data
    ? Number(dutchAuctionQuery.data.mintedNftCount)
    : indexedNftCount;

  const isMintedOut =
    dutchAuctionQuery.data && isNumberType(sale.maxTokenId)
      ? Boolean(sale.maxTokenId === totalMintedCount)
      : sale.status === 'MINTED_OUT';

  const itemPriceBreakdown = getMintPriceBreakdown({
    count: BigInt(1),
    fee: FND_DROP_MARKET_MINT_FEE,
    price: currentMintPrice,
  });

  const stepper = getMintPriceStepperBreakdown({
    count: mintQuantity,
    fee: FND_DROP_MARKET_MINT_FEE,
    price: currentMintPrice,
  });

  const nextDecrement = stepper.nextDecrement.count;
  const nextIncrement = stepper.nextIncrement.count;

  const simulation = useSimulateMintFromLinearDutchAuction({
    chainId,
    count: BigInt(mintQuantity),
    contractAddress,
    totalPrice: stepper.current.pricing.totalPrice,
  });

  const nextDecrementSimulation = useSimulateMintFromLinearDutchAuction({
    chainId,
    count: BigInt(nextDecrement),
    contractAddress,
    totalPrice: stepper.nextDecrement.pricing.totalPrice,
  });

  const nextIncrementSimulation = useSimulateMintFromLinearDutchAuction({
    chainId,
    count: BigInt(nextIncrement),
    contractAddress,
    totalPrice: stepper.nextIncrement.pricing.totalPrice,
  });

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess(txHash) {
        txStore.startTracking({
          chainId,
          ui: 'toast',
          action: {
            name: MINT_FROM_DUTCH_AUCTION_ACTION_NAME,
            worldId,
            market: 'FND',
          },
          txHash,
          title: MINT_FROM_DUTCH_AUCTION_TRACKING_COPY.title,
        });
      },
    },
  });

  const disabledReason = extractPrepareContractWriteRevertReason(simulation);

  return (
    <MintFromDutchAuctionFlowContext.Provider
      value={{
        action: MINT_FROM_DUTCH_AUCTION_ACTION_NAME,
        auction: {
          isMintedOut,
          totalMintedCount,
        },
        chainId,
        config: satisfiesExperimentalWeb3ActionConfig({
          disabledReason,
          hasTxPrompt: contractWrite.isPending,
          reset: contractWrite.reset,
          simulation,
          txHash: contractWrite.data || null,

          prompt: () => {
            if (simulation.isSuccess) {
              contractWrite.writeContract(simulation.data.request);
            }
          },
        }),
        itemPricing: itemPriceBreakdown,
        cartPricing: stepper.current.pricing,
        cartQuantity: {
          count: mintQuantity,
          onCountChange: setMintQuantity,
          decrement: createWeb3StepperControls({
            count: nextDecrement,
            simulation: nextDecrementSimulation,
            onClick: () => setMintQuantity(nextDecrement),
          }),
          increment: createWeb3StepperControls({
            count: nextIncrement,
            simulation: nextIncrementSimulation,
            onClick: () => setMintQuantity(nextIncrement),
          }),
        },
        onChainQuery: dutchAuctionQuery,
      }}
    >
      {children}
    </MintFromDutchAuctionFlowContext.Provider>
  );
}
