import {
  differenceInSeconds,
  isFuture,
  isPast,
  parseJSON,
  secondsToMilliseconds,
} from 'date-fns';
import { useState } from 'react';
import { useHarmonicIntervalFn } from 'react-use';
import { match } from 'ts-pattern';

import { isNumberType } from 'utils/helpers';
import { clampRatio } from 'utils/numbers';

import { HighlightStaggeredDutchAuctionSale } from 'types/DropSale';
import { Ratio } from 'types/number';

import Gizmo from './gizmo';
import { PriceStage } from './gizmo/Gizmo';
import { StaggeredSegment } from './gizmo/GizmoStaggeredCircle';

type StaggeredSale = HighlightStaggeredDutchAuctionSale;

type StaggeredDutchAuctionGizmoProps = {
  brandColor: string;
  currentPrice: number | null;
  isMintedOut: boolean;
  lastMintedAtDate: Date | null;
  sale: StaggeredSale;
};

export function StaggeredDutchAuctionGizmo(
  props: StaggeredDutchAuctionGizmoProps
) {
  const { brandColor, currentPrice, isMintedOut, lastMintedAtDate, sale } =
    props;

  const [_, setTick] = useState(0);

  useHarmonicIntervalFn(
    () => setTick((v) => v + 1),
    sale.status === 'LIVE' ? secondsToMilliseconds(1) : null
  );

  const {
    completedRatio,
    endDate,
    mintEndDate,
    price,
    priceStage,
    segments,
    startDate,
  } = mapToStaggeredGizmoData({
    currentPrice,
    sale,
    isMintedOut,
    lastMintedAtDate,
  });

  return (
    <Gizmo.Root style={{ color: brandColor }}>
      <Gizmo.Content
        css={{
          // Forces the content to be below the circle (to prevent it from blocking the segment tooltips)
          zIndex: 'initial',
        }}
      >
        <Gizmo.Price price={price} stage={priceStage} />
        <Gizmo.Stat
          minPrice={sale.minPrice}
          isMintedOut={isMintedOut}
          startDate={startDate}
          endDate={endDate}
          lastMintedAtDate={lastMintedAtDate}
          mintEndDate={mintEndDate}
        />
      </Gizmo.Content>
      <Gizmo.StaggeredCircle
        completedRatio={completedRatio}
        isMintedOut={isMintedOut}
        sale={sale}
        segments={segments}
      />
    </Gizmo.Root>
  );
}

type StaggeredGizmoData = {
  completedRatio: Ratio;
  price: number;
  priceStage: PriceStage;
  segments: StaggeredSegment[];
  startDate: Date;
  endDate: Date;
  mintEndDate: Date | null;
};

export const mapToStaggeredGizmoData = (options: {
  currentPrice: number | null;
  isMintedOut: boolean;
  lastMintedAtDate: Date | null;
  sale: StaggeredSale;
}): StaggeredGizmoData => {
  const { currentPrice, isMintedOut, lastMintedAtDate, sale } = options;

  const startDate = parseJSON(sale.startTime);
  const endDate = parseJSON(sale.endTime);
  const mintEndDate = sale.mintEndTime ? parseJSON(sale.mintEndTime) : null;

  const isPastEndDate = isPast(endDate);

  const priceStage = match<
    {
      saleStatus: HighlightStaggeredDutchAuctionSale['status'];
      isMintedOut: boolean;
      isPastEndDate: boolean;
    },
    PriceStage
  >({
    saleStatus: sale.status,
    isMintedOut,
    isPastEndDate,
  })
    .with({ isPastEndDate: true }, () => 'final')
    .with({ isMintedOut: true }, () => 'final')
    .with({ saleStatus: 'SCHEDULED' }, () => 'starting')
    .with({ saleStatus: 'LIVE' }, () => 'current')
    .otherwise(() => 'final');

  const segments = mapApiSalePricesToStaggeredGizmoSegments({
    prices: sale.prices,
    saleClearingPrice: sale.clearingPrice,
    saleStartDate: startDate,
    saleEndDate: endDate,
  });

  const price = match(sale.status)
    .with('SCHEDULED', () => sale.maxPrice)
    .with('LIVE', () => {
      const activeSegment = segments.find(
        (segment) => segment.status === 'active'
      );

      if (isMintedOut && currentPrice) {
        return currentPrice;
      }

      // can happen when the sale is live, but user stays on the page while the timer runs out
      if (isPastEndDate) {
        return sale.minPrice;
      }

      // It's assumed that there will always be 1 active segment while the sale is live
      if (activeSegment) {
        return activeSegment.price;
      }

      // Fallback #1: if there are no active segments, use the first segment
      if (segments[0]) {
        return segments[0].price;
      }

      // Fallback #2: if there are no segments, use the max/starting price
      return sale.maxPrice;
    })
    .otherwise(() => currentPrice || sale.clearingPrice || sale.minPrice);

  const completedRatio = getCompletedRatio({
    startDate,
    endDate,
    isMintedOut,
    lastMintedAtDate,
  });

  return {
    completedRatio,
    price,
    priceStage,
    segments,
    startDate,
    endDate,
    mintEndDate,
  };
};

const mapApiSalePricesToStaggeredGizmoSegments = (options: {
  prices: StaggeredSale['prices'];
  saleClearingPrice: number | null;
  saleStartDate: Date;
  saleEndDate: Date;
}): StaggeredSegment[] => {
  const { prices, saleClearingPrice, saleStartDate, saleEndDate } = options;

  return prices.map((step, index, array): StaggeredSegment => {
    const { price } = step;

    /** API does not currently return the startTime for each step, so here we're making two assumptions
     *
     * 1. The first step starts at the same time as the sale
     * 2. The start time of each step is the end time of the previous step
     */
    const startDate =
      index === 0
        ? saleStartDate
        : parseJSON((array[index - 1] as typeof step).endTime);
    const endDate = parseJSON(step.endTime);

    const segmentStatus = getStaggeredSegmentStatus({
      price,
      startDate,
      endDate,
      saleClearingPrice,
      saleStartDate,
      saleEndDate,
    });

    return {
      price,
      startDate,
      endDate,
      status: segmentStatus,
    };
  });
};

const getStaggeredSegmentStatus = (options: {
  price: number;
  startDate: Date;
  endDate: Date;
  saleClearingPrice: number | null;
  saleEndDate: Date;
  saleStartDate: Date;
}): StaggeredSegment['status'] => {
  const {
    price,
    startDate,
    endDate,
    saleClearingPrice,
    saleStartDate,
    saleEndDate,
  } = options;

  // Entire sale minted out at or before this segment
  if (isNumberType(saleClearingPrice) && price <= saleClearingPrice) {
    return 'final';
  }

  // When entire sale is in the future, segment must be too
  if (isFuture(saleStartDate)) {
    return 'scheduled';
  }

  // When entire sale is in the past, segment must be too
  if (isPast(saleEndDate)) {
    return 'ended';
  }

  if (isFuture(startDate)) {
    return 'scheduled';
  }

  if (isPast(startDate) && isFuture(endDate)) {
    return 'active';
  }

  if (isPast(endDate)) {
    return 'ended';
  }

  return 'scheduled';
};

/**
 *
 * @param options
 * @returns number between 0...100
 */
const getCompletedRatio = (options: {
  startDate: Date;
  endDate: Date;
  isMintedOut: boolean;
  lastMintedAtDate: Date | null;
}): Ratio => {
  const { startDate, endDate, isMintedOut, lastMintedAtDate } = options;

  if (isMintedOut && lastMintedAtDate) {
    return calculateCompletedRatio({
      startDate,
      endDate,
      comparisonDate: lastMintedAtDate,
    });
  }

  if (isFuture(startDate)) {
    return 0;
  }

  if (isPast(endDate)) {
    return 1;
  }

  return calculateCompletedRatio({
    startDate,
    endDate,
    comparisonDate: new Date(),
  });
};

function calculateCompletedRatio(options: {
  startDate: Date;
  endDate: Date;
  comparisonDate: Date;
}) {
  const { startDate, endDate, comparisonDate } = options;

  const totalDurationMs = differenceInSeconds(endDate, startDate);
  const elapsedDurationMs = differenceInSeconds(comparisonDate, startDate);

  return clampRatio(elapsedDurationMs / totalDurationMs);
}
