import { InfoIcon } from '@f8n/icons';
import { parseJSON } from 'date-fns';
import { P, match } from 'ts-pattern';

import { CollectorsSummary } from 'components/CollectorsSummary';
import { SaleSchedule } from 'components/SaleSchedule';
import Divider from 'components/base/Divider';
import Flex from 'components/base/Flex';
import Link from 'components/base/Link';
import Text from 'components/base/Text';
import { Checkout } from 'components/checkout-widget/Checkout';

import { helpCenterPaths } from 'utils/router';

import { CollectionFilter } from 'types/Collection';
import { DutchAuctionDropSale } from 'types/DropSale';
import { CollectorsPreview } from 'types/collectors';

import { MintWidget } from './MintWidget';
import { useMintWidget } from './MintWidgetContext';
import { MintWidgetMintCta } from './MintWidgetMintCta';
import { MintWidgetQuantityStepper } from './MintWidgetQuantityStepper';

export function DutchAuctionDropMintWidget(props: {
  collectors: CollectorsPreview;
  gizmo: React.ReactNode;
  nftCount: number;
  sale: DutchAuctionDropSale;
  collection: CollectionFilter;
  isOwner: boolean;
  isRevealed: boolean;
}) {
  const { collectors, nftCount, sale, gizmo, isOwner, isRevealed, collection } =
    props;
  const { onAddToCal, onCollectorsClick, onShopTheSecondary } = useMintWidget();

  return match(sale)
    .with({ status: 'SCHEDULED' }, (sale) => {
      const { startTime, endTime, maxTokenId } = sale;

      const startDate = parseJSON(startTime);
      const endDate = parseJSON(endTime);

      return (
        <Checkout.Root>
          <AuctionName />
          {gizmo}
          <AuctionNftCount maxTokenId={maxTokenId} nftCount={nftCount} />
          <Divider />
          <Checkout.Stack css={{ gap: '$4' }}>
            <SaleSchedule.Public startDate={startDate} prefix="mint-opens" />
            <Checkout.Stack css={{ gap: '$3' }}>
              <MintWidget.AddToCal
                onClick={() => onAddToCal({ startDate, endDate })}
              />

              {isOwner && (
                <DutchAuctionDropOwnerActions
                  collection={collection}
                  isRevealed={isRevealed}
                />
              )}
            </Checkout.Stack>
          </Checkout.Stack>
        </Checkout.Root>
      );
    })
    .with({ status: P.union('AVAILABLE', 'LIVE') }, (sale) => {
      const { maxTokenId } = sale;

      return (
        <Checkout.Root>
          <AuctionName />
          {gizmo}

          <Checkout.Stack css={{ gap: '$3' }}>
            <Checkout.Stack>
              <AuctionNftCount maxTokenId={maxTokenId} nftCount={nftCount} />
              <MintWidgetQuantityStepper />
              <MintWidgetMintCta size={1} />
            </Checkout.Stack>

            {isOwner && (
              <DutchAuctionDropOwnerActions
                collection={collection}
                isRevealed={isRevealed}
              />
            )}
          </Checkout.Stack>

          <CollectorsSummary
            type="collector"
            collectors={collectors}
            onClick={onCollectorsClick}
            maxPreviews={2}
          />
        </Checkout.Root>
      );
    })
    .with({ status: 'MINTED_OUT' }, (sale) => {
      const { maxTokenId } = sale;
      return (
        <Checkout.Root>
          <AuctionName />
          {gizmo}
          <AuctionNftCount maxTokenId={maxTokenId} nftCount={nftCount} />
          <Checkout.Stack css={{ gap: '$3' }}>
            {onShopTheSecondary && (
              <MintWidget.BrowseSecondaryCta onClick={onShopTheSecondary} />
            )}

            {isOwner && (
              <DutchAuctionDropOwnerActions
                collection={collection}
                isRevealed={isRevealed}
              />
            )}
          </Checkout.Stack>

          <CollectorsSummary
            type="collector"
            collectors={collectors}
            onClick={onCollectorsClick}
            maxPreviews={2}
          />
        </Checkout.Root>
      );
    })
    .with({ status: 'ENDED' }, (sale) => {
      const { maxTokenId } = sale;
      return (
        <Checkout.Root>
          <AuctionName />
          {gizmo}
          <AuctionNftCount maxTokenId={maxTokenId} nftCount={nftCount} />

          <Checkout.Stack css={{ gap: '$3' }}>
            {onShopTheSecondary && (
              <MintWidget.BrowseSecondaryCta onClick={onShopTheSecondary} />
            )}
            {isOwner && (
              <DutchAuctionDropOwnerActions
                collection={collection}
                isRevealed={isRevealed}
              />
            )}
          </Checkout.Stack>

          <CollectorsSummary
            type="collector"
            collectors={collectors}
            onClick={onCollectorsClick}
            maxPreviews={2}
          />
        </Checkout.Root>
      );
    })
    .exhaustive();
}

function AuctionName() {
  return (
    <Text
      css={{ gap: '$1', display: 'flex', alignItems: 'center' }}
      color="dim"
      size={1}
    >
      Rebate Dutch Auction{' '}
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href={helpCenterPaths.dutchAuctions}
        css={{
          svg: {
            width: '10px',
            height: '10px',
            display: 'block',
          },
          color: '$black40',
          '@hover': {
            '&:hover': {
              color: '$black60',
            },
          },
        }}
      >
        <InfoIcon />
      </Link>
    </Text>
  );
}

function AuctionNftCount(props: { nftCount: number; maxTokenId: number }) {
  const { nftCount, maxTokenId } = props;
  return (
    <Text
      color="dim"
      css={{ width: '100%', textAlign: 'center' }}
      weight="regular"
    >
      <Text as="span" color="strong">
        {nftCount}
      </Text>
      /{maxTokenId} minted
    </Text>
  );
}

function DutchAuctionDropOwnerActions(props: {
  collection: CollectionFilter;
  isRevealed: boolean;
}) {
  const { collection, isRevealed } = props;

  return (
    <Checkout.Stack css={{ flexDirection: 'row', gap: '$2' }}>
      {!isRevealed && (
        <Flex css={{ flex: 1 }}>
          <MintWidget.RevealCollection collection={collection} />
        </Flex>
      )}

      <MintWidget.EditCollection contractAddress={collection.contractAddress} />
    </Checkout.Stack>
  );
}
