import { FrameMetadata, FrameMetadataReact } from '@coinbase/onchainkit';
import { darkMode } from '@f8n-frontend/stitches';
import { useQueryClient } from '@tanstack/react-query';
import { GetStaticPaths, GetStaticPropsContext } from 'next';
import Head from 'next/head';
import { useEffect, useRef, useState } from 'react';
import { P, match } from 'ts-pattern';
import { z } from 'zod';

import FarcasterHead from 'components/FarcasterHead';
import HighlightDutchAuctionClaimActions from 'components/HighlightDutchAuctionClaimActions';
import LinearDutchAuctionClaimActions from 'components/LinearDutchAuctionClaimActions';
import PageHead from 'components/PageHead';
import ModerationBanner from 'components/admin/ModerationBanner';
import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Divider from 'components/base/Divider';
import Tabs from 'components/base/Tabs';
import CollectionArtworks from 'components/collections/CollectionArtworks';
import CollectionSalesHistoryTable from 'components/collections/CollectionSalesHistory';
import Footer from 'components/footers/Footer';
import Header from 'components/headers/Header';
import { CollectionMintLayout } from 'components/layouts/CollectionMintLayout';
import { MintWidgetFlowManager } from 'components/mint-widget/MintWidgetFlowManager';
import BridgeModal from 'components/modals/BridgeModal';
import ClaimRebateModal from 'components/modals/ClaimRebateModal';
import CollectionOwnersModal from 'components/modals/CollectionOwnersModal';
import ReportModal from 'components/modals/ReportModal';
import ShareMintModal from 'components/modals/ShareMintModal';
import { SplitsModal } from 'components/modals/SplitsModal';
import WithdrawEarningsModal from 'components/modals/WithdrawEarningsModal';
import AdminToolsModal from 'components/modals/admin-tools/AdminToolsModal';
import HideCollectionPane from 'components/modals/admin-tools/HideCollectionPane';
import { CollectionModerationBlock } from 'components/trust-safety/ModerationBlock';
import useAnalytics from 'contexts/analytics/useAnalytics';
import { hasPublicKey, isAdmin, isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { ApiSplitFragment } from 'gql/api/api-fragments.generated';
import { useCollectionArtworkCounts } from 'gql/api/queries/collection-artwork-counts.generated';
import { ApiMintPage, useMintPage } from 'gql/api/queries/mint-page.generated';
import { useUserFollowState } from 'gql/api/queries/user-follow-state.generated';
import { ApiContractType } from 'gql/api/types-api.generated';
import useTrackView from 'hooks/analytics/use-track-view';
import {
  CollectionOwnersSplits,
  getCollectionOwnersSplits,
  useCollectionOwnersSplits,
} from 'hooks/queries/hasura/collections/use-collection-owners-splits';
import useModal from 'hooks/use-modal';
import useCanSelfDestructCollection from 'hooks/web3/use-can-self-destruct-collection';
import useMintedTokenCount from 'hooks/web3/use-minted-token-count';
import { useWatchMintEvent } from 'hooks/web3/use-watch-mint-event';
import { ChainId } from 'lib/chains';
import { NOT_FOUND, REVALIDATE_TIME } from 'lib/constants';
import { mapDropSaleConfigurationToDropSale } from 'schemas/parse/drop-sale';
import { mapEditionSaleConfigurationToEditionSale } from 'schemas/parse/edition-sale';
import { addressValueSchema } from 'schemas/shared';
import { chainSlugSchema } from 'schemas/url/chain';
import { getChainAnalyticsSummary } from 'utils/analytics';
import { getBlockExplorerByChainId } from 'utils/block-explorer';
import { isHighlightSale } from 'utils/drop-sale';
import { buildFarcasterUrl } from 'utils/farcaster';
import { editionButtons } from 'utils/frames';
import { getCollectionFilter } from 'utils/inputs';
import {
  mapApiMediaToAssetMedium,
  mapApiMediaToFullscreenMintMedia,
  mapApiMediaToMintMedia,
  mapApiMediaToOgImage,
} from 'utils/media';
import { isFlaggedForModeration } from 'utils/moderation';
import { getChainConfigById, getChainConfigBySlug } from 'utils/network';
import { getPath } from 'utils/router';
import { serverQuery } from 'utils/server';

import { CollectionFilter } from 'types/Collection';
import { CuratedStore } from 'types/CuratedStore';
import { DropSale } from 'types/DropSale';
import { EditionCollectionSale } from 'types/EditionSale';
import { CollectorsPreview } from 'types/collectors';
import { NextInferGetStaticPropsType } from 'types/next';

type MintPageInitialProps = NextInferGetStaticPropsType<typeof getStaticProps>;

export default function MintPageRoot(initialProps: MintPageInitialProps) {
  const mintPageQuery = useMintPage(
    {
      collection: initialProps.collectionFilter,
    },
    {
      initialData: {
        collection: initialProps.pageData.collection,
        moment: initialProps.pageData.moment,
        splits: initialProps.pageData.splits,
      },
    }
  );

  const collection =
    mintPageQuery.isSuccess && mintPageQuery.data.collection
      ? mintPageQuery.data.collection
      : initialProps.pageData.collection;

  const moment = mintPageQuery.isSuccess
    ? mintPageQuery.data.moment
    : initialProps.pageData.moment;

  const splits = mintPageQuery.isSuccess
    ? mintPageQuery.data.splits
    : initialProps.pageData.splits;

  if (collection.__typename === 'DropCollection') {
    return (
      <MintPageConnected
        category="DROP"
        collection={collection}
        moment={moment}
        sale={mapDropSaleConfigurationToDropSale(collection.saleConfiguration)}
        collectionOwnersSplits={initialProps.collectionOwnersSplits}
        splits={splits}
      />
    );
  }

  if (collection.__typename === 'EditionCollection' && collection.editionSale) {
    return (
      <MintPageConnected
        category="EDITION"
        collection={collection}
        moment={moment}
        sale={mapEditionSaleConfigurationToEditionSale(collection.editionSale)}
        collectionOwnersSplits={initialProps.collectionOwnersSplits}
        splits={splits}
      />
    );
  }

  throw new Error('Collection not found');
}

type MintPageCollection = NonNullable<ApiMintPage['collection']>;
type MintPageDropCollection = Extract<
  MintPageCollection,
  { __typename: 'DropCollection' }
>;
type MintPageEditionCollection = Extract<
  MintPageCollection,
  { __typename: 'EditionCollection' }
>;

type MintPageConnectedProps = { moment: ApiMintPage['moment'] } & (
  | {
      collection: MintPageDropCollection;
      category: 'DROP';
      sale: DropSale | null;
      splits: ApiSplitFragment[];
      collectionOwnersSplits: CollectionOwnersSplits;
    }
  | {
      collection: MintPageEditionCollection;
      category: 'EDITION';
      sale: EditionCollectionSale;
      splits: ApiSplitFragment[];
      collectionOwnersSplits: CollectionOwnersSplits;
    }
);

type MintPageTabValue = 'nfts' | 'activity';

function MintPageConnected(props: MintPageConnectedProps) {
  const { collection, category, collectionOwnersSplits, moment, sale, splits } =
    props;
  const {
    description,
    chainId,
    creator,
    contractAddress,
    contractType,
    media: apiMedia,
    moderationFrom,
    moderationStatus,
    name: collectionName,
    nftCount,
    slug,
  } = collection;

  const auth = useAuth();
  const analytics = useAnalytics();
  const modal = useModal();
  const trackView = useTrackView();

  const isCreatorConnected = isMyAddress(auth, creator.publicKey);

  const tabsRef = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState<MintPageTabValue>(() =>
    category === 'EDITION' ? 'activity' : 'nfts'
  );

  const userFollowQuery = useUserFollowState({
    publicKey: creator.publicKey,
  });

  const canSelfDestructCollectionQuery = useCanSelfDestructCollection(
    {
      contractAddress,
      chainId,
    },
    {
      enabled: isCreatorConnected,
    }
  );

  const canSelfDestructCollection = canSelfDestructCollectionQuery.isSuccess;

  const collectionOwnersSplitsQuery = useCollectionOwnersSplits(
    { contractAddress },
    { initialData: collectionOwnersSplits }
  );

  const getCollectors = (): CollectorsPreview => {
    if (collectionOwnersSplitsQuery.isSuccess) {
      return {
        preview: collectionOwnersSplitsQuery.data.collectors,
        totalCount: collectionOwnersSplitsQuery.data.collectorsCount,
      };
    }
    return {
      preview: props.collectionOwnersSplits.collectors,
      totalCount: props.collectionOwnersSplits.collectorsCount,
    };
  };

  const collectors = getCollectors();

  const queryClient = useQueryClient();

  const collectionFilter = { chainId, contractAddress };

  const mintedTokenCountQuery = useMintedTokenCount(collectionFilter, {
    enabled: true,
  });

  const refetchEvents = () => {
    queryClient.invalidateQueries({
      queryKey: mintedTokenCountQuery.queryKey,
    });
  };

  useWatchMintEvent(
    { filter: collectionFilter, sale },
    { onMintEvent: refetchEvents }
  );

  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : null;
  const isCurrentUserAdmin = isAdmin(auth);
  const isCollectionModerated = isFlaggedForModeration(moderationStatus);
  const isCurrentUserCollectionCreator = isMyAddress(auth, creator.publicKey);
  const isCurrentUserAdminOrCreator =
    isCurrentUserAdmin || isCurrentUserCollectionCreator;
  const hasFullScreenModerationBlock =
    isCollectionModerated && !isCurrentUserAdminOrCreator;

  const chainConfig = getChainConfigById(chainId);

  const creatorFollowersCount = match(userFollowQuery.data)
    .with(
      { userFollowState: { followerCount: P.number } },
      (data) => data.userFollowState.followerCount
    )
    .otherwise(() => null);

  const media = mapApiMediaToMintMedia(apiMedia);
  const mediaFullscreen = mapApiMediaToFullscreenMintMedia(apiMedia);
  const mediaAssetMedium = mapApiMediaToAssetMedium(apiMedia);
  const metaImage = mapApiMediaToOgImage(apiMedia);

  const store = match({ collection, moment })
    .with({ moment: P.not(null) }, ({ moment }): CuratedStore => {
      return { type: 'MOMENT', moment };
    })
    .with(
      { collection: { world: P.not(null) } },
      ({ collection: { world } }): CuratedStore => {
        return { type: 'WORLD', world };
      }
    )
    .otherwise(() => null);

  const blockExplorer = getBlockExplorerByChainId(chainConfig.chainId);
  const blockExplorerHref = blockExplorer.address.getUrl({
    address: contractAddress,
  });

  const onShopTheSecondary = () => {
    if (!tabsRef.current) return;

    analytics.track({ name: 'clicked_shop_the_secondary' });

    tabsRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const EVENT_NAME = 'viewed_collection';
    const chain = getChainAnalyticsSummary(chainId);

    match(props)
      .with({ category: 'DROP' }, ({ sale: dropSale }) => {
        trackView({
          name: EVENT_NAME,
          chain,
          collectionType: 'drop',
          contractAddress,
          sale: dropSale,
          slug,
        });
      })
      .with({ category: 'EDITION' }, ({ sale: editionSale }) => {
        trackView({
          name: EVENT_NAME,
          chain,
          collectionType: 'edition',
          contractAddress,
          sale: editionSale,
          slug,
        });
      })
      .exhaustive();
  }, []);

  const meta = (
    <PageHead
      description={collection.description}
      title={collection.name}
      // set to null to allow dynamic frame OG to take priority (set in FarcasterHeadEdition)
      image={category === 'EDITION' ? null : metaImage}
    />
  );

  const header = <Header absolute={false} mode="LIGHT" type="MAXIMAL" />;

  if (hasFullScreenModerationBlock) {
    return (
      <>
        {meta}
        {header}
        <CollectionModerationBlock
          moderationStatus={moderationStatus}
          moderationFrom={moderationFrom}
        />
      </>
    );
  }

  return (
    <>
      {/* Modals */}
      <BridgeModal />
      <ShareMintModal />
      <SplitsModal />
      <ClaimRebateModal />
      <WithdrawEarningsModal />
      <CollectionOwnersModal
        contractSlug={slug}
        currentUserPublicKey={currentUserPublicKey}
      />
      <ReportModal
        pageType="Collection"
        publicKey={currentUserPublicKey || ''}
        reportedPublicKey={collection.creator.publicKey}
      />
      {isAdmin(auth) && (
        <AdminToolsModal
          tools={[
            {
              id: 'hide-collection',
              name: 'Hide Collection',
              ui: <HideCollectionPane collectionId={collection.id} />,
            },
          ]}
        />
      )}

      {/* Meta */}
      {meta}
      {category === 'EDITION' && <FarcasterHeadEdition edition={collection} />}
      <FarcasterHead
        collectionName={collection.name}
        contractAddress={collection.contractAddress}
        creatorAddress={collection.creator.publicKey}
      />

      {/* Moderation */}
      {isCollectionModerated && isCurrentUserAdminOrCreator && (
        <ModerationBanner
          status={moderationStatus}
          messages={{
            SUSPENDED: isCurrentUserCollectionCreator
              ? 'Your collection has been removed.'
              : 'This collection has been removed.',
            UNDER_REVIEW: isCurrentUserCollectionCreator
              ? 'Your collection is under review.'
              : 'This collection is under review.',
            TAKEDOWN_REQUESTED: isCurrentUserCollectionCreator
              ? `Your collection has received a DMCA takedown notice from ${moderationFrom}.`
              : `This collection has received a DMCA takedown notice from ${moderationFrom}.`,
          }}
        />
      )}

      <Header absolute={false} mode="LIGHT" type="MAXIMAL" />
      <Divider />
      <Body>
        <CollectionMintLayout
          blockExplorerHref={blockExplorerHref}
          canSelfDestructCollection={canSelfDestructCollection}
          contractAddress={contractAddress}
          chainConfig={chainConfig}
          collectionName={collectionName}
          contractCategory={category}
          contractType={contractType}
          creator={creator}
          creatorFollowersCount={creatorFollowersCount}
          description={description}
          highlightId={sale && isHighlightSale(sale) ? sale.highlightId : null}
          media={media}
          mediaFullscreen={mediaFullscreen}
          mediaAssetMedium={mediaAssetMedium}
          mintWidget={
            sale ? (
              <MintWidgetFlowManager
                isOwner={isCurrentUserCollectionCreator}
                collection={collection}
                nftCount={
                  mintedTokenCountQuery.isSuccess
                    ? mintedTokenCountQuery.data
                    : collection.nftCount
                }
                onShopTheSecondary={onShopTheSecondary}
                sale={sale}
                collectors={collectors}
                world={collection.world ? collection.world : null}
                isRevealed={match(props)
                  .with({ category: 'DROP' }, (props) =>
                    Boolean(props.collection.isRevealed)
                  )
                  .otherwise(() => null)}
              />
            ) : null
          }
          store={store}
          splits={splits}
          onShare={() => {
            modal.setModal({
              collection,
              creator: collection.creator,
              category: category,
              type: 'SHARE_MINT',
            });
          }}
          onSplitsClick={() => {
            modal.setModal({
              type: 'SPLITS',
              splits: collectionOwnersSplits.splits,
              chainId: collection.chainId,
            });
          }}
        />
      </Body>
      {nftCount > 0 && (
        <Box>
          <Tabs.Root
            ref={tabsRef}
            value={tab}
            onValueChange={(value) => {
              setTab(value as MintPageTabValue);
            }}
          >
            <Tabs.Container>
              <Body>
                <Tabs.List centered>
                  <Tabs.Trigger value="nfts">NFTs</Tabs.Trigger>
                  <Tabs.Trigger value="activity">Activity</Tabs.Trigger>
                </Tabs.List>
              </Body>
            </Tabs.Container>
            <Body css={{ paddingBottom: '$10' }}>
              <Tabs.Content value="nfts">
                <ArtworksConnected
                  sale={sale}
                  collection={collection}
                  nftCount={
                    mintedTokenCountQuery.isSuccess
                      ? mintedTokenCountQuery.data
                      : collection.nftCount
                  }
                />
              </Tabs.Content>
              <Tabs.Content value="activity">
                <Box css={{ paddingTop: '$3', paddingBottom: '$8' }}>
                  <CollectionSalesHistoryTable
                    contractAddress={contractAddress}
                  />
                </Box>
              </Tabs.Content>
            </Body>
          </Tabs.Root>
        </Box>
      )}
      <Box className={darkMode} css={{ backgroundColor: '$black100' }}>
        <Footer footerMode="DARK" type="MAXIMAL" />
      </Box>

      {match(props)
        .with(
          {
            category: 'DROP',
            collection: { world: P.not(P.nullish) },
            sale: { type: 'HIGHLIGHT_STAGGERED_DUTCH_AUCTION' },
          },
          ({ collection, sale }) => {
            return (
              <HighlightDutchAuctionClaimActions
                collection={collection}
                sale={sale}
                totalMinted={nftCount}
                takeRateInBasisPoints={collection.world.takeRateInBasisPoints}
              />
            );
          }
        )
        .with(
          {
            category: 'DROP',
            sale: { type: 'FND_LINEAR_DUTCH_AUCTION' },
          },
          ({ collection, sale }) => {
            return (
              <LinearDutchAuctionClaimActions
                collection={collection}
                sale={sale}
              />
            );
          }
        )
        .otherwise(() => null)}
    </>
  );
}

type CollectionArtworksConnectedProps = {
  collection: CollectionFilter & {
    chainId: ChainId;
    contractType: ApiContractType;
  };
  nftCount: number;
  sale: DropSale | EditionCollectionSale | null;
};

function ArtworksConnected(props: CollectionArtworksConnectedProps) {
  const { collection, nftCount } = props;

  const collectionFilter = getCollectionFilter(collection);

  const artworksCountQuery = useCollectionArtworkCounts({
    collection: collectionFilter,
  });

  const burnedTokensCount =
    artworksCountQuery.isSuccess &&
    artworksCountQuery.data.collectionArtworkCounts
      ? artworksCountQuery.data.collectionArtworkCounts.burnedTokensCount
      : null;

  return (
    <Box css={{ paddingTop: '$6' }}>
      <CollectionArtworks
        collectionChainId={collection.chainId}
        collection={collection}
        burnedTokensCount={burnedTokensCount}
        mintedTokensCount={nftCount}
        isOwnerOnCollection={false}
        isOwnerOrAdmin={false}
        unstyledCollection={false}
        isEmptyCollection={false}
      />
    </Box>
  );
}

type FarcasterHeadEditionProps = {
  edition: MintPageEditionCollection;
};

function FarcasterHeadEdition(props: FarcasterHeadEditionProps) {
  const collectionFilter = getCollectionFilter(props.edition);

  const mintPageUrl = buildFarcasterUrl(getPath.mint.page(collectionFilter));
  const ogImageUrl = buildFarcasterUrl(
    getPath.api.openGraph.edition(collectionFilter)
  );

  if (props.edition.editionSale === null)
    throw new Error('No sale configuration found');

  const sale = mapEditionSaleConfigurationToEditionSale(
    props.edition.editionSale
  );

  const buttons = match(sale)
    .with({ status: 'SCHEDULED' }, () => [editionButtons.view(mintPageUrl)])
    .with({ status: 'OPEN' }, () => [editionButtons.mint(collectionFilter)])
    .with({ status: P.union('ENDED', 'MINTED_OUT') }, () => [
      editionButtons.view(mintPageUrl),
    ])
    .exhaustive();

  return (
    <FrameMetadata
      image={{
        src: ogImageUrl,
        aspectRatio: '1:1',
      }}
      buttons={buttons as FrameMetadataReact['buttons']} // TODO: Upgrade to ts-pattern@^5 to use .returnType()
      wrapper={Head}
    />
  );
}

const mintPageParamsSchema = z.object({
  chain: chainSlugSchema,
  contractAddress: addressValueSchema,
});

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const params = mintPageParamsSchema.safeParse(context.params);

  if (!params.success) {
    return NOT_FOUND;
  }

  const { chain: chainSlug, contractAddress } = params.data;

  const chainConfig = getChainConfigBySlug(chainSlug);

  const collectionFilter: CollectionFilter = {
    chainId: chainConfig.chainId,
    contractAddress,
  };

  const fetchMintPage = useMintPage.fetcher({ collection: collectionFilter });

  const [pageQuery, collectionOwnersSplits] = await Promise.all([
    serverQuery(fetchMintPage),
    getCollectionOwnersSplits({ contractAddress }),
  ]);

  // Re-throw when page query fails
  if (pageQuery.error) {
    throw pageQuery.error;
  }

  const { collection, moment, splits } = pageQuery.data;

  // 404 when no collection found
  if (!collection) {
    return NOT_FOUND;
  }

  if (collection.__typename === 'StandardCollection') {
    return {
      redirect: {
        destination: getPath.collection.page(collection.slug),
        permanent: true,
      },
    };
  }

  return {
    props: {
      collectionFilter,
      pageData: {
        collection,
        moment,
        splits,
      },
      collectionOwnersSplits,
    },
    revalidate: REVALIDATE_TIME,
  };
};

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};
