import Stepper from 'components/Stepper';
import { Checkout } from 'components/checkout-widget/Checkout';

import { MAX_MINT_QUANTITY } from 'lib/constants';
import { ethToNumber } from 'utils/bigint';

import { useMintWidget } from './MintWidgetContext';
import { MintWidgetSaleStats } from './MintWidgetSaleStats';

export function MintWidgetQuantityStepper() {
  const { pricing, quantity } = useMintWidget();

  const mintPrice = ethToNumber(pricing.mintPrice);
  const feePrice = ethToNumber(pricing.feePrice);

  return (
    <Checkout.StatRowContainer
      css={{
        alignItems: 'flex-end',
        justifyContent: 'space-between',
      }}
    >
      <MintWidgetSaleStats.Price mintPrice={mintPrice} feePrice={feePrice} />
      <Stepper
        size={1}
        count={quantity.count}
        onCountChange={quantity.onCountChange}
        decrement={quantity.decrement}
        increment={quantity.increment}
        isDisabled={false}
        min={1}
        max={MAX_MINT_QUANTITY}
      />
    </Checkout.StatRowContainer>
  );
}
