export const HighlightDutchAuction = [
  { type: 'error', inputs: [], name: 'CollectorNotOwedRebate' },
  { type: 'error', inputs: [], name: 'EtherSendFailed' },
  { type: 'error', inputs: [], name: 'InvalidDPPFundsWithdrawl' },
  { type: 'error', inputs: [], name: 'InvalidMint' },
  { type: 'error', inputs: [], name: 'InvalidMintManager' },
  { type: 'error', inputs: [], name: 'InvalidPaymentAmount' },
  { type: 'error', inputs: [], name: 'InvalidRebate' },
  { type: 'error', inputs: [], name: 'InvalidUpdate' },
  { type: 'error', inputs: [], name: 'InvalidVectorConfig' },
  { type: 'error', inputs: [], name: 'NotMintManager' },
  { type: 'error', inputs: [], name: 'Unauthorized' },
  { type: 'error', inputs: [], name: 'VectorAlreadyCreated' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'newAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'AdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'beacon',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'BeaconUpgraded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'mechanicVectorId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'collector',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'rebate',
        internalType: 'uint200',
        type: 'uint200',
        indexed: false,
      },
      {
        name: 'currentPricePerNft',
        internalType: 'uint200',
        type: 'uint200',
        indexed: false,
      },
    ],
    name: 'DiscreteDutchAuctionCollectorRebate',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'mechanicVectorId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
    ],
    name: 'DiscreteDutchAuctionCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'mechanicVectorId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'paymentRecipient',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'clearingPrice',
        internalType: 'uint200',
        type: 'uint200',
        indexed: false,
      },
      {
        name: 'currentSupply',
        internalType: 'uint48',
        type: 'uint48',
        indexed: false,
      },
    ],
    name: 'DiscreteDutchAuctionDPPFundsWithdrawn',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'mechanicVectorId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'recipient',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'pricePerToken',
        internalType: 'uint200',
        type: 'uint200',
        indexed: false,
      },
      {
        name: 'numMinted',
        internalType: 'uint48',
        type: 'uint48',
        indexed: false,
      },
    ],
    name: 'DiscreteDutchAuctionMint',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'mechanicVectorId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
    ],
    name: 'DiscreteDutchAuctionUpdated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'version', internalType: 'uint8', type: 'uint8', indexed: false },
    ],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'implementation',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'Upgraded',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'vectorData', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'createVector',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'getPayeePotentialEscrowedFunds',
    outputs: [
      { name: 'escrowFunds', internalType: 'uint256', type: 'uint256' },
      { name: 'amountFinalized', internalType: 'bool', type: 'bool' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'getRawVector',
    outputs: [
      {
        name: '_vector',
        internalType: 'struct DiscreteDutchAuctionMechanic.DutchAuctionVector',
        type: 'tuple',
        components: [
          { name: 'startTimestamp', internalType: 'uint48', type: 'uint48' },
          { name: 'endTimestamp', internalType: 'uint48', type: 'uint48' },
          { name: 'periodDuration', internalType: 'uint32', type: 'uint32' },
          {
            name: 'maxUserClaimableViaVector',
            internalType: 'uint32',
            type: 'uint32',
          },
          {
            name: 'maxTotalClaimableViaVector',
            internalType: 'uint48',
            type: 'uint48',
          },
          { name: 'currentSupply', internalType: 'uint48', type: 'uint48' },
          {
            name: 'lowestPriceSoldAtIndex',
            internalType: 'uint32',
            type: 'uint32',
          },
          { name: 'tokenLimitPerTx', internalType: 'uint32', type: 'uint32' },
          { name: 'numPrices', internalType: 'uint32', type: 'uint32' },
          {
            name: 'paymentRecipient',
            internalType: 'address payable',
            type: 'address',
          },
          { name: 'totalSales', internalType: 'uint240', type: 'uint240' },
          { name: 'bytesPerPrice', internalType: 'uint8', type: 'uint8' },
          { name: 'auctionExhausted', internalType: 'bool', type: 'bool' },
          {
            name: 'payeeRevenueHasBeenWithdrawn',
            internalType: 'bool',
            type: 'bool',
          },
        ],
      },
      { name: 'packedPrices', internalType: 'bytes', type: 'bytes' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'user', internalType: 'address', type: 'address' },
    ],
    name: 'getUserInfo',
    outputs: [
      { name: 'rebate', internalType: 'uint256', type: 'uint256' },
      {
        name: '',
        internalType: 'struct DiscreteDutchAuctionMechanic.UserPurchaseInfo',
        type: 'tuple',
        components: [
          { name: 'numTokensBought', internalType: 'uint32', type: 'uint32' },
          { name: 'numRebates', internalType: 'uint24', type: 'uint24' },
          { name: 'totalPosted', internalType: 'uint200', type: 'uint200' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'getVectorState',
    outputs: [
      {
        name: '_vector',
        internalType: 'struct DiscreteDutchAuctionMechanic.DutchAuctionVector',
        type: 'tuple',
        components: [
          { name: 'startTimestamp', internalType: 'uint48', type: 'uint48' },
          { name: 'endTimestamp', internalType: 'uint48', type: 'uint48' },
          { name: 'periodDuration', internalType: 'uint32', type: 'uint32' },
          {
            name: 'maxUserClaimableViaVector',
            internalType: 'uint32',
            type: 'uint32',
          },
          {
            name: 'maxTotalClaimableViaVector',
            internalType: 'uint48',
            type: 'uint48',
          },
          { name: 'currentSupply', internalType: 'uint48', type: 'uint48' },
          {
            name: 'lowestPriceSoldAtIndex',
            internalType: 'uint32',
            type: 'uint32',
          },
          { name: 'tokenLimitPerTx', internalType: 'uint32', type: 'uint32' },
          { name: 'numPrices', internalType: 'uint32', type: 'uint32' },
          {
            name: 'paymentRecipient',
            internalType: 'address payable',
            type: 'address',
          },
          { name: 'totalSales', internalType: 'uint240', type: 'uint240' },
          { name: 'bytesPerPrice', internalType: 'uint8', type: 'uint8' },
          { name: 'auctionExhausted', internalType: 'bool', type: 'bool' },
          {
            name: 'payeeRevenueHasBeenWithdrawn',
            internalType: 'bool',
            type: 'bool',
          },
        ],
      },
      { name: 'prices', internalType: 'uint200[]', type: 'uint200[]' },
      { name: 'currentPrice', internalType: 'uint200', type: 'uint200' },
      {
        name: 'payeePotentialEscrowedFunds',
        internalType: 'uint256',
        type: 'uint256',
      },
      { name: 'collectionSupply', internalType: 'uint256', type: 'uint256' },
      { name: 'collectionSize', internalType: 'uint256', type: 'uint256' },
      {
        name: 'escrowedFundsAmountFinalized',
        internalType: 'bool',
        type: 'bool',
      },
      { name: 'auctionExhausted', internalType: 'bool', type: 'bool' },
      { name: 'auctionInFPP', internalType: 'bool', type: 'bool' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '_mintManager', internalType: 'address', type: 'address' },
      { name: 'platform', internalType: 'address', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'mintManager',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'tokenIds', internalType: 'uint256[]', type: 'uint256[]' },
      {
        name: 'mechanicVectorMetadata',
        internalType: 'struct IMechanicData.MechanicVectorMetadata',
        type: 'tuple',
        components: [
          { name: 'contractAddress', internalType: 'address', type: 'address' },
          { name: 'editionId', internalType: 'uint96', type: 'uint96' },
          { name: 'mechanic', internalType: 'address', type: 'address' },
          { name: 'isEditionBased', internalType: 'bool', type: 'bool' },
          { name: 'isChoose', internalType: 'bool', type: 'bool' },
          { name: 'paused', internalType: 'bool', type: 'bool' },
        ],
      },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'processChooseMint',
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'numToMint', internalType: 'uint32', type: 'uint32' },
      {
        name: 'mechanicVectorMetadata',
        internalType: 'struct IMechanicData.MechanicVectorMetadata',
        type: 'tuple',
        components: [
          { name: 'contractAddress', internalType: 'address', type: 'address' },
          { name: 'editionId', internalType: 'uint96', type: 'uint96' },
          { name: 'mechanic', internalType: 'address', type: 'address' },
          { name: 'isEditionBased', internalType: 'bool', type: 'bool' },
          { name: 'isChoose', internalType: 'bool', type: 'bool' },
          { name: 'paused', internalType: 'bool', type: 'bool' },
        ],
      },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'processNumMint',
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'collector', internalType: 'address payable', type: 'address' },
    ],
    name: 'rebateCollector',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: '_mintManager', internalType: 'address', type: 'address' },
    ],
    name: 'updateMintManager',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
      {
        name: 'newVector',
        internalType: 'struct DiscreteDutchAuctionMechanic.DutchAuctionVector',
        type: 'tuple',
        components: [
          { name: 'startTimestamp', internalType: 'uint48', type: 'uint48' },
          { name: 'endTimestamp', internalType: 'uint48', type: 'uint48' },
          { name: 'periodDuration', internalType: 'uint32', type: 'uint32' },
          {
            name: 'maxUserClaimableViaVector',
            internalType: 'uint32',
            type: 'uint32',
          },
          {
            name: 'maxTotalClaimableViaVector',
            internalType: 'uint48',
            type: 'uint48',
          },
          { name: 'currentSupply', internalType: 'uint48', type: 'uint48' },
          {
            name: 'lowestPriceSoldAtIndex',
            internalType: 'uint32',
            type: 'uint32',
          },
          { name: 'tokenLimitPerTx', internalType: 'uint32', type: 'uint32' },
          { name: 'numPrices', internalType: 'uint32', type: 'uint32' },
          {
            name: 'paymentRecipient',
            internalType: 'address payable',
            type: 'address',
          },
          { name: 'totalSales', internalType: 'uint240', type: 'uint240' },
          { name: 'bytesPerPrice', internalType: 'uint8', type: 'uint8' },
          { name: 'auctionExhausted', internalType: 'bool', type: 'bool' },
          {
            name: 'payeeRevenueHasBeenWithdrawn',
            internalType: 'bool',
            type: 'bool',
          },
        ],
      },
      { name: 'newPackedPrices', internalType: 'bytes', type: 'bytes' },
      {
        name: 'updateConfig',
        internalType:
          'struct DiscreteDutchAuctionMechanic.DutchAuctionVectorUpdateConfig',
        type: 'tuple',
        components: [
          { name: 'updateStartTimestamp', internalType: 'bool', type: 'bool' },
          { name: 'updateEndTimestamp', internalType: 'bool', type: 'bool' },
          { name: 'updatePeriodDuration', internalType: 'bool', type: 'bool' },
          {
            name: 'updateMaxUserClaimableViaVector',
            internalType: 'bool',
            type: 'bool',
          },
          {
            name: 'updateMaxTotalClaimableViaVector',
            internalType: 'bool',
            type: 'bool',
          },
          { name: 'updateTokenLimitPerTx', internalType: 'bool', type: 'bool' },
          {
            name: 'updatePaymentRecipient',
            internalType: 'bool',
            type: 'bool',
          },
          { name: 'updatePrices', internalType: 'bool', type: 'bool' },
        ],
      },
    ],
    name: 'updateVector',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
    ],
    name: 'upgradeTo',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    inputs: [
      { name: '', internalType: 'bytes32', type: 'bytes32' },
      { name: '', internalType: 'address', type: 'address' },
    ],
    name: 'userPurchaseInfo',
    outputs: [
      { name: 'numTokensBought', internalType: 'uint32', type: 'uint32' },
      { name: 'numRebates', internalType: 'uint24', type: 'uint24' },
      { name: 'totalPosted', internalType: 'uint200', type: 'uint200' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'withdrawDPPFunds',
    outputs: [],
    stateMutability: 'nonpayable',
  },
] as const;
