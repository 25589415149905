import { useQueryClient } from '@tanstack/react-query';
import { parseJSON } from 'date-fns';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { Address } from 'viem';
import { useWatchBlockNumber } from 'wagmi';

import { LinearDutchAuctionGizmo } from 'components/LinearDutchAuctionGizmo';
import { StaggeredDutchAuctionGizmo } from 'components/StaggeredDutchAuctionGizmo';
import Gizmo from 'components/gizmo';
import { FixedPriceDropMintWidget } from 'components/mint-widget/FixedPriceDropMintWidget';
import { LimitedEditionMintWidget } from 'components/mint-widget/LimitedEditionMintWidget';
import { TimedEditionMintWidget } from 'components/mint-widget/TimedEditionMintWidget';
import useAnalytics from 'contexts/analytics/useAnalytics';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import { MintFromHighlightStaggeredDutchAuctionProvider } from 'flows/mint-from-dutch-auction/MintFromHighlightStaggeredDutchAuctionProvider';
import { MintFromLinearDutchAuctionProvider } from 'flows/mint-from-dutch-auction/MintFromLinearDutchAuctionProvider';
import { useMintFromDutchAuctionFlow } from 'flows/mint-from-dutch-auction/use-mint-from-dutch-auction-flow';
import { MintFromFoundationFixedPriceProvider } from 'flows/mint-from-fixed-price/MintFromFoundationFixedPriceProvider';
import { MintFromHighlightFixedPriceProvider } from 'flows/mint-from-fixed-price/MintFromHighlightFixedPriceProvider';
import { useMintFromFixedPriceFlow } from 'flows/mint-from-fixed-price/use-mint-from-fixed-price-flow';
import useTransactionStore, {
  useTransactionEffects,
} from 'state/stores/transactions';

import { ApiDropFragment } from 'gql/api/api-fragments.generated';
import { useMintPage } from 'gql/api/queries/mint-page.generated';
import { usePresaleEligibility } from 'gql/hasura/queries/pre-sale-eligibility.generated';
import useModal from 'hooks/use-modal';
import { useWeb3ActionCta } from 'hooks/web3/use-web3-action-cta';
import { ChainId } from 'lib/chains';
import { ZERO_ADDRESS } from 'lib/constants';
import { ethToNumber } from 'utils/bigint';
import {
  getCalendarDescriptionAnchor,
  getGoogleCalendarLink,
} from 'utils/calendar';
import { getCurrentWindowUrl } from 'utils/location';
import { extractGeneratedQueryKeyRoot } from 'utils/react-query';
import { selectTransactionByAction } from 'utils/transaction-tracking';

import { UserLight } from 'types/Account';
import { CollectionFilter } from 'types/Collection';
import { CollectionSale } from 'types/CollectionSale';
import {
  DutchAuctionDropSale,
  FixedPriceDropSale,
  FndLinearDutchAuctionSale,
  HighlightStaggeredDutchAuctionSale,
  PresaleEligibility,
} from 'types/DropSale';
import { ERC1155TokenSale, EditionCollectionSale } from 'types/EditionSale';
import { Marketplace } from 'types/Marketplace';
import { WorldOverview } from 'types/World';
import { CollectorsPreview } from 'types/collectors';

import { DutchAuctionDropMintWidget } from './DutchAuctionDropMintWidget';
import { MintWidgetContext } from './MintWidgetContext';
import { MintWidgetContextValue } from './types';

export function MintWidgetFlowManager(props: {
  collection: CollectionFilter & {
    name: string;
    lastMintedAt: string | null;
    totalVolumeInEth: number;
  };
  isOwner: boolean;
  isRevealed: boolean | null;
  nftCount: number;
  sale: CollectionSale;
  collectors: {
    preview: UserLight[];
    totalCount: number;
  };
  world: WorldOverview | null;
  onShopTheSecondary: () => void;
}) {
  const {
    collectors,
    collection,
    nftCount,
    sale,
    world,
    isOwner,
    isRevealed,
    onShopTheSecondary,
  } = props;
  const { chainId, contractAddress } = collection;

  const [mintQuantity, setMintQuantity] = useState(1);
  const totalSales = collection.totalVolumeInEth;
  const collectionName = collection.name;

  return match(sale)
    .with({ type: 'TIMED_EDITION' }, (timedEditionSale) => {
      return (
        <MintFromFoundationFixedPriceProvider
          chainId={chainId}
          contractAddress={contractAddress}
          contractCategory="EDITION"
          mintQuantity={mintQuantity}
          price={timedEditionSale.mintPrice}
          setMintQuantity={setMintQuantity}
        >
          <EditionMintWidgetFlowConnected
            collectionName={collectionName}
            chainId={chainId}
            collectors={collectors}
            market="FND"
            nftCount={nftCount}
            onShopTheSecondary={onShopTheSecondary}
            sale={timedEditionSale}
            totalSales={totalSales}
            contractAddress={contractAddress}
            isOwner={isOwner}
          />
        </MintFromFoundationFixedPriceProvider>
      );
    })
    .with({ type: 'LIMITED_EDITION' }, (limitedEditionSale) => {
      return (
        <MintFromFoundationFixedPriceProvider
          chainId={chainId}
          contractAddress={contractAddress}
          contractCategory="EDITION"
          mintQuantity={mintQuantity}
          price={limitedEditionSale.mintPrice}
          setMintQuantity={setMintQuantity}
        >
          <EditionMintWidgetFlowConnected
            collectionName={collectionName}
            chainId={chainId}
            collectors={collectors}
            market="FND"
            nftCount={nftCount}
            onShopTheSecondary={onShopTheSecondary}
            sale={limitedEditionSale}
            totalSales={totalSales}
            contractAddress={contractAddress}
            isOwner={isOwner}
          />
        </MintFromFoundationFixedPriceProvider>
      );
    })
    .with({ type: 'FND_FIXED_PRICE' }, (fndFixedPriceDropSale) => {
      return (
        <MintFromFoundationFixedPriceProvider
          chainId={chainId}
          contractAddress={contractAddress}
          contractCategory="DROP"
          mintQuantity={mintQuantity}
          price={fndFixedPriceDropSale.mintPrice}
          saleStatus={fndFixedPriceDropSale.status}
          setMintQuantity={setMintQuantity}
        >
          <FixedPriceDropMintWidgetConnected
            collectionName={collectionName}
            chainId={chainId}
            collectors={collectors}
            contractAddress={contractAddress}
            market="FND"
            nftCount={nftCount}
            onShopTheSecondary={onShopTheSecondary}
            sale={fndFixedPriceDropSale}
            totalSales={totalSales}
            isOwner={isOwner}
            isRevealed={Boolean(isRevealed)}
          />
        </MintFromFoundationFixedPriceProvider>
      );
    })
    .with({ type: 'HIGHLIGHT_FIXED_PRICE' }, (higlightFixedDropSale) => {
      return (
        <MintFromHighlightFixedPriceProvider
          chainId={chainId}
          contractAddress={contractAddress}
          mintQuantity={mintQuantity}
          sale={higlightFixedDropSale}
          setMintQuantity={setMintQuantity}
        >
          <FixedPriceDropMintWidgetConnected
            collectionName={collectionName}
            onShopTheSecondary={onShopTheSecondary}
            chainId={chainId}
            collectors={collectors}
            contractAddress={contractAddress}
            market="HIGHLIGHT"
            nftCount={nftCount}
            sale={higlightFixedDropSale}
            totalSales={totalSales}
            isOwner={isOwner}
            isRevealed
          />
        </MintFromHighlightFixedPriceProvider>
      );
    })
    .with(
      {
        type: 'FND_LINEAR_DUTCH_AUCTION',
      },
      (linearDutchAuctionSale) => {
        return (
          <MintFromLinearDutchAuctionProvider
            chainId={chainId}
            contractAddress={contractAddress}
            mintQuantity={mintQuantity}
            indexedNftCount={nftCount}
            sale={linearDutchAuctionSale}
            setMintQuantity={setMintQuantity}
            worldId={world ? world.id : null}
          >
            <LinearDutchAuctionMintWidgetFlowConnected
              collectionName={collectionName}
              onShopTheSecondary={onShopTheSecondary}
              brandColor={null} // TODO: fetch this
              chainId={chainId}
              collectors={collectors}
              contractAddress={contractAddress}
              lastMintedAt={collection.lastMintedAt}
              market="FND"
              nftCount={nftCount}
              sale={linearDutchAuctionSale}
              totalSales={totalSales}
              isOwner={isOwner}
              isRevealed={Boolean(isRevealed)}
            />
          </MintFromLinearDutchAuctionProvider>
        );
      }
    )
    .with(
      {
        type: 'HIGHLIGHT_STAGGERED_DUTCH_AUCTION',
      },
      (highlightStaggeredDutchAuctionSale) => {
        return (
          <MintFromHighlightStaggeredDutchAuctionProvider
            chainId={chainId}
            mintQuantity={mintQuantity}
            indexedNftCount={nftCount}
            sale={highlightStaggeredDutchAuctionSale}
            setMintQuantity={setMintQuantity}
            worldId={world ? world.id : null}
          >
            <StaggeredDutchAuctionMintWidgetFlowConnected
              collectionName={collectionName}
              onShopTheSecondary={onShopTheSecondary}
              brandColor={null} // TODO: fetch this
              chainId={chainId}
              collectors={collectors}
              contractAddress={contractAddress}
              lastMintedAt={collection.lastMintedAt}
              market="HIGHLIGHT"
              nftCount={nftCount}
              sale={highlightStaggeredDutchAuctionSale}
              totalSales={totalSales}
              isOwner={isOwner}
              isRevealed
            />
          </MintFromHighlightStaggeredDutchAuctionProvider>
        );
      }
    )
    .exhaustive();
}

type MintWidgetProviderConnectedProps = Pick<
  MintWidgetContextValue,
  'market' | 'mint' | 'pricing' | 'quantity'
> & {
  children: React.ReactNode;
  collectionName: string;
  sale: CollectionSale | ERC1155TokenSale;
  onShopTheSecondary: (() => void) | null;
};

export function MintWidgetProviderConnected(
  props: MintWidgetProviderConnectedProps
) {
  const { market, mint, pricing, quantity, sale, onShopTheSecondary } = props;

  const analytics = useAnalytics();
  const modal = useModal();

  const onAddToCal: MintWidgetContextValue['onAddToCal'] = (options) => {
    const { startDate, endDate } = options;

    const mintPageUrl = getCurrentWindowUrl({ includeQueryParams: false });

    const googleCalendarUrl = getGoogleCalendarLink({
      title: `"${props.collectionName}" opens for minting on Foundation`,
      description: `
${getCalendarDescriptionAnchor({
  href: mintPageUrl,
  label: 'Mint now',
})} on Foundation.

${mintPageUrl}
`,
      startDate,
      endDate,
    });

    analytics.track({
      name: 'clicked_add_to_cal',
      type: 'mint',
      sale,
    });

    window.open(googleCalendarUrl);
  };

  const onCollectorsClick = () => {
    analytics.track({ name: 'clicked_collectors' });

    modal.setModal({ type: 'OWNED_BY' });
  };

  return (
    <MintWidgetContext.Provider
      value={{
        market,
        mint,
        onAddToCal,
        onCollectorsClick,
        onShopTheSecondary,
        pricing,
        quantity,
      }}
    >
      {props.children}
    </MintWidgetContext.Provider>
  );
}

type MintWidgetFlowProps = Pick<
  MintWidgetContextValue,
  'onShopTheSecondary'
> & {
  chainId: ChainId;
  collectionName: string;
  collectors: CollectorsPreview;
  contractAddress: Address;
  isOwner: boolean;
  market: Marketplace;
  nftCount: number;
  totalSales: number;
};

export const useFixedPriceMintFlow = () => {
  const flow = useMintFromFixedPriceFlow();

  const trackedTx = useTransactionStore(
    selectTransactionByAction({
      action: flow.action,
    })
  );

  useTransactionEffects(trackedTx, {
    onSuccess: () => {
      flow.config.reset();
    },
  });

  const mintCta = useWeb3ActionCta({
    chainId: flow.chainId,
    config: flow.config,
    context: 'on-page',
    trackedTx,
  });

  return {
    mint: mintCta,
    pricing: flow.pricing,
    quantity: flow.quantity,
  };
};

const useDutchAuctionMintFlow = (options: { sale: DutchAuctionDropSale }) => {
  const { sale } = options;

  const flow = useMintFromDutchAuctionFlow();
  const queryClient = useQueryClient();

  useWatchBlockNumber({
    onBlockNumber: () => {
      queryClient.invalidateQueries({
        queryKey: [extractGeneratedQueryKeyRoot(useMintPage.getKey)],
      });
    },
    enabled: sale.status === 'LIVE',
  });

  const trackedTx = useTransactionStore(
    selectTransactionByAction({
      action: flow.action,
    })
  );

  useTransactionEffects(trackedTx, {
    onSuccess: () => {
      flow.config.reset();
    },
  });

  const mintCta = useWeb3ActionCta({
    chainId: flow.chainId,
    config: flow.config,
    context: 'on-page',
    trackedTx,
  });

  return {
    cartPricing: flow.cartPricing,
    cartQuantity: flow.cartQuantity,
    isMintedOut: flow.auction.isMintedOut,
    mint: mintCta,
    mintPricing: flow.itemPricing,
    onChainQuery: flow.onChainQuery,
  };
};

function FixedPriceDropMintWidgetConnected(
  props: MintWidgetFlowProps & {
    sale: FixedPriceDropSale;
    isRevealed: boolean;
  }
) {
  const {
    collectionName,
    collectors,
    market,
    nftCount,
    sale,
    totalSales,
    contractAddress,
    chainId,
    onShopTheSecondary,
    isOwner,
    isRevealed,
  } = props;

  const { mint, pricing, quantity } = useFixedPriceMintFlow();

  const auth = useAuth();

  // TODO (l2-collections): get this data via the API
  const presaleEligibilityQuery = usePresaleEligibility(
    {
      contractAddress,
      publicKey: hasPublicKey(auth) ? auth.publicKey : ZERO_ADDRESS,
    },
    {
      enabled: hasPublicKey(auth),
      select: (data) => Boolean(data.allowlistUser[0]),
    }
  );

  const getPresaleEligibility = (): PresaleEligibility => {
    if (
      presaleEligibilityQuery.status === 'pending' ||
      presaleEligibilityQuery.status === 'error'
    ) {
      return 'UNKNOWN';
    } else {
      return presaleEligibilityQuery.data ? 'ELIGIBLE' : 'NOT_ELIGIBLE';
    }
  };

  return (
    <MintWidgetProviderConnected
      collectionName={collectionName}
      market={market}
      mint={mint}
      onShopTheSecondary={onShopTheSecondary}
      pricing={pricing}
      quantity={quantity}
      sale={sale}
    >
      <FixedPriceDropMintWidget
        collection={{
          contractAddress,
          chainId,
        }}
        collectors={collectors}
        nftCount={nftCount}
        sale={sale}
        totalSales={totalSales}
        eligibilityStatus={getPresaleEligibility()}
        isOwner={isOwner}
        isRevealed={isRevealed}
      />
    </MintWidgetProviderConnected>
  );
}

function EditionMintWidgetFlowConnected(
  props: MintWidgetFlowProps & { sale: EditionCollectionSale }
) {
  const {
    collectionName,
    collectors,
    market,
    nftCount,
    onShopTheSecondary,
    sale,
    totalSales,
  } = props;
  const { mint, pricing, quantity } = useFixedPriceMintFlow();

  return (
    <MintWidgetProviderConnected
      collectionName={collectionName}
      market={market}
      mint={mint}
      onShopTheSecondary={onShopTheSecondary}
      pricing={pricing}
      quantity={quantity}
      sale={sale}
    >
      {sale.type === 'TIMED_EDITION' ? (
        <TimedEditionMintWidget
          collectors={collectors}
          nftCount={nftCount}
          sale={sale}
          totalSales={totalSales}
        />
      ) : (
        <LimitedEditionMintWidget
          collectors={collectors}
          nftCount={nftCount}
          sale={sale}
          totalSales={totalSales}
        />
      )}
    </MintWidgetProviderConnected>
  );
}

function LinearDutchAuctionMintWidgetFlowConnected(
  props: MintWidgetFlowProps & {
    brandColor: string | null;
    chainId: ChainId;
    lastMintedAt: ApiDropFragment['lastMintedAt'];
    sale: FndLinearDutchAuctionSale;
    isRevealed: boolean;
  }
) {
  const {
    brandColor,
    collectionName,
    collectors,
    lastMintedAt,
    market,
    nftCount,
    sale,
    onShopTheSecondary,
    isOwner,
    isRevealed,
    contractAddress,
    chainId,
  } = props;

  const {
    cartPricing,
    cartQuantity,
    isMintedOut,
    mint,
    mintPricing,
    onChainQuery,
  } = useDutchAuctionMintFlow({ sale });

  return (
    <MintWidgetProviderConnected
      collectionName={collectionName}
      onShopTheSecondary={onShopTheSecondary}
      market={market}
      mint={mint}
      pricing={cartPricing}
      quantity={cartQuantity}
      sale={sale}
    >
      <DutchAuctionDropMintWidget
        collection={{
          contractAddress,
          chainId,
        }}
        collectors={collectors}
        gizmo={
          onChainQuery.status === 'success' ? (
            <LinearDutchAuctionGizmo
              isMintedOut={isMintedOut}
              startsAt={parseJSON(sale.startTime)}
              endsAt={parseJSON(sale.endTime)}
              currentPrice={ethToNumber(mintPricing.mintPrice)}
              minPrice={sale.minPrice}
              lastMintedAtDate={lastMintedAt ? parseJSON(lastMintedAt) : null}
              brandColor={brandColor || '#000'}
            />
          ) : (
            <Gizmo.Skeleton />
          )
        }
        nftCount={nftCount}
        sale={sale}
        isOwner={isOwner}
        isRevealed={isRevealed}
      />
    </MintWidgetProviderConnected>
  );
}

function StaggeredDutchAuctionMintWidgetFlowConnected(
  props: MintWidgetFlowProps & {
    brandColor: string | null;
    chainId: ChainId;
    lastMintedAt: ApiDropFragment['lastMintedAt'];
    sale: HighlightStaggeredDutchAuctionSale;
    isRevealed: boolean;
  }
) {
  const {
    brandColor,
    collectionName,
    collectors,
    lastMintedAt,
    market,
    nftCount,
    sale,
    isOwner,
    onShopTheSecondary,
    isRevealed,
    contractAddress,
    chainId,
  } = props;

  const {
    cartPricing,
    cartQuantity,
    isMintedOut,
    mint,
    mintPricing,
    onChainQuery,
  } = useDutchAuctionMintFlow({ sale });

  return (
    <MintWidgetProviderConnected
      collectionName={collectionName}
      onShopTheSecondary={onShopTheSecondary}
      market={market}
      mint={mint}
      pricing={cartPricing}
      quantity={cartQuantity}
      sale={sale}
    >
      <DutchAuctionDropMintWidget
        collection={{
          contractAddress,
          chainId,
        }}
        collectors={collectors}
        gizmo={
          onChainQuery.status === 'success' ? (
            <StaggeredDutchAuctionGizmo
              brandColor={brandColor || '#000'}
              currentPrice={ethToNumber(mintPricing.mintPrice)}
              isMintedOut={isMintedOut}
              lastMintedAtDate={lastMintedAt ? parseJSON(lastMintedAt) : null}
              sale={sale}
            />
          ) : (
            <Gizmo.Skeleton />
          )
        }
        nftCount={nftCount}
        sale={sale}
        isOwner={isOwner}
        isRevealed={isRevealed}
      />
    </MintWidgetProviderConnected>
  );
}
