import { match } from 'ts-pattern';
import type { Address } from 'viem';

import TransactionModal from 'components/TransactionModal';
import InputEth from 'components/base/InputEth';
import Modal from 'components/base/Modal';
import AmountBreakDown from 'components/transactions/generic/AmountBreakdown';
import { useAssumeConnectedAuth } from 'contexts/auth/useAuth';
import { WithdrawFoundationDutchAuctionEarningsProvider } from 'flows/withdraw-dutch-auction-earnings/WithdrawFoundationDutchAuctionEarnings';
import { WithdrawHighlightDutchAuctionEarningsProvider } from 'flows/withdraw-dutch-auction-earnings/WithdrawHighlightDutchAuctionEarnings';
import { useWithdrawDutchAuctionEarningsFlow } from 'flows/withdraw-dutch-auction-earnings/use-withdraw-dutch-auction-earnings-flow';

import useModalVisibility from 'hooks/use-modal-visibility';
import { formatEther } from 'utils/units';

const MODAL_KEY = 'CLAIM_DUTCH_AUCTION_REVENUE';

export default function WithdrawEarningsModal() {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {match(modal.config)
            .with(null, () => null)
            .with({ market: 'FND' }, (config) => {
              return (
                <WithdrawFoundationDutchAuctionEarningsProvider {...config}>
                  <WithdrawEarningsModalWindow
                    contractAddress={config.contractAddress}
                  />
                </WithdrawFoundationDutchAuctionEarningsProvider>
              );
            })
            .with({ market: 'HIGHLIGHT' }, (config) => {
              return (
                <WithdrawHighlightDutchAuctionEarningsProvider {...config}>
                  <WithdrawEarningsModalWindow contractAddress={null} />
                </WithdrawHighlightDutchAuctionEarningsProvider>
              );
            })
            .exhaustive()}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

function WithdrawEarningsModalWindow(props: {
  // TODO: remove this prop and move fee calculations to the flow
  contractAddress: Address | null;
}) {
  const context = useWithdrawDutchAuctionEarningsFlow();

  const auth = useAssumeConnectedAuth();

  return (
    <TransactionModal
      chainId={context.chainId}
      preSignPrompt={{
        type: 'custom',
        copy: {
          description: 'Sign a message to claim your earnings.',
        },
      }}
    >
      <TransactionModal.Content
        action={context.action}
        chainId={context.chainId}
        txHash={context.txHash ? context.txHash : null}
        footer={
          <Modal.Footer>
            <TransactionModal.TransactionButton
              chainId={context.chainId}
              write={context.write}
              isDisabled={context.isDisabled}
              isLoading={context.isLoading}
              error={context.disabledReason}
              label="Withdraw"
            />
          </Modal.Footer>
        }
      >
        <TransactionModal.Body>
          <Modal.BodyTitle
            align="left"
            title="Drop earnings"
            description="The auction has ended and your earnings have been calculated. After settling the auction all future mints will be automatically sent to your wallet."
          />
          <TransactionModal.Body>
            <TransactionModal.InputWrapper>
              <InputEth
                id="price"
                disabled
                value={
                  context.withdrawAmount
                    ? formatEther(context.withdrawAmount)
                    : '0'
                }
              />
            </TransactionModal.InputWrapper>

            {/* TODO: update AmountBreakDown to work for Highlight */}
            {props.contractAddress && (
              <AmountBreakDown.FeesConnected
                chainId={context.chainId}
                currentUserPublicKey={auth.publicKey}
                contractAddress={props.contractAddress}
                tokenId={0}
                price={
                  context.withdrawAmount
                    ? Number(formatEther(context.withdrawAmount))
                    : 0
                }
                worldId={context.worldId}
                takeRateInBasisPoints={context.takeRateInBasisPoints}
              />
            )}
          </TransactionModal.Body>
        </TransactionModal.Body>
      </TransactionModal.Content>
    </TransactionModal>
  );
}
