import { useContext } from 'react';

import { ClaimRebateDutchAuctionFlowContext } from './ClaimRebateDutchAuctionFlowContext';

export const useClaimRebateDutchAuctionFlow = () => {
  const context = useContext(ClaimRebateDutchAuctionFlowContext);

  if (!context) {
    throw new Error(
      'useClaimRebateDutchAuctionFlow must be used within a ClaimRebateDutchAuctionFlowContext provider'
    );
  }

  return context;
};
