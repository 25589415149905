import { styled } from '@f8n-frontend/stitches';
import { Placement } from '@floating-ui/react';
import { formatDistanceStrict, formatDistanceToNowStrict } from 'date-fns';
import { clamp } from 'ramda';
import { Fragment, useState } from 'react';
import { useTimeoutFn } from 'react-use';
import { match } from 'ts-pattern';

import Tooltip from 'components/base/Tooltip';

import { formatETHWithSuffix } from 'utils/formatters';
import { isNumberType, lerp } from 'utils/helpers';
import { createCssTarget } from 'utils/styles';
import { DEGREES_IN_CIRCLE, createArcAroundCircle } from 'utils/svg';

import { HighlightStaggeredDutchAuctionSale } from 'types/DropSale';
import { Ratio } from 'types/number';

import {
  GIZMO_CENTER,
  GIZMO_RADIUS,
  GIZMO_STROKE_WIDTH,
  GIZMO_VIEWBOX,
  GizmoCircle,
  GizmoSvg,
} from './GizmoCircle';

const MINTED_OUT_COLOR = '#E6E6E6';

const SEGMENT_PADDING_PERCENTAGE = 0.008;
const SEGMENT_PADDING_DEGREES = DEGREES_IN_CIRCLE * SEGMENT_PADDING_PERCENTAGE;

const SEGMENT_BACKGROUND_ARC_TARGET = createCssTarget('arc-background');
const SEGMENT_ACTIVE_ARC_TARGET = createCssTarget('arc-active');
const SEGMENT_ACTIVE_ARC_PULSE_TARGET = createCssTarget('arc-active-pulse');
const SEGMENT_TOOLTIP_SAFEZONE_TARGET = createCssTarget('arc-tooltip-safezone');

/** Ensures that a value is between 0...360 */
const clampToCircle = (angle: number) => clamp(0, DEGREES_IN_CIRCLE, angle);

/** Validates that a number is within the bounds of a min and max */
const isWithinRange = (range: { min: number; max: number }) => {
  return (value: number) => {
    const isAboveMin = value >= range.min;
    const isBelowMax = value <= range.max;

    return isAboveMin && isBelowMax;
  };
};

const isBottomSideAngle = isWithinRange({ min: 135, max: 225 });
const isRightSideAngle = isWithinRange({ min: 45, max: 135 });
const isLeftSideAngle = isWithinRange({ min: 225, max: 315 });

type StaggeredSegmentStatus =
  /** segment will become active in the future */
  | 'scheduled'
  /** segment is active now */
  | 'active'
  /** clearing price was reached here */
  | 'final'
  /** segment was active */
  | 'ended';

export type StaggeredSegment = {
  status: StaggeredSegmentStatus;
  startDate: Date;
  endDate: Date;
  price: number;
};

type AngleOptions = {
  /** start angle in degrees, assumed to be between 0...360 */
  startAngle: number;
  /** end angle in degrees — assumed to be higher and between 0...360   */
  endAngle: number;
  /** current angle in degrees - assumed to be between 0...360  */
  activeAngle: number;
};

const getRelativeTime = (date: Date) => {
  return formatDistanceToNowStrict(date, {
    addSuffix: true,
  });
};

const getSegmentDuration = (options: { startDate: Date; endDate: Date }) => {
  const { startDate, endDate } = options;

  return formatDistanceStrict(startDate, endDate, {
    addSuffix: false,
    roundingMethod: 'round',
  });
};

/**
 * @example if startAngle = 0, endAngle = 90, and activeAngle = 45, this function will return 0.5
 *
 * @param options describes the start, end, and active angles of a segment
 * @returns a number between 0...1
 */
const getPercentageThroughSegment = (options: AngleOptions): Ratio => {
  const startAngle = clampToCircle(options.startAngle);
  const endAngle = clampToCircle(options.endAngle);
  const activeAngle = clampToCircle(options.activeAngle);

  const range = endAngle - startAngle;

  // Avoid dividing by zero
  if (range === 0) {
    return 0;
  }

  if (activeAngle === 0) {
    return 0;
  }

  if (activeAngle < startAngle) {
    return 0;
  }

  if (activeAngle > endAngle) {
    return 1;
  }

  return (activeAngle - startAngle) / range;
};

/** Creates an SVG path that arcs around an imaginary circle */
const createArcAroundGizmo = (options: {
  startAngleInDegrees: number;
  endAngleInDegrees: number;
}) => {
  const { startAngleInDegrees, endAngleInDegrees } = options;

  return createArcAroundCircle({
    centerX: GIZMO_CENTER,
    centerY: GIZMO_CENTER,
    radius: GIZMO_RADIUS,
    startAngleInDegrees,
    endAngleInDegrees,
  });
};

export function GizmoStaggeredCircle(props: {
  completedRatio: Ratio;
  isMintedOut: boolean;
  segments: StaggeredSegment[];
  sale: HighlightStaggeredDutchAuctionSale;
}) {
  const { completedRatio, isMintedOut, segments, sale } = props;

  const [isRevealed, setIsRevealed] = useState(() => {
    if (sale.status === 'SCHEDULED') return true;
    return false;
  });

  useTimeoutFn(() => {
    setIsRevealed(true);
  }, 500);

  /** Converts the percentage completed to an angle between 0...360 */
  const activeAngle = lerp(0, DEGREES_IN_CIRCLE, completedRatio);

  const SEGMENT_COUNT = segments.length;

  /**
   * Note that we're assuming that each segment is evenly spaced around the circle.
   */
  const MAX_SEGMENT_ANGLE = DEGREES_IN_CIRCLE / SEGMENT_COUNT;

  return (
    <GizmoSvg
      viewBox={GIZMO_VIEWBOX}
      css={{
        /**
         * Rotation is used to align the SVG's coordinate system with the polar coordinate system.
         * This means that an angle of 0 degrees will point upwards, which is often more intuitive for a circular layout.
         */
        transform: 'rotate(-90deg)',
      }}
    >
      {/* TODO: replace with prices from API */}
      {segments.map((segment, index, array) => {
        const segmentStatus = segment.status;

        const hasOneSegment = array.length === 1;

        /** What percentage through the segments are we? Will be a value from 0 to 1 */
        const segmentPercentage = hasOneSegment
          ? 1 // used to fill the entire circle, and to avoid dividing by zero
          : clamp(0, 1, index / (array.length - 1));

        // Constants relating to the angles in this segment, assuming that there are no gaps between segments
        const START_ANGLE = lerp(
          0,
          DEGREES_IN_CIRCLE - MAX_SEGMENT_ANGLE,
          segmentPercentage
        );
        const END_ANGLE = START_ANGLE + MAX_SEGMENT_ANGLE;
        const ANGLE_MID_POINT = START_ANGLE + MAX_SEGMENT_ANGLE / 2;

        // Constants relating to the angles in this segment, after adding some padding around each segment
        const VISIBLE_START_ANGLE = START_ANGLE + SEGMENT_PADDING_DEGREES;
        const VISIBLE_END_ANGLE = END_ANGLE - SEGMENT_PADDING_DEGREES;
        const VISIBLE_ANGLE_RANGE = VISIBLE_END_ANGLE - VISIBLE_START_ANGLE;

        const isIncompleteSegment =
          segment.status === 'active' ||
          segment.status === 'scheduled' ||
          segment.status === 'final';

        // Creates an arc around the gizmo, which fills the entire segment
        const FULL_ARC_PATH = createArcAroundGizmo({
          startAngleInDegrees: VISIBLE_START_ANGLE,
          endAngleInDegrees: VISIBLE_END_ANGLE,
        });

        // Creates an arc around the gizmo, which partially fills the segment
        const ACTIVE_ARC_PATH = createArcAroundGizmo({
          startAngleInDegrees: match(segment.status)
            // When the segment is active, we want to create a curved progress bar that partially fills the segment
            .with('active', () => {
              // How far through the segment are we?
              const activeAnglePercentageThroughRange =
                getPercentageThroughSegment({
                  activeAngle,
                  startAngle: START_ANGLE,
                  endAngle: END_ANGLE,
                });

              if (!isNumberType(activeAnglePercentageThroughRange)) {
                return VISIBLE_END_ANGLE;
              }

              // Adjust the angle based on how far through the range we are
              return lerp(
                VISIBLE_END_ANGLE - VISIBLE_ANGLE_RANGE,
                VISIBLE_END_ANGLE,
                activeAnglePercentageThroughRange
              );
            })
            .with('final', 'scheduled', () => VISIBLE_START_ANGLE)
            .with('ended', () => VISIBLE_END_ANGLE)
            .exhaustive(),
          endAngleInDegrees: VISIBLE_END_ANGLE,
        });

        const formattedStepPrice = formatETHWithSuffix(segment.price);

        return (
          <Fragment key={`${index}-${SEGMENT_COUNT}`}>
            <Tooltip
              content={match({ status: sale.status, isMintedOut })
                .with({ isMintedOut: true }, () => null)
                .with({ status: 'LIVE' }, { status: 'SCHEDULED' }, () => {
                  return {
                    type: 'duo' as const,
                    title: formattedStepPrice,
                    message: match({ saleStatus: sale.status, segmentStatus })
                      .with(
                        { saleStatus: 'LIVE', segmentStatus: 'active' },
                        () => `Price drops ${getRelativeTime(segment.endDate)}`
                      )
                      .with(
                        { saleStatus: 'LIVE', segmentStatus: 'scheduled' },
                        () => `Price ${getRelativeTime(segment.startDate)}`
                      )
                      .with({ segmentStatus: 'ended' }, () => 'Ended')
                      .with({ segmentStatus: 'final' }, () => 'Final price')
                      .otherwise(() => `for ${getSegmentDuration(segment)}`),
                  };
                })
                .otherwise(() => null)}
              // Alignment is dynamic to keep the text close to the cursor when hovering over the sides
              align={match(ANGLE_MID_POINT)
                .when(isRightSideAngle, () => 'right' as const)
                .when(isLeftSideAngle, () => 'left' as const)
                .otherwise(() => 'center' as const)}
              // Placement is dynamic to keep tooltip inside the circle
              placement={match<number, Placement>(ANGLE_MID_POINT)
                .when(isRightSideAngle, () => 'left')
                .when(isBottomSideAngle, () => 'top')
                .when(isLeftSideAngle, () => 'right')
                .otherwise(() => 'bottom')}
              offset={2}
              size={0}
              followCursor
            >
              {/**
               * Note from @smhutch — I don't expect anyone to understand this at first glance, but I'll do my best to explain it here.
               *
               * ——————————
               *
               * This code is part of a loop, which generates multiple arcs that collectively create the *illusion* of staggered circle.
               * We start at the top of this "circle" and work our way clockwise around, adding an arc for each step in the circle.
               * As we loop through the steps, the START_ANGLE and END_ANGLE increase from ~0 to ~360 degrees. We're moving in a clockwise direction.
               *
               * When it comes to this specific piece of code though, it's very important to forget about us moving in a clockwise direction. Instead,
               * think about this arc in isolation, and understand what it's doing.
               *
               * This arc is a curved line between two points. Let's call them START and END.
               *
               * If we draw a line between START and END, we get → —
               * If we add a curve, we get either → ◠ OR ◡
               *
               * When we render multiple of these in a circle, we get either the illustion of a circle, or something that looks closer to a star.
               * The direction in which the arc is drawn is vital to create the circle. This is configured by the sweep-flag argument inside createArcAroundCircle.
               *
               * ——————————
               *
               * The secondary complexity we face here is that we have two overalapping arcs for each part of our imaginary circle.
               * We have the background (grey) arc, and the foreground (brand-color) arc.
               *
               * When a segment is active, the colored arc acts as a sort of countdown timer. The END point of the arc moves closer to the START point.
               * This is done intentionally so that the colored arc is drawn on top of the grey arc.
               *
               * ——————————
               *
               * To summarize:
               *
               * - There is no circle, just a bunch of arcs.
               * - START_ANGLE and END_ANGLE relate to the arc's position in the imaginary circle.
               * - When one arc is drawn, the START and END points are intentionally inverted.
               * - While a segment is active, the END point is moved closer to the START point.
               */}
              <PathGroup
                status={segmentStatus}
                isRevealed={isRevealed}
                isMintedOut={isMintedOut}
              >
                {/** Tooltip safezone — it's invisible, but makes it easier to trigger the tooltip */}
                <GizmoSegmentPath
                  className={SEGMENT_TOOLTIP_SAFEZONE_TARGET.className}
                  d={FULL_ARC_PATH}
                  css={{
                    stroke: 'transparent', // intentionally hidden
                    strokeWidth: GIZMO_STROKE_WIDTH * 2,
                  }}
                />

                {/* Background arc — visible when a segment is in-progress or ended */}
                <GizmoSegmentPath
                  className={SEGMENT_BACKGROUND_ARC_TARGET.className}
                  d={FULL_ARC_PATH}
                  style={{
                    transitionDelay: `${lerp(0, 0.8, segmentPercentage)}s`,
                  }}
                />

                {/* Hover effect — visible when hovering over StaggeredGizmoGroup */}
                {isIncompleteSegment && (
                  <GizmoSegmentPath
                    className={SEGMENT_ACTIVE_ARC_PULSE_TARGET.className}
                    d={ACTIVE_ARC_PATH}
                    css={{
                      stroke: MINTED_OUT_COLOR,
                      strokeWidth: GIZMO_STROKE_WIDTH * 2,
                      opacity: 0,
                    }}
                  />
                )}

                {/* Bold / Colored arc — used to indicate how much time is remaining in a segment */}
                {isIncompleteSegment && (
                  <>
                    <GizmoSegmentPath
                      className={SEGMENT_ACTIVE_ARC_TARGET.className}
                      d={ACTIVE_ARC_PATH}
                    />
                  </>
                )}
              </PathGroup>
            </Tooltip>
          </Fragment>
        );
      })}
      <GizmoSegmentTooltipBlocker />
    </GizmoSvg>
  );
}

const PathGroup = styled('g', {
  [SEGMENT_BACKGROUND_ARC_TARGET.selector]: {
    stroke: MINTED_OUT_COLOR,
  },

  [SEGMENT_ACTIVE_ARC_TARGET.selector]: {
    stroke: 'currentColor',
  },

  [SEGMENT_ACTIVE_ARC_PULSE_TARGET.selector]: {
    stroke: 'currentColor',
  },

  [`${GizmoSvg}:hover &`]: {
    [SEGMENT_ACTIVE_ARC_TARGET.selector]: {
      opacity: 0.5,
    },
  },

  [`${GizmoSvg}:hover &:hover`]: {
    [SEGMENT_ACTIVE_ARC_PULSE_TARGET.selector]: {
      opacity: 0.4,
    },

    [SEGMENT_ACTIVE_ARC_TARGET.selector]: {
      opacity: 1,
    },
  },

  variants: {
    isRevealed: {
      true: {
        [SEGMENT_BACKGROUND_ARC_TARGET.selector]: {
          stroke: MINTED_OUT_COLOR,
        },
      },
      false: {
        [SEGMENT_BACKGROUND_ARC_TARGET.selector]: {
          stroke: 'currentColor',
        },
      },
    },
    isMintedOut: {
      true: {
        [SEGMENT_ACTIVE_ARC_TARGET.selector]: {
          stroke: '$black30',
          opacity: '1 !important', // use of !important prevents parent hover effects from overriding this
        },

        [SEGMENT_ACTIVE_ARC_PULSE_TARGET.selector]: {
          stroke: 'transparent',
        },
      },
      false: {},
    },
    status: {
      scheduled: {
        [`${GizmoSvg}:hover &:hover`]: {
          [SEGMENT_ACTIVE_ARC_PULSE_TARGET.selector]: {
            opacity: 0.15,
          },
        },
      },
      active: {
        [`${GizmoSvg}:hover &:hover`]: {
          [SEGMENT_ACTIVE_ARC_PULSE_TARGET.selector]: {
            opacity: 0.3,
          },
        },
      },
      final: {
        [SEGMENT_ACTIVE_ARC_TARGET.selector]: {
          stroke: '$black30',
          opacity: '1 !important', // use of !important prevents parent hover effects from overriding this
        },

        [SEGMENT_ACTIVE_ARC_PULSE_TARGET.selector]: {
          stroke: 'transparent',
        },
      },
      ended: {},
    },
  },
});

const GizmoSegmentPath = styled('path', {
  fill: 'transparent',
  transform: 'rotate(90deg)',
  transformOrigin: 'center',
  strokeLinecap: 'round',
  strokeWidth: GIZMO_STROKE_WIDTH,

  transition: '$3 $ease stroke, $3 $ease opacity',
});

/**
 * This invisible circle overlays the gizmo segments to prevent tooltips from activating when hovering over the gizmo's center.
 * It's useful when the Staggered Circle has 1 or 2 segments, where the tooltip safezone extends across center of the circle.
 * This internal circle prevent tooltips from triggering when near the middle of the circle.
 */
function GizmoSegmentTooltipBlocker() {
  return (
    <GizmoCircle
      cx={GIZMO_CENTER}
      cy={GIZMO_CENTER}
      // Fill 90% of the gizmo's radius, meaning only the outer 10% of the gizmo will trigger tooltips
      r={GIZMO_RADIUS * 0.9}
      css={{ fill: 'transparent' }}
    />
  );
}
