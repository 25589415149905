import { useEffect } from 'react';
import { P, match } from 'ts-pattern';

import { ZERO_ADDRESS } from 'lib/constants';
import { getViemWebSocketClientByChainId } from 'lib/wagmi';

import { CollectionFilter } from 'types/Collection';
import { CollectionSale } from 'types/CollectionSale';

type SubscribeVariables = {
  filter: CollectionFilter;
  sale: CollectionSale | null;
};

type SubscribeOptions = {
  onMintEvent: () => void;
};

function watchMintTransferEvent(
  variables: CollectionFilter,
  options: SubscribeOptions
) {
  const { chainId, contractAddress } = variables;

  const client = getViemWebSocketClientByChainId(chainId);

  return client.watchContractEvent({
    address: contractAddress,
    abi: transferEventAbi,
    eventName: 'Transfer',
    args: { from: ZERO_ADDRESS },
    onLogs: options.onMintEvent,
  });
}

function subscribe(variables: SubscribeVariables, options: SubscribeOptions) {
  return match(variables.sale)
    .with(
      {
        type: P.union(
          'FND_FIXED_PRICE',
          'LIMITED_EDITION',
          'TIMED_EDITION',
          'FND_LINEAR_DUTCH_AUCTION',
          'HIGHLIGHT_FIXED_PRICE',
          'HIGHLIGHT_STAGGERED_DUTCH_AUCTION'
        ),
        status: P.union('AVAILABLE', 'LIVE', 'OPEN', 'OPEN_PRESALE'),
      },
      () => watchMintTransferEvent(variables.filter, options)
    )
    .otherwise(() => {
      return () => false;
    });
}

export function useWatchMintEvent(
  variables: SubscribeVariables,
  options: SubscribeOptions
) {
  useEffect(() => {
    const unwatch = subscribe(variables, options);
    return unwatch;
  }, [variables.sale?.status, variables.filter.chainId]);
}

/**
 * industry standard ABI for the ERC721 Transfer event
 */
const transferEventAbi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'from',
        type: 'address',
      },
      {
        indexed: true,
        name: 'to',
        type: 'address',
      },
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
] as const;
